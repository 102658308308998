import { Fragment, useState } from "react";
import { getProductsList } from "../../../api/config";
import { useFetch } from "../../../hooks/useFetch";
import { GetProductListResponse } from "../../../interfaces/be.interfaces";
import Spinner from "../../../components/Spinner";
import Select from "../../../components/Select";
import { useMemo } from "react";
import CustomerItem from "./CustomerItem";

const ManageProduct = () => {
  const [customers, setCustomers] = useState<{ value: string; label: string }[]>([]);
  const [selectedVendorId, setSelectedVendorId] = useState<string>("");

  /**
   * Fetches list of products and sets customers list array for filtering
   */
  const {
    data,
    run: runProductList,
    loading: isListLoading,
  } = useFetch<GetProductListResponse>({
    url: getProductsList,
    runOnMount: true,
    onSuccess: (response) => {
      if (response.data.status) {
        // set customers list data for filtering in select dropdown
        setCustomers([
          { label: "All customers", value: "" },
          ...response.data.data.list.map((item) => ({
            value: String(item.vendor_id),
            label: item.customer_name,
          })),
        ]);
      }
    },
  });

  // Queries list of products based on selected customer's vendor id
  const filteredList = useMemo(() => {
    return selectedVendorId ? data?.data?.list?.filter((item) => item.vendor_id === Number(selectedVendorId)) : data?.data?.list;
  }, [selectedVendorId, data?.data?.list]);

  if (isListLoading) return <Spinner />;

  return (
    <>
      <div className="d-flex align-item-center justify-content-between">
        <h1>Manage Products</h1>
        <Select
          selectProps={{
            value: selectedVendorId,
            onChange: (e) => {
              setSelectedVendorId(e.target.value);
            },
          }}
          options={customers}
        />
      </div>
      {filteredList?.map((item) => (
        <CustomerItem customer={item} runProductList={runProductList} key={item.vendor_id} />
      ))}
    </>
  );
};

export default ManageProduct;
