/**
 * Prompts a user when they exit the page
 */

import { useCallback, useContext, useEffect } from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";

/**
 * This hook will block user from navigating to a different page or reloading current one.
 * @param confirmExit - function to allow navigate
 * @param when - When this parameter is true we disallow navigation otherwise continue
 */
function useConfirmExit(confirmExit: () => boolean, when = true) {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) {
      return;
    }

    // Duplicate push function in a separate variable
    const push = navigator.push;

    // modify push method to ask for user confirmation first
    navigator.push = (...args: Parameters<typeof push>) => {
      const result = confirmExit();

      // if user confirms we navigate anyhow
      if (result !== false) {
        push(...args);
      }
    };

    return () => {
      navigator.push = push;
    };
  }, [navigator, confirmExit, when]);
}

export function usePrompt(message: string, when = true) {
  useEffect(() => {
    if (when) {
      // binds before unload event to listen for reload or navigation
      window.onbeforeunload = function () {
        return message;
      };
    }

    // on unmount removes the event
    return () => {
      window.onbeforeunload = null;
    };
  }, [message, when]);

  // Ask for user confirmation using prompt
  const confirmExit = useCallback(() => {
    const confirm = window.confirm(message);
    return confirm;
  }, [message]);

  useConfirmExit(confirmExit, when);
}
