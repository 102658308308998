import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { Formik, FormikHelpers } from "formik";
import isNil from "lodash/isNil";

import Form from "./Form";

import { addInputCommand } from "../../api/config";
import { http } from "../../api/utils/http";
import { validateCustomCommandForm } from "../../utils/validationSchemas";
import { InputCommandData } from "../../interfaces/be.interfaces";
import { CustomCommandFormValues, CustomCommandResultType } from "../../interfaces/fe.interfaces";

import styles from "./styles.module.scss";

interface AddCommandDialogProps {
  callback: () => void | Promise<void>;
  onCancelClick: () => void | Promise<void>;
}

const initialValues: CustomCommandFormValues = {
  cust_command_name: "",
  cust_command_description: "",
  result_type: null,
  move_on: false,
  vendor_id: null,
  passing_values: [],
  min_value: null,
  max_value: null,
};

// DialogBox component for create new command
const AddCommandDialog = (props: AddCommandDialogProps) => {
  /**
   * Submit handler for create command form
   * Calls /add-input-command API with cust_command_name, cust_command_description and result_type payload
   */
  const handleAddCommand = (values: CustomCommandFormValues, { setSubmitting }: FormikHelpers<CustomCommandFormValues>): void => {
    const payload: CustomCommandFormValues = {
      ...values,
      min_value: values.result_type === CustomCommandResultType.NUMBER && !isNil(values?.min_value) ? +values.min_value : null,
      max_value: values.result_type === CustomCommandResultType.NUMBER && !isNil(values?.max_value) ? +values.max_value : null,
    };

    // Success callback for /add-input-command API
    const onSuccess = ({ data: response }: AxiosResponse<InputCommandData>) => {
      setSubmitting(false);
      if (response?.status) {
        toast.success(response?.message || "Custom command added successfully");
        props.callback();
        props.onCancelClick();
      } else {
        toast.error(response?.message);
      }
    };

    http.makePostRequest<InputCommandData, { message: string }>(
      addInputCommand,
      onSuccess,
      (error) => {
        setSubmitting(false);
        toast.error(error?.response?.data?.message);
        console.log("error", error);
      },
      payload
    );
  };

  return (
    <>
      <div className={styles.dialogContainer}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleAddCommand}
          onReset={props.onCancelClick}
          validationSchema={validateCustomCommandForm}>
          {(props) => <Form {...props} />}
        </Formik>
      </div>
    </>
  );
};

export default AddCommandDialog;
