import { ChangeEvent } from "react";
import { ErrorMessage, Field, Form, FormikProps } from "formik";
import { ReactComponent as UploadFileIcon } from "../../../../assets/images/uploadFile.svg";
import Checkbox from "../../../components/Checkbox";
import Select from "../../../components/Select";
import Spinner from "../../../components/Spinner";
import styles from "./styles.module.scss";
import { ManageFirmwareWisaFirmwareFormInitValuesInterface } from "../../../interfaces/fe.interfaces";
import { WisaProductModel } from "../../../interfaces/be.interfaces";

interface ManageFirmwareFormProps extends FormikProps<ManageFirmwareWisaFirmwareFormInitValuesInterface> {
  isEdit?: boolean;
  wisaProducts: WisaProductModel[];
}

const ManageFirmwareForm = ({
  isSubmitting,
  handleSubmit,
  values,
  setFieldValue,
  wisaProducts,
  setFieldTouched,
  isEdit = false,
  handleBlur,
}: ManageFirmwareFormProps) => {
  const onFirmwareBinChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFieldTouched("firmware_bin", true);
    setFieldValue("firmware_bin", event.target.files?.[0]);
  };

  const onChangeFirmwareMemoryLocation = (value: string) => {
    const regEx = /^(0[xX][0-9a-fA-F]+|0|0x|0X)$/;
    const isHex = regEx.test(value);

    // If value is empty or is a valid hex value, update the value
    if (isHex || value === "") setFieldValue("memory_location", value);
  };

  const onChangeVersion = (value: string) => {
    const regEx = /^[0-9.]+$/;

    // If value is empty or number or .
    if (regEx.test(value) || value === "") setFieldValue("version", value);
  };

  if (isSubmitting) return <Spinner />;

  return (
    <Form className={styles.wisaFirmwareUploadForm} onSubmit={handleSubmit}>
      <fieldset className={styles.wisaFirmwareUploadFormFieldset}>
        <div className={styles.wisaFirmwareUploadFormGridItem}>
          <Field
            id="version"
            name="version"
            type="text"
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeVersion(e?.target?.value)}
            className={styles.wisaFirmwareUploadFormField}
            placeholder="Add a firmware version"
            value={values.version}
          />
          <ErrorMessage className={styles.error} name="version" component="p" />
        </div>
        <div className={styles.wisaFirmwareUploadFormGridItem}>
          <Field
            id="description"
            name="description"
            type="text"
            className={styles.wisaFirmwareUploadFormField}
            placeholder="Add a firmware description"
            value={values.description}
          />
          <ErrorMessage className={styles.error} name="description" component="p" />
        </div>
        <div className={styles.wisaFirmwareUploadFormGridItem}>
          <Field
            id="memory_location"
            name="memory_location"
            type="text"
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeFirmwareMemoryLocation(e?.target?.value)}
            onBlur={handleBlur}
            className={styles.wisaFirmwareUploadFormField}
            placeholder="Add a firmware memory location"
            value={values.memory_location}
          />
          <ErrorMessage className={styles.error} name="memory_location" component="p" />
        </div>
        <div className={styles.wisaFirmwareUploadFormGridItem}>
          <Select
            selectProps={{
              name: "wisa_product",
              placeholder: "Select Product",
              value: values.wisa_product,
              onChange: (event) => setFieldValue("wisa_product", event.target.value),
            }}
            options={wisaProducts}
            optionLabel="type"
            optionValue="type"
          />
          <ErrorMessage className={styles.error} name="wisa_product" component="p" />
        </div>
        <div className={styles.wisaFirmwareUploadFormGridItem}>
          <p className={styles.wisaFirmwareUploadFormIconText}>Choose Firmware File</p>
          <label className={styles.wisaFirmwareUploadFormIconLabel} htmlFor="firmware_bin">
            <input id="firmware_bin" name="firmware_bin" type="file" onChange={onFirmwareBinChange} hidden />
            <UploadFileIcon className={styles.wisaFirmwareUploadFormUploadIcon} />
            <div className={styles.wisaFirmwareUploadFormUploadMessagesWrapper}>
              {values.firmware_bin?.name && <p className={styles.wisaFirmwareUploadFormFileName}>{values.firmware_bin?.name}</p>}
              <ErrorMessage className={styles.error} name="firmware_bin" component="p" />
            </div>
          </label>
        </div>
        <div className={styles.wisaFirmwareUploadFormCheckboxWrapper}>
          <Field
            id="current_customer_version"
            name="current_customer_version"
            type="checkbox"
            label="Firmware is current customer version?"
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setFieldValue("current_customer_version", event.target.checked);
            }}
            checked={values.current_customer_version}
            as={Checkbox}
            disabled={isEdit}
          />
          <ErrorMessage className={styles.error} name="current_customer_version" component="p" />
        </div>
        <button type="submit" className={styles.wisaFirmwareUploadFormGridItem}>
          Submit
        </button>
      </fieldset>
    </Form>
  );
};

export default ManageFirmwareForm;
