import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { Icons } from "../../../../assets/images";
import { changeCurrentMcuFirmwareVersion, getMcuFirmwareVersions } from "../../../api/config";
import AddMcuFirmwareVersion from "../../../components/AddOrEditMcuFirmwareVersion/AddMcuFirmwareVersion";
import EditMcuFirmwareVersionDialog from "../../../components/AddOrEditMcuFirmwareVersion/EditMcuFirmwareVersion";
import AccessGuard from "../../../components/Guards/AccessGuard";
import Switch from "../../../components/Switch";
import { useFetch } from "../../../hooks/useFetch";
import { GetMcuFirmwareVersionsResponse, UpdateItemResponse } from "../../../interfaces/be.interfaces";
import { McuFirmwareVersionPayload } from "../../../interfaces/fe.interfaces";
import { PERMISSIONS } from "../../../utils/constants";
import styles from "./styles.module.scss";

/**
 * This component will responsible for list and manage mcu firmware versions
 */
const ManageFirmwareMcuFirmware = () => {
  const [addDialogVisible, setAddDialogVisible] = useState(false);
  const [editMcuDialogDetails, setEditMcuDialogDetails] = useState<McuFirmwareVersionPayload | null>(null);

  // Hook to get the list of the mcu firmware version
  const {
    data: mcuFirmwareVersions,
    error: mcuFirmwareVersionsError,
    run: runList,
  } = useFetch<GetMcuFirmwareVersionsResponse>({
    url: getMcuFirmwareVersions,
    runOnMount: true,
  });

  const mcuFirmwareList = mcuFirmwareVersions?.data;

  const createOrEditUserCallback = () => {
    // if successful then re-run the mcu firmware query to get the updated list
    runList({
      showLoader: false,
    });
  };

  // Hook to update the status of the user
  const { run: runStatusUpdate, error: statusUpdateError } = useFetch<UpdateItemResponse>({
    url: changeCurrentMcuFirmwareVersion,
    runOnMount: false,
    onSuccess: (res) => {
      toast.success(res?.data?.message);

      // if successful then re-run the mcu firmware query to get the updated list
      runList({
        showLoader: false,
      });
    },
  });

  const handleChangeStatus = useCallback(
    (id: number): void => {
      runStatusUpdate({
        url: `${changeCurrentMcuFirmwareVersion}/${id}`,
      });
    },
    [runStatusUpdate]
  );

  return (
    <>
      <Tooltip id="info-tooltip" />
      {mcuFirmwareVersionsError || statusUpdateError ? (
        <div className="errorAlert">{mcuFirmwareVersionsError || statusUpdateError}</div>
      ) : null}
      <div className={styles.tableResponsive}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Customer Name</th>
              <th>MCU Model</th>
              <th>Firmware Version</th>
              <th>Firmware Description</th>
              <th>Firmware File Name</th>
              <AccessGuard permission={PERMISSIONS.UPDATE_MCU_FIRMWARE_VERSION_VERSION}>
                <th className={styles.action}>
                  Current Customer Version{" "}
                  <span
                    data-tooltip-id="info-tooltip"
                    data-tooltip-content={"Only one version can be enabled at a time for each vendor's each MCU model"}
                    data-tooltip-place="top"
                    className={styles.info}>
                    &nbsp;&#9432;
                  </span>
                </th>
              </AccessGuard>
              <AccessGuard permission={PERMISSIONS.MCU_FIRMWARE_VERSION_UPDATE}>
                <th className={styles.action}>Action</th>
              </AccessGuard>
            </tr>
          </thead>
          <tbody>
            {mcuFirmwareList?.length ? (
              mcuFirmwareList?.map((item) => {
                return (
                  <tr key={item.mcu_firmware_id}>
                    <td>{item?.customer?.customer_name ?? "-"}</td>
                    <td>{item.mcu_model}</td>
                    <td>{item.firmware_version}</td>
                    <td>{item.firmware_description}</td>
                    <td>{item.firmware_file_name}</td>
                    <AccessGuard permission={PERMISSIONS.UPDATE_MCU_FIRMWARE_VERSION_VERSION}>
                      <td className={styles.switchinfo}>
                        <Switch
                          checked={item.current_customer_version}
                          name={"user"}
                          disabled={item.current_customer_version}
                          onChange={() => !item.current_customer_version && handleChangeStatus(item.mcu_firmware_id)}
                        />
                      </td>
                    </AccessGuard>
                    <AccessGuard permission={PERMISSIONS.MCU_FIRMWARE_VERSION_UPDATE}>
                      <td>
                        <Icons.EditIcon
                          className={styles.button}
                          onClick={() =>
                            setEditMcuDialogDetails({
                              firmware_description: item.firmware_description,
                              mcu_model: item.mcu_model,
                              vendor_id: String(item.vendor_id),
                              firmware_version: item.firmware_version,
                              mcu_firmware_id: item.mcu_firmware_id,
                              firmware_file_name: item.firmware_file_name,
                              current_customer_version: item.current_customer_version,
                            })
                          }
                        />
                      </td>
                    </AccessGuard>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={10}>
                  <div className={`${styles.emptyPage} d-flex justify-content-center align-item-center`}>
                    <span className={styles.noDataFound}>No data found</span>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <AccessGuard permission={PERMISSIONS.MCU_FIRMWARE_VERSION_CREATE}>
        <div className={styles.addButton}>
          <button onClick={() => setAddDialogVisible(true)}>Add {mcuFirmwareList?.length ? "Another" : ""} MCU Firmware</button>
        </div>
      </AccessGuard>
      {addDialogVisible ? (
        <AddMcuFirmwareVersion callback={createOrEditUserCallback} onCancelClick={() => setAddDialogVisible(false)} />
      ) : null}
      {editMcuDialogDetails ? (
        <EditMcuFirmwareVersionDialog
          id={editMcuDialogDetails.mcu_firmware_id}
          initialValues={editMcuDialogDetails}
          callback={createOrEditUserCallback}
          onCancelClick={() => setEditMcuDialogDetails(null)}
        />
      ) : null}
    </>
  );
};

export default ManageFirmwareMcuFirmware;
