import { Formik, FormikHelpers } from "formik";
import Images from "../../../assets/images";
import styles from "./styles.module.scss";
import { LoginValidation } from "../../utils/validationSchemas";
import { TOAST_TIMEOUT } from "../../utils/constants";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { LoginPayload } from "../../interfaces/fe.interfaces";
import { useCallback } from "react";
import { http } from "../../api/utils/http";
import { LoginResponse } from "../../interfaces/be.interfaces";
import { login } from "../../api/config";
import { AxiosResponse } from "axios";

const Login = () => {
  const navigate = useNavigate();

  const handleSubmit = useCallback((values: LoginPayload, { resetForm, setSubmitting }: FormikHelpers<LoginPayload>) => {
    const payload = {
      email: values.email,
      password: values.password,
    };

    // Success callback for /add-user API
    const onSuccess = ({ data: response }: AxiosResponse<LoginResponse>) => {
      setSubmitting(false);
      if (response?.status) {
        // Display success toast and call parent callback to refresh the table
        toast.success(response?.message);

        resetForm();

        // Navigate to home page
        navigate("/");

        http.setAuthToken(response.data.token);
      } else {
        toast.error(response?.message);
      }
    };

    // Calls /login API
    http.makePostRequest<LoginResponse, { message: string }>(
      login,
      onSuccess,
      (error) => {
        setSubmitting(false);
        toast.error(error?.response?.data?.message);
      },
      payload
    );
  }, []);

  return (
    <>
      <div className={styles.pageContainer}>
        <div className={styles.loginBox}>
          <div className={styles.headerSection}>
            <img src={Images.WiSALogo} />
            <div>
              <h2>Welcome</h2>
              <p>Login to continue to WiSA</p>
            </div>
          </div>
          <Formik initialValues={{ email: "", password: "" }} validationSchema={LoginValidation} onSubmit={handleSubmit}>
            {({ values, handleChange, handleBlur, touched, errors, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className={styles.inputsSection}>
                  <div className={styles.inputItem}>
                    <label>Email</label>
                    <input
                      type="text"
                      placeholder="Enter email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched?.email && errors?.email ? <span className={styles.error}>{errors?.email}</span> : null}
                  </div>
                  <div className={styles.inputItem}>
                    <label>Password</label>
                    <input
                      type="password"
                      placeholder="Enter password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched?.password && errors?.password ? <span className={styles.error}>{errors?.password}</span> : null}
                  </div>
                  <button type="submit">Login</button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <ToastContainer newestOnTop autoClose={TOAST_TIMEOUT} />
    </>
  );
};

export default Login;
