import { ReactNode, createContext, useReducer } from "react";

import { http } from "../api/utils/http";
import { getSequences } from "../api/config";
import { AppAction, AppContextType, AppInitState, ScanItem } from "../interfaces/fe.interfaces";
import { SequencesData } from "../interfaces/be.interfaces";
import { AxiosResponse } from "axios";
import { ROLE_ID } from "../utils/constants";
import { I2CDriver } from "../utils/i2c";
import { FTDIDriver } from "../utils/ftdi";
import { SerialDriver } from "../utils/serial";

export const FETCH_PAGE_DATA_END = "FETCH_PAGE_DATA_END";
export const SET_SCAN = "SET_SCAN";
export const SET_SESSION_ID = "SET_SESSION_ID";
export const SET_CONNECTED_PORT = "SET_CONNECTED_PORT";

export const APP_INIT_STATE: AppInitState = {
  isLoading: true,
  pageData: null,
  user: null,
  userHasOEMAccess: false,
  error: null,
  scan: [],
  sessionId: "",
  connectedPort: null,
};

/**
 * This is updater for the reducer states which called whenever dispatch action is called
 * Default returns previously updated state or initial state
 */
export const reducer = (state: AppInitState, action: AppAction): AppInitState => {
  switch (action.type) {
    case FETCH_PAGE_DATA_END:
      return {
        ...state,
        isLoading: false,
        pageData: action?.payload.sequence,
        user: action?.payload.user,
        userHasOEMAccess: action?.payload.userHasOEMAccess,
      };

    case SET_SCAN:
      return {
        ...state,
        scan: action.payload,
      };

    case SET_SESSION_ID:
      return {
        ...state,
        sessionId: action?.payload,
      };

    case SET_CONNECTED_PORT:
      return {
        ...state,
        connectedPort: action.payload,
      };

    default:
      return state;
  }
};

// Initialize context
export const AppContext = createContext<AppContextType | null>(null);

interface ProviderProps {
  children: ReactNode;
}

export const AppContextProvider = ({ children }: ProviderProps) => {
  const [state, dispatch] = useReducer(reducer, APP_INIT_STATE);

  /**
   * Fetches page data from API
   */
  const fetchPageData = () => {
    // Called with page data when API response with success status
    const onSuccess = ({ data: response }: AxiosResponse<SequencesData>) => {
      const user = response.data.user;

      // When user is manager or executive and customer is OEM or
      // user is WiSA admin, automation engineer or WiSA sales then user has OEM access
      // For technician, OEM access is always false
      const userHasOEMAccess =
        user.customer?.manufacturer ||
        user.customer?.customer_info === "OEM" ||
        [ROLE_ID.WISA_ADMIN, ROLE_ID.AUTOMATION_ENGINEER, ROLE_ID.WISA_SALES].includes(user.role_id);

      dispatch({
        type: FETCH_PAGE_DATA_END,
        payload: {
          sequence: response.data.sequence,
          userHasOEMAccess,
          user,
        },
      });
    };

    http.makeGetRequest<SequencesData>(getSequences, onSuccess, (err) => {
      console.log(err);
    });
  };

  const setScan = (scan: ScanItem[]) => {
    dispatch({
      type: SET_SCAN,
      payload: scan,
    });
  };

  /**
   * Sets sessionId on header to be later used on API calls
   * @param {string} sessionId - sessionId that is recieved from server
   */
  const setSessionId = (sessionId: string) => {
    dispatch({
      type: SET_SESSION_ID,
      payload: sessionId,
    });
    http.setSequenceToken(sessionId);
  };

  /**
   * Sets connected port
   * Depending on the port, the app will be able to connect to the device.
   */
  const setConnectedPort = (port: I2CDriver | FTDIDriver | SerialDriver | null) => {
    dispatch({
      type: SET_CONNECTED_PORT,
      payload: port,
    });
  };

  return <AppContext.Provider value={{ ...state, fetchPageData, setScan, setSessionId, setConnectedPort }}>{children}</AppContext.Provider>;
};
