import { useEffect } from "react";
import { useRef } from "react";
import { getMacProcessInfo } from "../../api/config";
import { useFetch } from "../../hooks/useFetch";
import { ImportMacProcessInfo } from "../../interfaces/be.interfaces";
import Spinner from "../Spinner";
import styles from "./styles.module.scss";

interface MacImportDetailsDialogProps {
  macProcessId: string | null;
  handleCloseDetailsDialog: () => void;
}

/**
 * Dialog to show the status of the mac import process
 * Shows the total number of macs, success count and fail count with an estimated time to finish
 */
const MacImportDetailsDialog = (props: MacImportDetailsDialogProps) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  // Fetch data from the api to get the status of the mac import process
  const { data, loading, run, error } = useFetch<ImportMacProcessInfo>({
    url: `${getMacProcessInfo}/${props.macProcessId}`,
    runOnMount: true,
    onSuccess: () => {
      // Calls the api every 5 seconds to get the updated status of the mac import process
      timeoutRef.current = setTimeout(() => {
        run({
          showLoader: false,
        });
      }, 5000);
    },
  });

  // Clear the timeout when the component unmounts to stop the api calls
  useEffect(() => {
    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <div className={styles.dialogContainer}>
      <div className={styles.dialog}>
        {loading ? (
          <Spinner />
        ) : (
          <>
            {data?.data && !error ? (
              <>
                <div className={styles.statusBoxContainer}>
                  <div className={styles.statusBox}>
                    <span>Total</span>
                    <span>{data?.data?.total}</span>
                  </div>
                  <div className={styles.statusBox}>
                    <span>Success</span>
                    <span>{data?.data?.successCount}</span>
                  </div>
                  <div className={styles.statusBox}>
                    <span>Failed</span>
                    <span>{data?.data?.failCount}</span>
                  </div>
                </div>
                <span>Estimated Time to Finish: {data?.data?.estimatedTime}</span>
              </>
            ) : (
              <>{error}</>
            )}
            <div className={styles.buttonContainer}>
              <button className={styles.dialogBtn} onClick={props.handleCloseDetailsDialog}>
                Close
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MacImportDetailsDialog;
