import { AxiosResponse } from "axios";
import { Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { editInputSequence } from "../../api/config";
import { http } from "../../api/utils/http";
import { GetWisaCommandDetails } from "../../interfaces/be.interfaces";
import { CustomSequencePayload } from "../../interfaces/fe.interfaces";
import AddOrUpdateSeqForm from "./Form";
import styles from "./styles.module.scss";
import Modal from "../Modal/Modal";
import { CustomSequenceValidationSchema } from "../../utils/validationSchemas";

interface EditSeqDialogProps {
  callback: () => void | Promise<void>;
  onCancelClick: () => void | Promise<void>;
  initialValues: CustomSequencePayload;
}

// DialogBox component for create new sequence
const EditSeqDialog = (props: EditSeqDialogProps) => {
  const navigate = useNavigate();
  const { id } = useParams();

  /**
   * Submit handler for update sequence form
   */
  const handleAddSequence = (values: CustomSequencePayload): void => {
    const onSuccess = ({ data: response }: AxiosResponse<GetWisaCommandDetails>) => {
      if (response?.status) {
        // Updating URL slug with the new title
        navigate(`/update-custom-seq/${response.data?.seq_id}`);
        props.callback();
      } else {
        toast.error(response?.message || "Something went wrong.");
      }
    };

    http.makePutRequest<GetWisaCommandDetails, { message: string }>(
      editInputSequence + id,
      onSuccess,
      (error) => {
        toast.error(error?.response?.data?.message || "Something went wrong.");
      },
      values
    );
  };

  return (
    <Modal isModalOpen={true} onModalClose={props.onCancelClick}>
      <div className={styles.dialogContainer}>
        <Formik initialValues={props.initialValues} validationSchema={CustomSequenceValidationSchema} onSubmit={handleAddSequence}>
          {(formikProps) => <AddOrUpdateSeqForm {...formikProps} onCancelClick={props.onCancelClick} isEdit />}
        </Formik>
      </div>
    </Modal>
  );
};

export default EditSeqDialog;
