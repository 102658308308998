import React from "react";
import { Log } from "../../interfaces/fe.interfaces";
import styles from "./styles.module.scss";

interface CommandResponseProps {
  logs: Log[];
}

const CommandResponse = (props: CommandResponseProps) => {
  return (
    <div className={styles.logsContainer}>
      <p>Command Logs</p>
      <div className={styles.responseContainer}>
        {props?.logs?.map((log, index) => (
          <div className={styles.response} key={index}>
            <p>{log.commandName}</p>
            <p>{log.value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CommandResponse;
