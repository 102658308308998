import { NavLink, Outlet } from "react-router-dom";
import { NavigationRoutesEnum } from "../../interfaces/fe.interfaces";
import styles from "./styles.module.scss";
import AccessGuard from "../../components/Guards/AccessGuard";
import { PERMISSIONS } from "../../utils/constants";

const ManageFirmware = () => {
  const { MANAGE_FIRMWARE_ROUTE, AMP_CONFIGURATION_ROUTE, MCU_FIRMWARE_ROUTE, CHIME_CONFIGURATION_ROUTE } = NavigationRoutesEnum;

  return (
    <div>
      <h1>Manage Firmware</h1>
      <nav className={styles.tabsContainer}>
        <AccessGuard permission={PERMISSIONS.FIRMWARE_LIST}>
          <NavLink className={({ isActive }) => (isActive ? styles.active : "")} to={`/${MANAGE_FIRMWARE_ROUTE}`} end>
            WiSA Firmware
          </NavLink>
        </AccessGuard>
        <AccessGuard permission={PERMISSIONS.AMP_CONFIGURATION_LIST}>
          <NavLink className={({ isActive }) => (isActive ? styles.active : "")} to={AMP_CONFIGURATION_ROUTE}>
            AMP Configuration
          </NavLink>
        </AccessGuard>
        <AccessGuard permission={PERMISSIONS.MCU_FIRMWARE_VERSION_LIST}>
          <NavLink className={({ isActive }) => (isActive ? styles.active : "")} to={MCU_FIRMWARE_ROUTE}>
            MCU Firmware
          </NavLink>
        </AccessGuard>
        <AccessGuard permission={PERMISSIONS.CHIME_CONFIGURATION_LIST}>
          <NavLink className={({ isActive }) => (isActive ? styles.active : "")} to={CHIME_CONFIGURATION_ROUTE}>
            Chime Configuration
          </NavLink>
        </AccessGuard>
      </nav>
      <div className={styles.dataContainer}>
        <Outlet />
      </div>
    </div>
  );
};

export default ManageFirmware;
