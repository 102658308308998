/**
 * This file contains constants used in code
 */

import { TableHeader } from "../components/CalibrationTable";
import { InputEVM, InputPPM, InputRxGain, InputTestResults, InputTxPo } from "../interfaces/be.interfaces";

export enum COMMAND_STATUS {
  Success = "success",
  Pending = "pending",
  InProgress = "inProgress",
  Failed = "failed",
  Aborted = "aborted",
  Skipped = "skipped",
}

export const STATUS_CLASS_MAPPING = {
  [COMMAND_STATUS.Success]: {
    label: "Successful",
    className: "success",
  },
  [COMMAND_STATUS.Pending]: {
    label: "Pending",
    className: "pending",
  },
  [COMMAND_STATUS.InProgress]: {
    label: "In Progress",
    className: "inProgress",
  },
  [COMMAND_STATUS.Failed]: {
    label: "Failed",
    className: "failed",
  },
  [COMMAND_STATUS.Aborted]: {
    label: "Aborted",
    className: "failed",
  },
  [COMMAND_STATUS.Skipped]: {
    label: "Skipped",
    className: "skipped",
  },
};

/*
 * start commands and stop commands are used in runSequence function to check if command is start or stop command
 * If command is start command then we start capturing response from device to an Array for sending it to backend
 * If command is stop command we stop capturing response from device
 *
 * INIT_SEQ_START_COMMAND & INIT_SEQ_STOP_COMMAND are representing init sequence start and stop command
 */
export const START_COMMAND = 115;
export const STOP_COMMAND = 112;
export const STATUS_COMMAND = 128;
export const INIT_SEQ_START_COMMAND = 101;
export const STATUS_COMMAND_RESPONSE = 0;

/**
 * For init sequence device timeout will be 1s because we need to timeout ASAP if init sequence failed
 * For any other command we can use 15s timeout, this is used as minimum timeout because few commands in firmware update needs 9s timeout
 */
export const DEVICE_TIMEOUT = 25000;
export const INIT_SEQ_DEVICE_TIMEOUT = 1000;

export const userConfirmationMessage = "If you leave the page, sequence will stop running!";
export const TOAST_TIMEOUT = 2000;

/**
 * This is used for mapping the table headers in calibration table
 */
export const TXPO_TABLE_HEADERS: TableHeader<InputTxPo>[] = [
  {
    name: "Channel",
    key: "channel",
    backendKey: "",
  },
  {
    name: "Center Freq(MHz)",
    key: "center_freq",
    backendKey: "",
  },
  {
    name: "TxPo (raw)",
    backendKey: "tx_po_raw",
    inputType: "number",
  },
  {
    name: "DE",
    backendKey: "de",
    inputType: "number",
  },
  {
    name: "TxPo (w/cal)",
    backendKey: "tx_po_calc",
    inputType: "number",
  },
  {
    name: "Thermal",
    backendKey: "thermal",
    inputType: "hex",
  },
];

export const RXGAIN_TABLE_HEADERS: TableHeader<InputRxGain>[] = [
  {
    name: "Channel",
    key: "channel",
    backendKey: "",
  },
  {
    name: "Center Freq (MHz)",
    key: "center_freq",
    backendKey: "",
  },
  {
    name: "RSSI(raw)",
    backendKey: "rssi_raw",
  },
  {
    name: "ESG",
    backendKey: "esg",
  },
  {
    name: "RSSI(w/cal)",
    backendKey: "rssi_calc",
  },
];

export const EVM_TABLE_HEADERS: TableHeader<InputEVM>[] = [
  {
    name: "Channel",
    key: "channel",
    backendKey: "",
  },
  {
    name: "Center Freq (MHz)",
    key: "center_freq",
    backendKey: "",
  },
  {
    name: "EVM %",
    backendKey: "evm",
  },
];

export const PPM_TABLE_HEADERS: TableHeader<InputPPM>[] = [
  {
    name: "Channel",
    key: "channel",
    backendKey: "",
  },
  {
    name: "Center Freq (MHz)",
    key: "center_freq",
    backendKey: "",
  },
  {
    name: "PPM(raw)",
    backendKey: "ppm_raw",
  },
  {
    name: "PPM(adjusted)",
    key: "ppm_raw_adjusted",
    backendKey: "",
  },
  {
    name: "Xtal_K",
    backendKey: "xtal_k",
  },
  {
    name: "PPM(w/cal)",
    backendKey: "ppm_calc",
  },
  {
    name: "PPM(w/cal adjusted)",
    key: "ppm_calc_adjusted",
    backendKey: "",
  },
];

export const TEST_RESULTS_TABLE_HEADERS: TableHeader<InputTestResults>[] = [
  {
    name: "Channel",
    key: "channel",
    backendKey: "",
  },
  {
    name: "Center Freq (MHz)",
    key: "center_freq",
    backendKey: "",
  },
  {
    name: "TxPo",
    backendKey: "tx_po",
  },
  {
    name: "EVM",
    backendKey: "evm",
  },
  {
    name: "PPM(measured)",
    backendKey: "ppm",
  },
  {
    name: "PPM(adjusted)",
    key: "ppm_adjusted",
    backendKey: "",
  },
  {
    name: "Sensitivity",
    backendKey: "sensitivity",
  },
  {
    name: "RxGain",
    backendKey: "rx_gain",
  },
];

export const DB_MAX_SAFE_INTEGER = Math.pow(2, 31) - 1;
export const DESC_CHAR_LIMIT = 300;
export const HEX_LOG_COMMAND_NAMES = [{ name: "GET_SPK_MASK", reverse: true }];

export const USA_REGION_CODE = "0x01h";

export const SET_CONFIGURATION_SEQ_NAME = "Set Configuration";
export const SET_OEM_INFO_COMMAND_NAME = "SET_OEM_INFO";
export const TEST_RSSI_COMMAND_ID = "0x05ff";

export const FLOATING_NUM_REGEX = /^-?\d*\.?[0-9]*$/;
export const ALPHA_NUM_DASH_UNDERSCORE_REGEX = /^[a-zA-Z0-9_-\s]*$/;

export enum PERMISSIONS {
  CUSTOM_SEQUENCE_LIST = "custom_sequence.get",
  CUSTOM_SEQUENCE_ADD = "custom_sequence.add",
  CUSTOM_SEQUENCE_UPDATE = "custom_sequence.update",
  CUSTOM_SEQUENCE_DELETE = "custom_sequence.delete",
  CUSTOM_SEQUENCE_ENABLE_DISABLE = "custom_sequence.enable_disable",

  CUSTOM_COMMAND_LIST = "custom_command.get",
  CUSTOM_COMMAND_ADD = "custom_command.add",
  CUSTOM_COMMAND_UPDATE = "custom_command.update",
  CUSTOM_COMMAND_ENABLE_DISABLE = "custom_command.enable_disable",
  CUSTOM_COMMAND_DELETE = "custom_command.delete",

  CUSTOMER_LIST = "customer.get",
  CUSTOMER_ADD = "customer.add",
  CUSTOMER_UPDATE = "customer.update",
  CUSTOMER_ENABLE_DISABLE = "customer.enable_disable",
  UPDATE_CUSTOMER_MODULE = "customer.update_module",

  MAC_LIST = "mac.get",
  MAC_ADD = "mac.add",

  PRODUCT_LIST = "product.get",
  PRODUCT_UPDATE = "product.update",
  PRODUCT_ADD = "product.add",
  PRODUCT_ENABLE_DISABLE = "product.enable_disable",
  PRODUCT_MODEL_ADD = "product_model.add",
  PRODUCT_MODEL_UPDATE = "product_model.update",
  PRODUCT_MODEL_ENABLE_DISABLE = "product_model.enable_disable",
  PRODUCT_MODEL_DELETE = "product_model.delete",

  USER_LIST = "user.get",
  USER_ADD = "user.add",
  USER_UPDATE = "user.update",
  USER_DELETE = "user.delete",
  USER_ENABLE_DISABLE = "user.enable_disable",

  FIRMWARE_LIST = "firmware.get",
  FIRMWARE_UPLOAD = "firmware.upload",
  UPDATE_FIRMWARE_VERSION = "firmware.update_version",

  SEQUENCE_EXECUTION = "sequence.execution",
  SWAGGER = "swagger",

  AMP_CONFIGURATION_CREATE = "amp_configuration.create",
  AMP_CONFIGURATION_LIST = "amp_configuration.get",
  AMP_CONFIGURATION_UPDATE = "amp_configuration.update",
  AMP_CONFIGURATION_UPDATE_VERSION = "amp_configuration.update_version",

  MCU_FIRMWARE_VERSION_CREATE = "mcu_firmware_version.create",
  MCU_FIRMWARE_VERSION_LIST = "mcu_firmware_version.get",
  MCU_FIRMWARE_VERSION_UPDATE = "mcu_firmware_version.update",
  UPDATE_MCU_FIRMWARE_VERSION_VERSION = "mcu_firmware_version.update_version",

  CHIME_CONFIGURATION_CREATE = "chime_configuration.create",
  CHIME_CONFIGURATION_LIST = "chime_configuration.get",
  CHIME_CONFIGURATION_UPDATE = "chime_configuration.update",
  CHIME_CONFIGURATION_UPDATE_VERSION = "chime_configuration.update_version",

  SKIP_DEVICE_PROVISIONING = "skip_device_provisioning",

  CERT_APP_GET = "manage_cert_app.get",
  CERT_APP_UPLOAD = "manage_cert_app.create",
  CERT_APP_UPDATE_VERSION = "manage_cert_app.update_version",
}

export enum ROLE_ID {
  WISA_ADMIN = "wisa_admin",
  MANAGER = "manager",
  TECHNICIAN = "technician",
  AUTOMATION_ENGINEER = "automation_engineer",
  EXECUTIVE = "executive",
  WISA_SALES = "wisa_sales",
}

export enum CUSTOMER_MODULES {
  MANAGE_SOFTWARE = "MANAGE_SOFTWARE",
  CUSTOM_SEQUENCE = "CUSTOM_SEQUENCE",
  CALIBRATION = "CALIBRATION",
  MODULE_PROGRAMMING = "MODULE_PROGRAMMING",
  DEVICE_CONFIGURATION = "DEVICE_CONFIGURATION",
  REMOTE_COMMANDS = "REMOTE_COMMANDS",
  SPEAKER_TESTING = "SPEAKER_TESTING",
  SYSTEM_TESTING = "SYSTEM_TESTING",
  CUSTOM_COMMAND = "CUSTOM_COMMAND",
  MANAGE_PRODUCT = "MANAGE_PRODUCT",
  MANAGE_CUSTOMER = "MANAGE_CUSTOMER",
  MANAGE_USER = "MANAGE_USER",
}

// This constant will use for show all the title and module
// in manage customer module access model
export const CUSTOMER_MODULE_WITH_TITLE = [
  { title: "Module Programming", module: CUSTOMER_MODULES.MODULE_PROGRAMMING },
  { title: "Device Configuration", module: CUSTOMER_MODULES.DEVICE_CONFIGURATION },
  { title: "Remote Commands", module: CUSTOMER_MODULES.REMOTE_COMMANDS },
  { title: "Speaker Testing", module: CUSTOMER_MODULES.SPEAKER_TESTING },
  { title: "System Testing", module: CUSTOMER_MODULES.SYSTEM_TESTING },
  { title: "Custom Sequence", module: CUSTOMER_MODULES.CUSTOM_SEQUENCE },
  { title: "Custom Command", module: CUSTOMER_MODULES.CUSTOM_COMMAND },
  { title: "Manage Software", module: CUSTOMER_MODULES.MANAGE_SOFTWARE },
  { title: "Calibration", module: CUSTOMER_MODULES.CALIBRATION },
  { title: "Manage Product", module: CUSTOMER_MODULES.MANAGE_PRODUCT },
  { title: "Manage Customer", module: CUSTOMER_MODULES.MANAGE_CUSTOMER },
  { title: "Manage User", module: CUSTOMER_MODULES.MANAGE_USER },
];

export enum SAMPLE_RATE_E {
  SAMPLE_RATE_INVALID,
  SAMPLE_RATE_AUTO,
  SAMPLE_RATE_44_1K,
  SAMPLE_RATE_48K,
  SAMPLE_RATE_88_2K,
  SAMPLE_RATE_96K,
  SAMPLE_RATE_176_4K,
  SAMPLE_RATE_192K,
}

export const SAMPLE_RATE_ENTERPRISE_LABEL_MAP = {
  [SAMPLE_RATE_E.SAMPLE_RATE_INVALID]: "Invalid",
  [SAMPLE_RATE_E.SAMPLE_RATE_AUTO]: "Auto",
  [SAMPLE_RATE_E.SAMPLE_RATE_44_1K]: "44.1K",
  [SAMPLE_RATE_E.SAMPLE_RATE_48K]: "48K",
  [SAMPLE_RATE_E.SAMPLE_RATE_88_2K]: "88.2K",
  [SAMPLE_RATE_E.SAMPLE_RATE_96K]: "96K",
  [SAMPLE_RATE_E.SAMPLE_RATE_176_4K]: "176.4",
  [SAMPLE_RATE_E.SAMPLE_RATE_192K]: "192K",
};

const SAMPLE_RATE_E_SUPPORTED = {
  SAMPLE_RATE_44_1K: SAMPLE_RATE_E.SAMPLE_RATE_44_1K,
  SAMPLE_RATE_48K: SAMPLE_RATE_E.SAMPLE_RATE_48K,
  SAMPLE_RATE_96K: SAMPLE_RATE_E.SAMPLE_RATE_96K,
};

export const SAMPLE_RATE_ENTERPRISE = SAMPLE_RATE_E_SUPPORTED;

export enum E_PCM_FORMAT {
  PCM_FORMAT_UNKNOWN, // Unknown
  PCM_FORMAT_S8, // Signed 8 bit
  PCM_FORMAT_S16_LE, // Signed 16 bit Little Endian
  PCM_FORMAT_S24_3LE, // Signed 24 bit Little Endian in 3bytes format
  PCM_FORMAT_S32_LE, // Signed 32 bit Little Endian
  PCM_FORMAT_S24_LE, // Signed 24 bit Little Endian using low three bytes in 32-bit word
  PCM_FORMAT_S24_H_LE, // Signed 24 bit Little Endian using high three bytes in 32-bit word
  PCM_FORMAT_INVALID, // Invalid
}

export const PCM_FORMAT_ENTERPRISE_LABEL_MAP = {
  [E_PCM_FORMAT.PCM_FORMAT_UNKNOWN]: "Unknown",
  [E_PCM_FORMAT.PCM_FORMAT_S8]: "S8",
  [E_PCM_FORMAT.PCM_FORMAT_S16_LE]: "S16 LE",
  [E_PCM_FORMAT.PCM_FORMAT_S24_3LE]: "S24 3LE",
  [E_PCM_FORMAT.PCM_FORMAT_S32_LE]: "S32 LE",
  [E_PCM_FORMAT.PCM_FORMAT_S24_LE]: "S24 LE",
  [E_PCM_FORMAT.PCM_FORMAT_S24_H_LE]: "S24 H LE",
  [E_PCM_FORMAT.PCM_FORMAT_INVALID]: "Invalid",
};

const E_PCM_FORMAT_SUPPORTED = {
  PCM_FORMAT_UNKNOWN: E_PCM_FORMAT.PCM_FORMAT_UNKNOWN,
  PCM_FORMAT_S16_LE: E_PCM_FORMAT.PCM_FORMAT_S16_LE,
  PCM_FORMAT_S32_LE: E_PCM_FORMAT.PCM_FORMAT_S32_LE,
  PCM_FORMAT_S24_LE: E_PCM_FORMAT.PCM_FORMAT_S24_LE,
  PCM_FORMAT_S24_H_LE: E_PCM_FORMAT.PCM_FORMAT_S24_H_LE,
};

export const PCM_FORMAT_ENTERPRISE = E_PCM_FORMAT_SUPPORTED;

export const BITS_PER_FRAME_SAMPLE_ENTERPRISE = {
  FS32: 32,
  FS64: 64,
};

export const BITS_PER_FRAME_SAMPLE_ENTERPRISE_LABEL_MAP = {
  [BITS_PER_FRAME_SAMPLE_ENTERPRISE.FS32]: "FS32",
  [BITS_PER_FRAME_SAMPLE_ENTERPRISE.FS64]: "FS64",
};

export const TX_I2S_FORMAT_DEFAULT = {
  pcm_format: PCM_FORMAT_ENTERPRISE.PCM_FORMAT_S24_H_LE,
  bits_per_frame_sample: BITS_PER_FRAME_SAMPLE_ENTERPRISE.FS64,
  sample_rate: SAMPLE_RATE_ENTERPRISE.SAMPLE_RATE_48K,
};

export enum EXPRESS_DECODE_OPTIONS_ENUM {
  EXPRESS_2_0 = "2.0_express",
  EXPRESS_2_1 = "2.1_express",
  EXPRESS_ATMOS_2_1_2 = "2.1.2_express_atmos",
  EXPRESS_3_1 = "3.1_express",
  EXPRESS_ATMOS_3_1_2 = "3.1.2_express_atmos",
  EXPRESS_4_1 = "4.1_express",
  EXPRESS_ATMOS_4_1_2 = "4.1.2_express_atmos",
  EXPRESS_5_1 = "5.1_express",
  EXPRESS_DONGLE_HEIGHT_SURROUNDS_5_1_2 = "5.1.2_dongle_height_surrounds",
  EXPRESS_DONGLE_HEIGHT_FRONTS_5_1_2 = "5.1.2_dongle_height_fronts",
  EXPRESS_5_1_4 = "5.1.5_express",
  EXPRESS_7_1 = "7.1_express",
  EXPRESS_7_1_2 = "7.1.2_express",
  EXPRESS_7_1_4 = "7.1.4_express",
}

export enum LOCAL_SPEAKERS_ENUM {
  NONE_LOCAL = "none_local",
  FRONT_LEFT_LOCAL = "front_left_local",
  FRONT_RIGHT_LOCAL = "front_right_local",
  CENTER_LOCAL = "center_local",
  DUAL_HEIGHT_FRONT_LEFT_LOCAL = "dual_height_front_left_local",
  DUAL_HEIGHT_FRONT_RIGHT_LOCAL = "dual_height_front_right_local",
  HEIGHT_FRONT_LEFT_LOCAL = "height_front_left_local",
  HEIGHT_FRONT_RIGHT_LOCAL = "height_front_right_local",
}
