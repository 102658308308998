import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { Icons } from "../../../../assets/images";
import { changeCurrentAmpConfigVersion, getAmpConfigs } from "../../../api/config";
import AddAmpConfigDialog from "../../../components/AddOrEditAmplifier/AddAmpConfig";
import EditAmpConfigDialog from "../../../components/AddOrEditAmplifier/EditAmpConfig";
import AccessGuard from "../../../components/Guards/AccessGuard";
import Switch from "../../../components/Switch";
import { useFetch } from "../../../hooks/useFetch";
import { GetAmpConfigurationsResponse, UpdateItemResponse } from "../../../interfaces/be.interfaces";
import { AmpConfigPayload } from "../../../interfaces/fe.interfaces";
import { PERMISSIONS } from "../../../utils/constants";
import styles from "./styles.module.scss";
import { Tooltip } from "react-tooltip";

/**
 * This component will responsible for list and manage amp configurations
 */
const ManageFirmwareAmpConfiguration = () => {
  const [addDialogVisible, setAddDialogVisible] = useState(false);
  const [editAmpConfigDetails, setEditAmpConfigDetails] = useState<AmpConfigPayload | null>(null);

  // Hook to get the list of the amp config
  const {
    data: ampConfigs,
    error: ampConfigError,
    run: runList,
  } = useFetch<GetAmpConfigurationsResponse>({
    url: getAmpConfigs,
    runOnMount: true,
  });

  const ampConfigsList = ampConfigs?.data;

  const createOrEditCallback = useCallback(() => {
    // if successful then re-run the amp config query to get the updated list
    runList({
      showLoader: false,
    });
  }, [runList]);

  // Hook to update the status of the amp config
  const { run: runStatusUpdate, error: statusUpdateError } = useFetch<UpdateItemResponse>({
    url: getAmpConfigs,
    runOnMount: false,
    onSuccess: (res) => {
      toast.success(res?.data?.message);

      // if successful then re-run the amp config query to get the updated list
      runList({
        showLoader: false,
      });
    },
  });

  // Handle change for current customer version
  const changeCurrentVersion = useCallback(
    (id: number): void => {
      runStatusUpdate({
        url: `${changeCurrentAmpConfigVersion}/${id}`,
      });
    },
    [runStatusUpdate]
  );

  return (
    <>
      {ampConfigError || statusUpdateError ? <div className="errorAlert">{ampConfigError || statusUpdateError}</div> : null}
      <Tooltip id="info-tooltip" />
      <div className={styles.tableResponsive}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Customer Name</th>
              <th>Amplifier Model</th>
              <th>Config Version</th>
              <th>Config Description</th>
              <th>Config File Name</th>
              <AccessGuard permission={PERMISSIONS.AMP_CONFIGURATION_UPDATE_VERSION}>
                <th className={styles.action}>
                  Current Customer Version
                  <span
                    data-tooltip-id="info-tooltip"
                    data-tooltip-content={"Only one version can be enabled at a time for each vendor's each AMP model"}
                    data-tooltip-place="top"
                    className={styles.info}>
                    &nbsp;&#9432;
                  </span>
                </th>
              </AccessGuard>
              <AccessGuard permission={PERMISSIONS.AMP_CONFIGURATION_UPDATE}>
                <th className={styles.action}>Action</th>
              </AccessGuard>
            </tr>
          </thead>
          <tbody>
            {ampConfigsList?.length ? (
              ampConfigsList?.map((item) => {
                return (
                  <tr key={item.customer_amp_config_id}>
                    <td>{item?.customer?.customer_name ?? "-"}</td>
                    <td>{item.amp_model}</td>
                    <td>{item.config_version}</td>
                    <td>{item.config_description}</td>
                    <td>{item.config_file_name}</td>
                    <AccessGuard permission={PERMISSIONS.AMP_CONFIGURATION_UPDATE_VERSION}>
                      <td className={styles.switchinfo}>
                        <Switch
                          checked={item.current_customer_version}
                          disabled={item.current_customer_version}
                          name={"user"}
                          onChange={() => !item.current_customer_version && changeCurrentVersion(item.customer_amp_config_id)}
                        />
                      </td>
                    </AccessGuard>
                    <AccessGuard permission={PERMISSIONS.AMP_CONFIGURATION_UPDATE}>
                      <td>
                        <Icons.EditIcon
                          className={styles.button}
                          onClick={() =>
                            setEditAmpConfigDetails({
                              config_description: item.config_description,
                              amp_model: item.amp_model,
                              vendor_id: String(item.vendor_id),
                              config_version: item.config_version,
                              customer_amp_config_id: item.customer_amp_config_id,
                              config_file_name: item.config_file_name,
                              current_customer_version: item.current_customer_version,
                            })
                          }
                        />
                      </td>
                    </AccessGuard>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={10}>
                  <div className={`${styles.emptyPage} d-flex justify-content-center align-item-center`}>
                    <span className={styles.noDataFound}>No data found</span>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <AccessGuard permission={PERMISSIONS.AMP_CONFIGURATION_CREATE}>
        <div className={styles.addAmpConfigButton}>
          <button onClick={() => setAddDialogVisible(true)}>Add {ampConfigsList?.length ? "Another" : ""} Config</button>
        </div>
      </AccessGuard>
      {addDialogVisible ? (
        <AddAmpConfigDialog callback={createOrEditCallback} onCancelClick={() => setAddDialogVisible(false)} />
      ) : null}
      {editAmpConfigDetails ? (
        <EditAmpConfigDialog
          id={editAmpConfigDetails.customer_amp_config_id}
          initialValues={editAmpConfigDetails}
          callback={createOrEditCallback}
          onCancelClick={() => setEditAmpConfigDetails(null)}
        />
      ) : null}
    </>
  );
};

export default ManageFirmwareAmpConfiguration;
