import axios, { AxiosInstance } from "axios";
import { serializeQueryParams } from "./helpers";
import { Callback, ErrorCallback } from "./types";

export interface Headers {
  Authorization: string;
  sequenceToken: string;
}

class Http {
  private axiosInstance: AxiosInstance;
  private headers: Record<string, string>;

  constructor() {
    this.axiosInstance = axios.create();
    this.headers = {
      Authorization: localStorage.getItem("token") || "",
      sequenceToken: "",
    };

    this.axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        const { status } = error.response;
        if (status === 401) {
          // This will clear the local storage and navigate the user to login screen
          // Remove auth token and mac address
          this.setAuthToken("");
          this.setSequenceToken("");

          // Clear local storage
          localStorage.clear();

          // Navigate to login screen
          window.location.href = "/login";
        }
        return Promise.reject(error);
      }
    );
  }

  /**
   * Used for setting sequence token to header of API
   * @param {string} sequenceToken - Token received from API to pass in subsequent API calls
   */
  setSequenceToken(sequenceToken: string) {
    this.headers.sequenceToken = sequenceToken;
  }

  /**
   * Used for setting authorization token to header of API
   * @param {string} authToken - Token received from login API
   */
  setAuthToken(authToken: string) {
    // Update token in local storage
    localStorage.setItem("token", authToken);
    this.headers.Authorization = authToken;
  }

  /**
   * Call GET APIs
   * @param {string} path - API Endpoint to make request to
   * @param {function} callback - Function to be executed upon success reponse
   * @param {function} fail - Function to be executed upon fail reponse
   * @param {object} params - Get Parameter List
   */
  makeGetRequest<R = unknown, E = unknown>(path: string, callback: Callback<R>, fail: ErrorCallback<E>, params?: Record<string, any>) {
    if (params) path += serializeQueryParams(params);
    this.axiosInstance.get(path, { headers: this.headers }).then(callback).catch(fail);
  }

  /**
   * Call POST APIs
   * @param {string} path - API Endpoint to make request to
   * @param {function} callback - Function to be executed upon success response
   * @param {function} fail - Function to be executed upon fail response
   * @param {object} payload - POST API payload
   * @param {object} params - Get Parameter List
   */
  makePostRequest<R = unknown, E = unknown>(
    path: string,
    callback: Callback<R>,
    fail: ErrorCallback<E>,
    payload: Record<string, any>,
    params?: Record<string, any>
  ) {
    if (params != null) {
      path += serializeQueryParams(params);
    }
    this.axiosInstance.post(path, payload, { headers: this.headers }).then(callback).catch(fail);
  }

  /**
   * Call DELETE APIs
   * @param {string} path - API Endpoint to make request to
   * @param {function} callback - Function to be executed upon success response
   * @param {function} fail - Function to be executed upon fail response
   * @param {object} params - Get Parameter List
   */
  makeDeleteRequest<R = unknown, E = unknown>(path: string, callback: Callback<R>, fail: ErrorCallback<E>, params?: Record<string, any>) {
    if (params) path += serializeQueryParams(params);
    this.axiosInstance.delete(path, { headers: this.headers }).then(callback).catch(fail);
  }

  /**
   * Call PUT APIs
   * @param {string} path - API Endpoint to make request to
   * @param {function} callback - Function to be executed upon success response
   * @param {function} fail - Function to be executed upon fail response
   * @param {object} payload - PUT API payload
   * @param {object} params - Get Parameter List
   */
  makePutRequest<R = unknown, E = unknown>(
    path: string,
    callback: Callback<R>,
    fail: ErrorCallback<E>,
    payload: Record<string, any>,
    params?: Record<string, any>
  ) {
    if (params != null) {
      path += serializeQueryParams(params);
    }
    this.axiosInstance.put(path, payload, { headers: this.headers }).then(callback).catch(fail);
  }

  /**
   * Call POST APIs when payload is of type FormData
   * @param {string} path - API Endpoint to make request to
   * @param {function} callback - Function to be executed upon success response
   * @param {function} fail - Function to be executed upon fail response
   * @param {object} payload - POST API payload as FormData
   * @param {object} params - Get Parameter List
   */
  makePostRequestWithFormData<R = unknown, E = unknown>(path: string, callback: Callback<R>, fail: ErrorCallback<E>, payload: FormData) {
    this.axiosInstance
      .post(path, payload, {
        headers: {
          ...this.headers,
          "Content-Type": "multipart/form-data",
        },
      })
      .then(callback)
      .catch(fail);
  }
}

export const http = new Http();
