import { useState, useCallback } from "react";
import { changeCurrentChimeConfigVersion, getChimeConfigs } from "../../../api/config";
import AccessGuard from "../../../components/Guards/AccessGuard";
import { useFetch } from "../../../hooks/useFetch";
import { ChimeConfiguration, UpdateItemResponse } from "../../../interfaces/be.interfaces";
import { ChimeConfigPayload } from "../../../interfaces/fe.interfaces";
import { PERMISSIONS } from "../../../utils/constants";
import styles from "./styles.module.scss";
import { Icons } from "../../../../assets/images";
import Switch from "../../../components/Switch";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import AddChimeConfig from "../../../components/AddOrEditChime/AddChimeConfig";
import EditChimeConfig from "../../../components/AddOrEditChime/EditChimeConfig";

/**
 * This component will responsible for list and manage chime configurations
 */
const ManageFirmwareChimeConfiguration = () => {
  const [addDialogVisible, setAddDialogVisible] = useState(false);
  const [editDetails, setEditDetails] = useState<ChimeConfigPayload | null>(null);

  // Hook to get the list of the chime config
  const {
    data: chimeConfigs,
    error: chimeConfigError,
    run: runList,
  } = useFetch<UpdateItemResponse<ChimeConfiguration[]>>({
    url: getChimeConfigs,
    runOnMount: true,
  });

  const list = chimeConfigs?.data;

  // Update list when data will be added or updated
  const createOrEditCallback = useCallback(() => {
    // if successful then re-run the amp config query to get the updated list
    runList({
      showLoader: false,
    });
  }, [runList]);

  // Hook to update the status of the chime config
  const { run: runStatusUpdate, error: statusUpdateError } = useFetch<UpdateItemResponse>({
    url: getChimeConfigs,
    runOnMount: false,
    onSuccess: (res) => {
      toast.success(res?.data?.message);

      // if successful then re-run the chime config query to get the updated list
      runList({
        showLoader: false,
      });
    },
  });

  // Handle change for current customer version
  const changeCurrentVersion = useCallback(
    (id: number): void => {
      runStatusUpdate({
        url: `${changeCurrentChimeConfigVersion}/${id}`,
      });
    },
    [runStatusUpdate]
  );

  return (
    <>
      {chimeConfigError || statusUpdateError ? <div className="errorAlert">{chimeConfigError || statusUpdateError}</div> : null}
      <Tooltip id="info-tooltip" />
      <div className={styles.tableResponsive}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Customer Name</th>
              <th>Chime Audio</th>
              <th>Chime Version</th>
              <th>Chime Description</th>
              <th>Chime File Name</th>
              <AccessGuard permission={PERMISSIONS.AMP_CONFIGURATION_UPDATE_VERSION}>
                <th className={styles.action}>
                  Current Customer Version
                  <span
                    data-tooltip-id="info-tooltip"
                    data-tooltip-content={"Only one version can be enabled at a time for each vendor's each AMP model"}
                    data-tooltip-place="top"
                    className={styles.info}>
                    &nbsp;&#9432;
                  </span>
                </th>
              </AccessGuard>
              <AccessGuard permission={PERMISSIONS.AMP_CONFIGURATION_UPDATE}>
                <th className={styles.action}>Action</th>
              </AccessGuard>
            </tr>
          </thead>
          <tbody>
            {list?.length ? (
              list?.map((item) => {
                return (
                  <tr key={item.customer_chime_config_id}>
                    <td>{item?.customer?.customer_name ?? "-"}</td>
                    <td>{item.chime_audio}</td>
                    <td>{item.chime_version}</td>
                    <td>{item.chime_description}</td>
                    <td>{item.file_name}</td>
                    <AccessGuard permission={PERMISSIONS.AMP_CONFIGURATION_UPDATE_VERSION}>
                      <td className={styles.switchinfo}>
                        <Switch
                          checked={item.current_customer_version}
                          disabled={item.current_customer_version}
                          name={"user"}
                          onChange={() => !item.current_customer_version && changeCurrentVersion(item.customer_chime_config_id)}
                        />
                      </td>
                    </AccessGuard>
                    <AccessGuard permission={PERMISSIONS.AMP_CONFIGURATION_UPDATE}>
                      <td>
                        <Icons.EditIcon
                          className={styles.button}
                          onClick={() =>
                            setEditDetails({
                              chime_description: item.chime_description,
                              chime_audio: item.chime_audio,
                              vendor_id: String(item.vendor_id),
                              chime_version: item.chime_version,
                              customer_chime_config_id: item.customer_chime_config_id,
                              file_name: item.file_name,
                              current_customer_version: item.current_customer_version,
                            })
                          }
                        />
                      </td>
                    </AccessGuard>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={10}>
                  <div className={`${styles.emptyPage} d-flex justify-content-center align-item-center`}>
                    <span className={styles.noDataFound}>No data found</span>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <AccessGuard permission={PERMISSIONS.CHIME_CONFIGURATION_CREATE}>
        <div className={styles.addButton}>
          <button onClick={() => setAddDialogVisible(true)}>Add {list?.length ? "Another" : ""} Config</button>
        </div>
      </AccessGuard>
      {addDialogVisible ? <AddChimeConfig callback={createOrEditCallback} onCancelClick={() => setAddDialogVisible(false)} /> : null}
      {editDetails ? (
        <EditChimeConfig
          id={editDetails.customer_chime_config_id}
          initialValues={editDetails}
          callback={createOrEditCallback}
          onCancelClick={() => setEditDetails(null)}
        />
      ) : null}
    </>
  );
};

export default ManageFirmwareChimeConfiguration;
