import styles from "./styles.module.scss";

/**
 * Common component for displaying toggle switch
 * Uses base props of input element
 */
const Switch = ({ disabled, ...props }: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>) => {
  return <input type="checkbox" className={`${styles.switch} ${styles.toggle} ${disabled ? styles.disabled : ""}`} {...props} />;
};

export default Switch;
