import { AxiosResponse } from "axios";
import { http } from "../api/utils/http";
import { COMMAND_TYPE, InitSeqResponse } from "../interfaces/be.interfaces";
import { SerialOptions, SerialPort } from "../interfaces/serial";
import { getInitCommands } from "../api/config";
import {
  DEVICE_TIMEOUT,
  INIT_SEQ_DEVICE_TIMEOUT,
  INIT_SEQ_START_COMMAND,
  START_COMMAND,
  STATUS_COMMAND,
  STATUS_COMMAND_RESPONSE,
  STOP_COMMAND,
} from "./constants";
import { toast } from "react-toastify";
import isEqual from "lodash/isEqual";
import { CommunicationType } from "../interfaces/fe.interfaces";

/**
 * I2C Driver class to handle all I2C related operations
 * @class I2CDriver
 * @constructor
 * @public
 * @method connect - Connects to the device
 * @method disconnect - Disconnects from the device
 * @method runSequence - Runs sequence of commands
 */
export class I2CDriver {
  communicationType = CommunicationType.I2C;
  port: SerialPort | null = null;
  reader: ReadableStreamDefaultReader<any> | null = null;
  shouldCaptureResponse = false;
  deviceResponses = new Uint8Array([]);
  _promise: any = {
    resolve: null,
    reject: null,
  };
  deviceResponseTimeout = DEVICE_TIMEOUT;
  timeoutController: NodeJS.Timeout | null = null;
  shouldRunNext = this.createPromise();

  createPromise() {
    return new Promise((resolve, reject) => {
      this._promise.resolve = resolve;
      this._promise.reject = reject;
    });
  }

  connectOptions: SerialOptions = {
    baudRate: 1000000,
    dataBits: 8,
    parity: "none",
    stopBits: 1,
    flowControl: "none",
    bufferSize: 8 * 1024,

    // Prior to Chrome 86 these names were used.
    baudrate: 1000000,
    databits: 8,
    stopbits: 1,
    rtscts: "none",
  };

  constructor() {
    this.runSequence = this.runSequence.bind(this);
  }

  /**
   * This function writes data to the device
   * @param data - Array of numbers to be sent to the device
   */
  private async writeAndRead(data: number[]) {
    if (this.port && this.port?.writable && this.port?.readable) {
      const writer = this.port.writable.getWriter();
      const uintArr = new Uint8Array(data);
      if (data[0] !== STOP_COMMAND) {
        this.shouldRunNext = this.createPromise();
      }
      await writer.write(uintArr);
      writer.releaseLock();
    }
  }

  /**
   * This function reads data from the device
   * It runs a continuous loop to read data from the device and waits for the response
   * Once response is received, it resolves the promise so that next command is sent to the device
   */
  private async readDevice() {
    while (this.port && this.port.readable) {
      try {
        this.reader = this.port.readable.getReader();
        for (;;) {
          const response = await (async () => {
            return await this.reader?.read();
          })();

          if (response) {
            const { value, done } = response;

            if (value) {
              // It only waits for response if it is a status read command or data read command
              // For these commands we make shouldCaptureResponse true
              if (this.shouldCaptureResponse) {
                this.deviceResponses = new Uint8Array([...this.deviceResponses, ...value]);
              }
              this._promise.resolve();
            }
            if (done) {
              break;
            }
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        if (this.reader) {
          // Once finished readings, should release lock
          this.reader?.cancel();
          this.reader.releaseLock();
          this.reader = null;
        }
      }
    }
  }

  /**
   * This function checks if current command is status read command
   */
  private isCurrentCommandStatusRead(sequence: (string | number)[][], i: number, type: COMMAND_TYPE) {
    const START_COMMAND_ARR = [115, 81];
    const STATUS_COMMAND_ARR = [128];
    const STOP_COMMAND_ARR = [112];

    const startCommand = sequence?.[i - 2];
    const statusCommand = sequence?.[i - 1];
    const stopCommand = sequence?.[i];
    const nextCommand = sequence?.[i + 1];

    // For GET commands status read is second chunk (chunk is combination of 3 commands - start, command and stop)
    // So GET command's status read must have a following command
    // For SET command it is only 2 chunks and last one is status read
    return (
      isEqual(START_COMMAND_ARR, startCommand) &&
      isEqual(STATUS_COMMAND_ARR, statusCommand) &&
      isEqual(STOP_COMMAND_ARR, stopCommand) &&
      (type === COMMAND_TYPE.Set || (type === COMMAND_TYPE.Get && nextCommand !== undefined))
    );
  }

  private async requestPort() {
    try {
      if (navigator.serial) {
        return await navigator.serial.requestPort();
      } else {
        throw new Error("Web serial not supported");
      }
    } catch {
      return false;
    }
  }

  async connect() {
    try {
      if (this.port) {
        return true;
      }

      if (navigator.serial) {
        // Get list of previously paired devices
        const pairedPorts = await navigator.serial.getPorts();

        // Find paried port to connect to directly or ask user to pair a new device.
        const localPort = pairedPorts?.[0] || (await this.requestPort());

        if (!localPort) {
          return false;
        }

        await localPort.open(this.connectOptions);
        this.port = localPort;

        // setConnecting(true);
        // Get handshake sequence commands from API
        const response: AxiosResponse<InitSeqResponse> | boolean = await new Promise((resolve) => {
          http.makeGetRequest<InitSeqResponse>(getInitCommands, resolve, () => resolve(false));
        });

        // Start readings device response
        this.readDevice();

        // For init sequence using 1s timeout
        this.deviceResponseTimeout = INIT_SEQ_DEVICE_TIMEOUT;

        let res = new Uint8Array([]);
        if (typeof response !== "boolean") {
          res = await this.runSequence(response?.data?.data, false);
        }

        // When we’ve successfully connected to the device but when running the init sequence we’re unable to get any read from the device.
        // This could happen if we connected a wrong device or it is not responding correctly.
        if (res.length === 0) {
          this.port.forget();
          toast.error("Unable to recognize interface, please reconnect serial port.");
        }

        this.deviceResponseTimeout = DEVICE_TIMEOUT;
        return res.length > 0;
      }

      return false;
    } catch (e) {
      // When user picks a wrong device for which we can not open a connection or connection is already open with some other application.
      // This case will be hit when we’re unable to access the device.
      toast.error("Invalid port selected, please pick a valid serial port or reconnect serial");
      this.port?.forget();
      // setConnecting(false);
      return false;
    }
  }

  // Removes connection with the device and releases all locks
  async disconnect() {
    if (this.port) {
      try {
        if (this.reader) {
          this.reader.releaseLock();
        }
        await this.port.close();
        this.port = null;
        this.reader = null;
      } catch (error) {
        await this.reader?.cancel();
      }
    }
  }

  /**
   * This function runs sequence of commands
   * Sequence is an array of arrays, where each array is a chunk of commands, which are sent to the device one by one
   */
  async runSequence(sequence: (string | number)[][], captureResponse = true, type = COMMAND_TYPE.Get) {
    try {
      this.deviceResponses = new Uint8Array([]);

      for (let i = 0; i < sequence.length; i++) {
        // Clear timeout if next command is starting to execute
        if (this.timeoutController) {
          clearTimeout(this.timeoutController);
        }
        const data = sequence[i];
        // API will send array with timeout if some sleep is required before running next command
        if (data[0] === "delay") {
          await new Promise((resolve) => setTimeout(resolve, +data[1]));
          continue;
        }

        // Reject sequence running promise in case reading from device takes more than defined time
        this.timeoutController = setTimeout(() => {
          if (captureResponse) {
            toast.error("Device response timeout, please reconnect serial port.");
          }
          this._promise?.reject?.();
          this.timeoutController && clearTimeout(this.timeoutController);
        }, this.deviceResponseTimeout);

        const currentCommand = sequence?.[type === COMMAND_TYPE.Get ? i - 1 : i]?.[0];

        // If device response of status command is anything other than 0, then return response and stop sequence execution
        if (this.isCurrentCommandStatusRead(sequence, i, type) && this.deviceResponses[0] !== STATUS_COMMAND_RESPONSE && captureResponse) {
          this.timeoutController && clearTimeout(this.timeoutController);
          return this.deviceResponses;
        }

        // If the command is status read, data read or init sequence start command, then start capturing response
        if (
          (type === COMMAND_TYPE.Set && currentCommand === STATUS_COMMAND) ||
          currentCommand === START_COMMAND ||
          sequence[1][0] === INIT_SEQ_START_COMMAND
        ) {
          this.shouldCaptureResponse = true;
          this.deviceResponses = new Uint8Array([]);
        } else if (currentCommand === STOP_COMMAND) {
          this.shouldCaptureResponse = false;
        }

        await this.writeAndRead(data as any);
        if (captureResponse && data[0] !== "delay") await this.shouldRunNext;
      }
      !captureResponse && (await new Promise((resolve) => setTimeout(resolve, 50)));
      this.timeoutController && clearTimeout(this.timeoutController);
      return this.deviceResponses;
    } catch (error) {
      console.log(error);
      return new Uint8Array([]);
    }
  }
}
