import { Formik, FormikHelpers } from "formik";
import { useCallback, useState } from "react";
import { addModels, editModel, getModelSizeList, getModelUseCaseList } from "../../api/config";
import { http } from "../../api/utils/http";
import { ModelUseCase, ProductTypes, UpdateItemResponse } from "../../interfaces/be.interfaces";
import styles from "../AddOrEditProductForm/styles.module.scss";
import omit from "lodash/omit";
import { toast } from "react-toastify";
import { validateModel } from "../../utils/validationSchemas";
import { useFetch } from "../../hooks/useFetch";
import { useCustomerContext } from "../../hooks/useCustomerContext";
import { ModelPayload, SelectOption } from "../../interfaces/fe.interfaces";
import Spinner from "../Spinner";
import Form from "./AddOrEditModelForm";

interface AddOrEditModelProps {
  modelDetails: ModelPayload;
  onSuccess: () => void;
  onClose: () => void;
  productUseCaseId: number;
  productType: ProductTypes;
  productWillUpdateMcu: boolean;
  productWillConfigAmp: boolean;
  productWillConfigChime: boolean;
  vendorId: number;
}

const AddOrEditModel = ({
  modelDetails,
  onClose,
  onSuccess: onSuccessCallback,
  productUseCaseId,
  productType,
  productWillConfigAmp,
  productWillUpdateMcu,
  productWillConfigChime,
  vendorId,
}: AddOrEditModelProps) => {
  const { context, updateContext } = useCustomerContext();
  const [error, setError] = useState<string | null>();

  // fetch model size list to populate in select
  const [sizeList, setSizeList] = useState<SelectOption<string>[]>([]);
  const { loading: isSizeLoading } = useFetch<{ data: { size_arr: string[] } }>({
    url: getModelSizeList,
    runOnMount: true,
    onSuccess: (res) => {
      // prepare select options for dropdown
      setSizeList(res.data.data.size_arr.map((size) => ({ label: size, value: size })));
    },
  });

  // Fetch use case list from backend to populate in dropdown
  const { data, loading: isUseCaseLoading } = useFetch<ModelUseCase>({
    url: getModelUseCaseList,
    runOnMount: true,
  });

  /**
   * onSuccess callback for api call
   * @param data response data
   * @param setSubmitting formik setSubmitting function
   * @param oldModelId old model id
   * @param newModelId new model id
   */
  const onSuccess = useCallback(
    (data: UpdateItemResponse, setSubmitting: (val: boolean) => void, model?: ModelPayload) => {
      // if status is true, show success toast and close modal
      if (data?.status) {
        setError(null);
        onSuccessCallback();
        onClose();
        toast.success(data?.message);

        if (model && context.customerProductModelId === modelDetails.customer_product_model_id) {
          updateContext({
            ...context,
            modelDescription: model.model_description,
          });
        }
      } else {
        // if status is false, show error toast
        setError(data?.message);
      }

      setSubmitting(false);
    },
    [context, modelDetails]
  );

  // onError callback for api call
  const onError = (message: string | undefined, setSubmitting: (val: boolean) => void) => {
    setError(message);
    setSubmitting(false);
  };

  // Handler to update the model
  const updateModel = useCallback(
    (values: ModelPayload, { setSubmitting }: FormikHelpers<ModelPayload>) => {
      // prepare payload before making api call
      const payload = {
        // omit values which are not required in the payload
        ...omit(values, [
          "created_at",
          "updated_at",
          "deleted_at",
          "is_enable",
          "use_case_info",
          "amp_model",
          "mcu_model",
          "positions_required",
          "customer_product_model_amp_config",
          "customer_product_model_mcu_firmware",
          "customer_product_model_chime_config",
        ]),
        customer_product_model_id: modelDetails.customer_product_model_id,
        model_id: values.model_id,
        crossover: +values.crossover,
        size: values.size || null,
        mcu_firmware_id: +values.mcu_firmware_id || null,
        customer_amp_config_id: +values.customer_amp_config_id || null,
        left_speaker_chime_config_id: +values.left_speaker_chime_config_id || null,
        right_speaker_chime_config_id:
          values.positions_required && values.right_speaker_chime_config_id ? +values.right_speaker_chime_config_id : null,
      };

      // make put request to update model
      http.makePutRequest<UpdateItemResponse, { message: string }>(
        editModel,
        (res) => onSuccess(res.data, setSubmitting, values),
        (e) => onError(e?.response?.data.message, setSubmitting),
        payload
      );
    },
    [modelDetails, onSuccess]
  );

  // Handler to create new model
  const createNewModel = useCallback(
    (values: ModelPayload, { setSubmitting }: FormikHelpers<ModelPayload>) => {
      // prepare model item to be added
      const modelItem = omit(values, [
        "customer_product_id",
        "use_case_info",
        "amp_model",
        "mcu_model",
        "positions_required",
      ]);

      // prepare payload before making api call
      const payload = {
        customer_product_id: +modelDetails.customer_product_id,
        models_arr: [
          {
            ...modelItem,
            crossover: +modelItem.crossover,
            size: modelItem.size || null,
            mcu_firmware_id: +values.mcu_firmware_id || null,
            customer_amp_config_id: +values.customer_amp_config_id || null,
            left_speaker_chime_config_id: +values.left_speaker_chime_config_id || null,
            right_speaker_chime_config_id:
              values.positions_required && values.right_speaker_chime_config_id ? +values.right_speaker_chime_config_id : null,
          },
        ],
      };

      // make post request to add new model
      http.makePostRequest<UpdateItemResponse, { message: string }>(
        addModels,
        (res) => onSuccess(res.data, setSubmitting),
        (e) => onError(e?.response?.data.message, setSubmitting),
        payload
      );
    },
    [modelDetails]
  );

  return (
    <div className={styles.dialogContainer}>
      <div className={styles.dialog}>
        {isUseCaseLoading || isSizeLoading ? (
          <Spinner />
        ) : (
          <>
            {error ? <div className="errorAlert">{error}</div> : null}
            <Formik
              initialValues={modelDetails}
              onSubmit={modelDetails?.model_id ? updateModel : createNewModel}
              validationSchema={validateModel}>
              {(props) => (
                <Form
                  {...props}
                  onClose={onClose}
                  useCaseList={data}
                  productUseCaseId={productUseCaseId}
                  sizeList={sizeList}
                  productType={productType}
                  productWillConfigAmp={productWillConfigAmp}
                  productWillUpdateMcu={productWillUpdateMcu}
                  productWillConfigChime={productWillConfigChime}
                  vendorId={vendorId}
                />
              )}
            </Formik>
          </>
        )}
      </div>
    </div>
  );
};

export default AddOrEditModel;
