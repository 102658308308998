import { getAppVersion } from "../../api/config";
import { useFetch } from "../../hooks/useFetch";
import { AppInfoApiResponse } from "../../interfaces/be.interfaces";
import gitInfo from "../../../git-info.json";
import styles from "./styles.module.scss";

const About = () => {
  const { data } = useFetch<AppInfoApiResponse>({ url: getAppVersion, runOnMount: true });

  const server = data?.data?.server;
  const dwh = data?.data?.dwh;

  return (
    <div>
      <p>
        <span className={styles.label}>Client: </span> {gitInfo?.tag ? `${gitInfo?.tag} - ` : null} {gitInfo?.hash?.slice(1)}
      </p>
      <p>
        <span className={styles.label}>Server: </span> {server?.tag ? `${server?.tag} - ` : null} {server?.hash?.slice(1)}
      </p>
      <p>
        <span className={styles.label}>Database: </span> {dwh?.tag ? `${dwh?.tag} - ` : null} {dwh?.hash?.slice(1)}
      </p>
    </div>
  );
};

export default About;
