import { Dispatch, SetStateAction, useMemo } from "react";
import Modal from "../../components/Modal/Modal";
import updateStyles from "../UpdateCusSeq/styles.module.scss";
import { Formik } from "formik";
import { Sequence } from "../../hooks/useSequence";
import { setCommandsInputValidation } from "../../utils/validationSchemas";
import { GetSetCommandsInputsResponse } from "../../interfaces/be.interfaces";
import Select from "../../components/Select";

interface OverwriteModelProps {
  showOverwriteModel: boolean;
  setShowOverwriteModal: Dispatch<SetStateAction<boolean>>;
  sequence: Sequence[];
  callScanAPI: (values: any) => void;
  inputAvailableFor: string[];
  setCommandsInput: GetSetCommandsInputsResponse | null;
}

const OverwriteModel = ({
  showOverwriteModel,
  setShowOverwriteModal,
  sequence,
  callScanAPI,
  inputAvailableFor,
  setCommandsInput,
}: OverwriteModelProps) => {
  const validationSchema = useMemo(() => {
    return setCommandsInputValidation(inputAvailableFor, setCommandsInput?.data?.dynamic_values || {});
  }, [inputAvailableFor, setCommandsInput]);

  return (
    <Modal
      isModalOpen={showOverwriteModel}
      onModalClose={() => setShowOverwriteModal(false)}
      onModalOpen={() => setShowOverwriteModal(true)}>
      <div className={updateStyles.inputValuesModel}>
        <p className={updateStyles.heading}>Overwrite values</p>
        <Formik
          initialValues={sequence}
          onSubmit={(values) => {
            callScanAPI({ customSequence: values });
          }}
          validationSchema={validationSchema}>
          {({ setFieldValue, handleSubmit, values, errors, touched }) => (
            <form noValidate onSubmit={handleSubmit}>
              <div className={updateStyles.commandListContainer}>
                {sequence?.map((item, index) => {
                  const val = values?.[index];
                  const err = errors?.[index];
                  const touch = touched?.[index];

                  return (
                    <div key={item.id} className={updateStyles.commandItem}>
                      <p>{item.title}</p>
                      {inputAvailableFor?.includes(item.title) ? (
                        <>
                          {setCommandsInput?.data?.fixed_values?.[item.title] ? (
                            <div className={updateStyles.customInput}>
                              <Select
                                options={setCommandsInput?.data?.fixed_values?.[item.title] || []}
                                selectProps={{
                                  value: val?.set_value || "",
                                  onChange: (e) => {
                                    setFieldValue(`[${index}].set_value`, e.target.value);
                                  },
                                  disabled: !item.allowUpdate,
                                }}
                              />
                              {err?.set_value && touch?.set_value ? <div className={updateStyles.error}>{err?.set_value}</div> : null}
                            </div>
                          ) : (
                            <div className={updateStyles.customInput}>
                              <input
                                type="text"
                                value={val.set_value ?? ""}
                                placeholder={setCommandsInput?.data?.dynamic_values?.[item.title].prefix}
                                onChange={(e) => {
                                  setFieldValue(`[${index}].set_value`, e.target.value);
                                }}
                                className={updateStyles.dynamicInput}
                                disabled={!item.allowUpdate}
                              />
                              {err?.set_value && touch?.set_value ? <div className={updateStyles.error}>{err?.set_value}</div> : null}
                            </div>
                          )}
                        </>
                      ) : (
                        <p>-</p>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className={updateStyles.actionButtons}>
                <button type="submit">Submit</button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default OverwriteModel;
