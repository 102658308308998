import { StrictUnion } from "../../interfaces/fe.interfaces";
import styles from "./styles.module.scss";

interface CommonProps {
  /**
   * Title for the InfoBox card
   */
  title: string;
  /**
   * Override for className to customize styling
   */
  className?: string;
}

interface ImageTypeCard extends CommonProps {
  /**
   * Source of image to be displayed
   * Required to pass when value text is not passed
   */
  imgSrc: string;
}

interface ValueTypeCard extends CommonProps {
  /**
   * Value to be displayed
   * Required to pass when image source is not passed
   */
  value: string;
}

type Props = StrictUnion<ImageTypeCard | ValueTypeCard>;

const InfoBox = (props: Props) => {
  return (
    <div className={`d-flex ${styles.infoboxContainer} ${props?.className ?? ""}`}>
      <span>{props?.title}</span>
      {props?.imgSrc ? <img src={props?.imgSrc} alt="info" /> : <label>{props?.value}</label>}
    </div>
  );
};

export default InfoBox;
