import { DragEvent, useCallback, useRef, useState, MouseEvent } from "react";
import styles from "./styles.module.scss";
import { Icons } from "../../../assets/images";
import { toast } from "react-toastify";
import { http } from "../../api/utils/http";
import { BASE_URL, uploadMacAddresses } from "../../api/config";
import { UpdateItemResponse } from "../../interfaces/be.interfaces";
import { AxiosResponse } from "axios";

/**
 * @interface UploadMacDialogProps
 * @property {() => void} handleClose - function to close the dialog
 * @property {() => void} handleAfterSubmit - function to be called after successful submit
 */
interface UploadMacDialogProps {
  handleClose: () => void;
  handleAfterSubmit: () => void;
}

/**
 * This component is responsible for uploading mac addresses
 */
const UploadMacDialog = (props: UploadMacDialogProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  // validate that the file is a csv file
  // CSV file must contain wisa_mac_address header at top to be valid
  const handleValidateAndSelectFile = useCallback((file: File) => {
    if (file.type !== "text/csv") {
      // show error if the file is not a csv file
      toast.error("Please select a valid csv file");
      return;
    }

    setFile(file);
  }, []);

  /**
   * Handles drop event on the dropzone div
   * DropEvent has dataTransfer property which has files property which is an array of files, we can take first file and validate it
   */
  const onDrop = useCallback((e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    // check if the file is dropped and it is a valid csv file then set the file
    if (e.dataTransfer.files.length > 0) {
      handleValidateAndSelectFile(e.dataTransfer.files[0]);
      setIsDragging(false);
    }
  }, []);

  /**
   * Handles drag events on the dropzone div
   * On drag enter and leave changing UI of the dropzone div
   */
  const handleDrag = useCallback((e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(e.type === "dragenter" || e.type === "dragover");
  }, []);

  /**
   * Removes the selected file and allows user to select another file
   */
  const removeSelectedFile = useCallback((e: MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    setFile(null);
  }, []);

  /**
   * Calls the click event of the hidden input element to allow user to select a file from default file explorer
   */
  const handleOpenFileSelector = useCallback(() => {
    inputRef?.current?.click();
  }, [inputRef]);

  /**
   * On Change event for the hidden input element
   * This function is called when user selects a file from the default file explorer
   */
  const onFileChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      handleValidateAndSelectFile(e.target.files[0]);
      inputRef?.current?.value && (inputRef.current.value = "");
    }
  }, []);

  /**
   * Submit handler for the form
   */
  const handleSubmit = useCallback(() => {
    if (!file) return;

    // Prepare FormData to send to the server
    const formData = new FormData();
    formData.append("mac_addresses", file);

    // Success callback for the request
    const onSuccess = (res: AxiosResponse<UpdateItemResponse>) => {
      let toaster = toast.error;

      // If data is inserted successfully then show success toaster and close the dialog
      if (res.data.status) {
        props.handleClose();
        props.handleAfterSubmit();
        toaster = toast.success;
      }

      toaster(res.data.message);
    };

    // make requests to the server
    http.makePostRequestWithFormData<UpdateItemResponse, { message: string }>(
      uploadMacAddresses,
      onSuccess,
      (error) => {
        toast.error(error?.response?.data?.message || "Something went wrong");
      },
      formData
    );
  }, [file]);

  return (
    <div className={styles.dialogContainer}>
      <div className={styles.dialog}>
        <div
          className={`${styles.fileUpload} ${isDragging ? styles.dragging : ""} ${file ? styles.selected : ""}`}
          onDrop={onDrop}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onClick={handleOpenFileSelector}>
          {file ? (
            <div className={styles.selectedFile}>
              <Icons.TrashIcon onClick={removeSelectedFile} />
              {file.name}
            </div>
          ) : (
            <div>
              Drag and drop your file here or <span className={styles.fileUploadLink}>browse</span>
            </div>
          )}
          <input type="file" ref={inputRef} onChange={onFileChange} accept="text/csv" />
        </div>
        <div className={styles.csvInstructions}>
          <div className={styles.csvInstructionsTitle}>Note: </div>
          <ul>
            <li>CSV file must contain wisa_mac_address header at top to be valid</li>
            <li>
              Download{" "}
              <a href={`${BASE_URL}/Upload_MAC_Sample.csv`} target="_blank" rel="noreferrer">
                sample
              </a>{" "}
              file from here
            </li>
          </ul>
        </div>
        <div className="w-100 d-flex justify-content-around">
          <button className={styles.dialogBtn} type="button" onClick={handleSubmit} disabled={file === null}>
            Submit
          </button>
          <button className={styles.dialogBtn} onClick={props.handleClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadMacDialog;
