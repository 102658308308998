import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import { AxiosError, AxiosResponse } from "axios";
import ManageFirmwareWisaFirmwareUploadForm from "./ManageFirmwareWisaFirmwareUploadForm";
import Modal from "../../../components/Modal/Modal";
import styles from "./styles.module.scss";
import { changeCurrentCustomerVersion, getWisaFirmwareVersions, getWisaProducts } from "../../../api/config";
import {
  GetWisaFirmwareVersionsResponse,
  WisaFirmwareVersionModel,
  GetWisaProductsResponse,
  UpdateItemResponse,
} from "../../../interfaces/be.interfaces";
import { RunOptions, useFetch, UseFetchProps } from "../../../hooks/useFetch";
import { toast } from "react-toastify";
import Switch from "../../../components/Switch";
import { http } from "../../../api/utils/http";
import { Tooltip } from "react-tooltip";
import { Icons } from "../../../../assets/images";
import ManageFirmwareWisaFirmwareEditForm from "./ManageFirmwareWisaFirmwareEditForm";
import { ManageFirmwareWisaFirmwareFormInitValuesInterface } from "../../../interfaces/fe.interfaces";
import { usePermission } from "../../../hooks/usePermission";
import { PERMISSIONS } from "../../../utils/constants";
import AccessGuard from "../../../components/Guards/AccessGuard";

const manageFirmwareWisaFirmwareTableHeaders = [
  { title: "WiSA Product" },
  { title: "Version" },
  { title: "File Name" },
  { title: "Current Customer Version", info: "Only one version can be enabled at a time for each WiSA product" },
  { title: "Description" },
  { title: "Release Notes Link" },
  { title: "" },
];

interface OnErrorResponse {
  message: string;
}

const ManageFirmwareWisaFirmware = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [wisaProductFirmwareVersions, setWisaProductFirmwareVersions] = useState<WisaFirmwareVersionModel[]>([]);
  const [editFormInitialValues, setEditFormInitialValues] = useState<ManageFirmwareWisaFirmwareFormInitValuesInterface | null>(null);

  const onGetWisaFirmwareVersionsSuccess = useCallback(
    ({ data: response }: AxiosResponse<GetWisaFirmwareVersionsResponse>) => {
      const haveVersions = response.data.list.length > 0;
      if (!haveVersions) return;
      setWisaProductFirmwareVersions(response.data.list);
    },
    [wisaProductFirmwareVersions]
  );

  const onGetWisaFirmwareVersionsFailure = useCallback((error: AxiosError<OnErrorResponse, any> | undefined) => {
    toast.error("WiSA firmware versions failed to load. Try reloading the page.");
    console.log(`[GetWisaFirmwareVersions]: ${error?.message}`);
  }, []);

  const onGetWisaProductsFailure = useCallback((error: AxiosError<OnErrorResponse, any> | undefined) => {
    toast.error("WiSA products failed to load. Try reloading the page.");
    console.log(`[GetWisaProductsFailure]: ${error?.message}`);
  }, []);

  const useFetchGetWisaFirmwareVersionListProps: UseFetchProps<GetWisaFirmwareVersionsResponse, OnErrorResponse> = useMemo(
    () => ({
      url: getWisaFirmwareVersions,
      runOnMount: true,
      onSuccess: onGetWisaFirmwareVersionsSuccess,
      onError: onGetWisaFirmwareVersionsFailure,
    }),
    []
  );
  const { run: getWisaFirmwareVersionList } = useFetch(useFetchGetWisaFirmwareVersionListProps);

  const useFetchGetWisaProductsProps: UseFetchProps<GetWisaProductsResponse, OnErrorResponse> = useMemo(
    () => ({
      url: getWisaProducts,
      runOnMount: true,
      onError: onGetWisaProductsFailure,
    }),
    []
  );
  const { data: wisaProductsData } = useFetch(useFetchGetWisaProductsProps);

  const onModalClose = useCallback(() => setIsModalOpen(false), [setIsModalOpen]);
  const onEditModalClose = useCallback(() => setEditFormInitialValues(null), [setEditFormInitialValues]);

  const onUploadFirmwareButtonClick = useCallback(() => setIsModalOpen(true), [setIsModalOpen]);

  return (
    <div>
      <Tooltip id="info-tooltip" />
      <div className={styles.tableResponsive}>
        <table className={styles.table}>
          <thead>
            <tr>
              {manageFirmwareWisaFirmwareTableHeaders.map((header, index) => {
                return (
                  <th key={`product-firmware-th-${index}`}>
                    {header.title}
                    {header.info && (
                      <span
                        data-tooltip-id="info-tooltip"
                        data-tooltip-content={header.info}
                        data-tooltip-place="top"
                        className={styles.info}>
                        &nbsp;&#9432;
                      </span>
                    )}
                  </th>
                );
              })}
            </tr>
          </thead>
          {
            <ManageFirmwareCustomerProductWisaFirmwareVersionList
              productsFirmwareVersions={wisaProductFirmwareVersions}
              refetch={getWisaFirmwareVersionList}
              setEditFormInitialValues={setEditFormInitialValues}
            />
          }
        </table>
      </div>
      <AccessGuard permission={PERMISSIONS.FIRMWARE_UPLOAD}>
        <div className={styles.manageFirmwareWisaFirmwareButtonWrapper}>
          <button onClick={onUploadFirmwareButtonClick}>Upload .bin File</button>
        </div>
      </AccessGuard>
      <Modal isModalOpen={isModalOpen} onModalClose={onModalClose}>
        <ManageFirmwareWisaFirmwareUploadForm
          wisaProducts={wisaProductsData?.data.list || []}
          onUploadFirmware={onModalClose}
          onFormSubmitSuccessCallback={getWisaFirmwareVersionList}
        />
      </Modal>
      <Modal isModalOpen={!!editFormInitialValues} onModalClose={onEditModalClose}>
        {editFormInitialValues && editFormInitialValues.version_id && (
          <ManageFirmwareWisaFirmwareEditForm
            wisaProducts={wisaProductsData?.data.list || []}
            onUploadFirmware={onEditModalClose}
            onFormSubmitSuccessCallback={getWisaFirmwareVersionList}
            initialValues={editFormInitialValues}
            versionId={editFormInitialValues.version_id}
          />
        )}
      </Modal>
    </div>
  );
};

export interface ManageFirmwareWisaFirmwareVersionListProps {
  productsFirmwareVersions: WisaFirmwareVersionModel[];
  refetch: (options?: RunOptions) => void;
  setEditFormInitialValues: Dispatch<SetStateAction<ManageFirmwareWisaFirmwareFormInitValuesInterface | null>>;
}

export const ManageFirmwareCustomerProductWisaFirmwareVersionList = ({
  productsFirmwareVersions: customerProductsFirmwareVersions,
  refetch,
  setEditFormInitialValues,
}: ManageFirmwareWisaFirmwareVersionListProps) => {
  return (
    <tbody>
      {!customerProductsFirmwareVersions.length && (
        <tr>
          {manageFirmwareWisaFirmwareTableHeaders.map((_header, index) => (
            <td key={`placeholder-${index}`}>
              <p>-</p>
            </td>
          ))}
        </tr>
      )}
      {customerProductsFirmwareVersions.map((customerProductFirmwareVersion, index) => {
        return (
          <ManageFirmwareCustomerProductFirmwareVersion
            key={`product-firmware-${index}`}
            customerProductFirmwareVersion={customerProductFirmwareVersion}
            refetch={refetch}
            setEditFormInitialValues={setEditFormInitialValues}
          />
        );
      })}
    </tbody>
  );
};

interface ManageFirmwareCustomerProductFirmwareVersionProps {
  customerProductFirmwareVersion: WisaFirmwareVersionModel;
  refetch: (options?: RunOptions) => void;
  setEditFormInitialValues: Dispatch<SetStateAction<ManageFirmwareWisaFirmwareFormInitValuesInterface | null>>;
}

export const ManageFirmwareCustomerProductFirmwareVersion = ({
  customerProductFirmwareVersion,
  refetch,
  setEditFormInitialValues,
}: ManageFirmwareCustomerProductFirmwareVersionProps) => {
  const { version, wisa_product, file_name, current_customer_version, description, memory_location, version_id } =
    customerProductFirmwareVersion;
  const { checkPermission } = usePermission();

  // On change handler for the switch of changing current customer version
  const handleSwitchChange = useCallback(() => {
    // If the version is already a current customer version then do nothing as there must be atleast one current customer version
    if (current_customer_version) return;

    // On success callback
    const onSuccess = (res: AxiosResponse<UpdateItemResponse>) => {
      // if status is true then show success toast and refetch the data
      if (res.data.status) {
        toast.success(res.data.message);
        refetch && refetch();
      }
    };

    // Make request to change current customer version
    http.makeGetRequest<UpdateItemResponse, { message: string }>(`${changeCurrentCustomerVersion}/${version_id}`, onSuccess, (error) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    });
  }, [version, wisa_product, current_customer_version]);

  const handleClickEditButton = useCallback(() => {
    setEditFormInitialValues?.({
      version,
      wisa_product,
      firmware_bin: null,
      current_customer_version: Number(current_customer_version),
      description,
      memory_location,
      file_name,
      version_id,
    });
  }, [description, file_name, current_customer_version, wisa_product, version]);

  return (
    <tr>
      <td>{wisa_product}</td>
      <td>{version}</td>
      <td>{file_name}</td>
      <td>
        <Switch
          checked={current_customer_version}
          onChange={handleSwitchChange}
          disabled={current_customer_version || !checkPermission(PERMISSIONS.UPDATE_FIRMWARE_VERSION)}
        />
      </td>
      <td>{description}</td>
      <td></td>
      <td>
        <Icons.EditIcon onClick={handleClickEditButton} />
      </td>
    </tr>
  );
};

export default ManageFirmwareWisaFirmware;
