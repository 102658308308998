import { useState, useCallback } from "react";
import styles from "./styles.module.scss";
import { Formik } from "formik";
import { ExpressTxConfigurationsResponseModel, ExpressDecodeOptionsModel } from "../../../interfaces/be.interfaces";
import { ExpressTxConfigurationsFormModel, ExpressTxConfigurationsModel } from "../../../interfaces/fe.interfaces";
import { ManageProductExpressTxConfigurationsActualProduct } from "./ManageProductExpressTxConfigurationsActualProduct";
import { ManageProductExpressTxConfigurationsCustomerHardware } from "./ManageProductExpressTxConfigurationsCustomerHardware";
import { ManageProductExpressTxConfigurationsYourProduct } from "./ManageProductExpressTxConfigurationsYourProduct";
import { validateManageProductExpressTxConfigurations } from "../../../utils/validationSchemas";
import { http } from "../../../api/utils/http";
import { expressTxConfigurationsRoute } from "../../../api/config";
import { AxiosError, AxiosResponse } from "axios";

const haveSelectedCheckboxValue = (
  formValues: ExpressTxConfigurationsFormModel,
  fieldsetName: ExpressTxConfigurationsModel,
  checkboxValue: string
) => {
  return formValues[fieldsetName]?.includes(checkboxValue) ?? false;
};

export const expressTxConfigurationsFormModelInIt = {
  dev_type: "",
  local_speakers: [],
  operating_system: "",
  os_versions: [],
  os_other_text: "",
  decode_options: [],
  audio_hal: "",
  audio_hal_other_text: "",
  binder: false,
  rx_configurations: [],
  sync_requirements: "",
  tsf_available: false,
};

const ManageProductExpressTxConfigurations = () => {
  const [expressTxConfigurationsForm] = useState<ExpressTxConfigurationsFormModel>(expressTxConfigurationsFormModelInIt);

  const [actualProduct, setActualProduct] = useState<ExpressDecodeOptionsModel | null>(null);

  const [error, setError] = useState<string | null>(null);

  const onSuccess = useCallback(
    ({ data: response }: AxiosResponse<ExpressTxConfigurationsResponseModel>) => {
      const { actual_product } = response.data;
      setActualProduct(actual_product);
    },
    [setActualProduct, actualProduct]
  );

  const onError = useCallback(
    (
      error:
        | AxiosError<
            {
              message: string;
            },
            any
          >
        | undefined
    ) => {
      setError(error?.response?.data?.message ?? null);
    },
    [setError]
  );

  const onSubmit = useCallback(
    (values: ExpressTxConfigurationsFormModel) => {
      const {
        dev_type,
        local_speakers,
        operating_system,
        os_versions,
        os_other_text,
        decode_options,
        audio_hal,
        audio_hal_other_text,
        binder,
        rx_configurations,
        sync_requirements,
        tsf_available,
      } = values;

      const payload = {
        dev_type,
        local_speakers,
        operating_system,
        os_versions,
        os_other_text,
        decode_options,
        audio_hal,
        audio_hal_other_text,
        binder,
        rx_configurations,
        sync_requirements,
        tsf_available,
      };

      http.makePostRequest<ExpressTxConfigurationsResponseModel, { message: string }>(
        expressTxConfigurationsRoute,
        onSuccess,
        onError,
        payload
      );
    },
    [onSuccess, onError, expressTxConfigurationsRoute]
  );

  return (
    <div>
      {error && <div className="errorAlert">{error}</div>}

      <Formik
        onSubmit={onSubmit}
        initialValues={{ ...expressTxConfigurationsForm }}
        validationSchema={validateManageProductExpressTxConfigurations}>
        {(props) => (
          <form noValidate onSubmit={props.handleSubmit}>
            <div className="d-flex align-item-center justify-content-between">
              <h2>Express TX Configurations</h2>
              <button type="submit">Submit</button>
            </div>
            <div className={styles.manageProductExpressTxConfigurationsContainer}>
              <ManageProductExpressTxConfigurationsCustomerHardware {...props} />
              <ManageProductExpressTxConfigurationsYourProduct {...props} haveSelectedCheckboxValue={haveSelectedCheckboxValue} />
              {actualProduct && <ManageProductExpressTxConfigurationsActualProduct actualProduct={actualProduct} />}
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export { ManageProductExpressTxConfigurations };
