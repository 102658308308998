import { Formik, FormikHelpers } from "formik";
import styles from "./styles.module.scss";
import Form from "./Form";
import { validateAddOrEditAmpConfig } from "../../utils/validationSchemas";
import { AmpConfigInitValue } from "../../interfaces/fe.interfaces";
import { http } from "../../api/utils/http";
import { AmpConfiguration, UpdateItemResponse } from "../../interfaces/be.interfaces";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { editAmpConfig } from "../../api/config";

interface EditAmpConfigDialogProps {
  callback: () => void | Promise<void>;
  onCancelClick: () => void | Promise<void>;
  initialValues: AmpConfigInitValue;
  id: number;
}

/**
 * This component is responsible for edit amplifier configuration
 */
const EditAmpConfigDialog = (props: EditAmpConfigDialogProps) => {
  const handleEditAmpConfig = (values: AmpConfigInitValue, { setSubmitting }: FormikHelpers<AmpConfigInitValue>): void => {
    const formData = new FormData();

    formData.append("config_description", values.config_description);
    formData.append("customer_amp_config_id", String(props.id));
    formData.append("vendor_id", values.vendor_id);
    formData.append("config_version", values.config_version);
    formData.append("amp_model", values.amp_model);

    values.amp_config_bin && formData.append("amp_config_bin", values.amp_config_bin);

    // Success callback for /edit-amp-config API
    const onSuccess = ({ data: response }: AxiosResponse<UpdateItemResponse<AmpConfiguration>>) => {
      setSubmitting(false);

      if (response?.status) {
        toast.success(response?.message);

        // Close the dialog and call parent callback to refresh the table
        props.callback();
        props.onCancelClick();
      } else {
        toast.error(response?.message);
      }
    };

    // Calls /edit-amp-config API
    http.makePostRequestWithFormData<UpdateItemResponse<AmpConfiguration>, { message: string }>(
      editAmpConfig,
      onSuccess,
      (error) => {
        setSubmitting(false);
        toast.error(error?.response?.data?.message);
      },
      formData
    );
  };

  return (
    <div className={styles.dialogContainer}>
      <Formik
        enableReinitialize={true}
        initialValues={props?.initialValues}
        onSubmit={handleEditAmpConfig}
        onReset={props.onCancelClick}
        validationSchema={validateAddOrEditAmpConfig}>
        {(props) => <Form {...props} enableReinitialize={true} />}
      </Formik>
    </div>
  );
};

export default EditAmpConfigDialog;
