import React, { useContext, useMemo } from "react";
import { CUSTOMER_MODULES, PERMISSIONS } from "../../utils/constants";
import { usePermission } from "../../hooks/usePermission";
import { AppContext } from "../../context/AppContext";

interface AccessGuardProps {
  children: React.ReactNode;
  permission?: PERMISSIONS | PERMISSIONS[];
  modules?: CUSTOMER_MODULES | CUSTOMER_MODULES[];
  allPermissionRequired?: boolean;
}

/**
 * This component is used to show/hide components based on user permissions
 * It takes either a single permission or an array of permissions to check against the user's role
 * If no permission is passed, it will check if the user has OEM access
 * Check module access as well permission
 */
const AccessGuard = ({ children, permission, modules, allPermissionRequired = false }: AccessGuardProps) => {
  const appContext = useContext(AppContext);
  const { checkPermission, permissions: role, checkModuleAccess, modules: customer_modules } = usePermission();

  // Check if the user has the required permission(s)
  const showChildren = useMemo(() => {
    let hasModuleAccess = true;
    // If customer module has passed in this guard then that will check that User's customer has required module access or not
    if (modules) {
      // When modules is an array and allPermissionRequired is true,
      // check if the user's customer has all the module access
      // Otherwise, check if user's customer has any of them
      if (Array.isArray(modules)) {
        const func = (item: CUSTOMER_MODULES) => checkModuleAccess(item);
        hasModuleAccess = allPermissionRequired ? modules.every(func) : modules.some(func);
      } else {
        hasModuleAccess = checkModuleAccess(modules);
      }
    }

    // Just check if the user has OEM access
    let hasPermissionAccess = !!appContext?.userHasOEMAccess;
    if (permission) {
      // When permission is an array and allPermissionRequired is true, check if the user has all the permissions
      // Otherwise, check if the user has any of the permissions
      if (Array.isArray(permission)) {
        const func = (item: PERMISSIONS) => checkPermission(item);
        hasPermissionAccess = allPermissionRequired ? permission.every(func) : permission.some(func);
      } else {
        hasPermissionAccess = checkPermission(permission);
      }
    }

    return hasPermissionAccess && hasModuleAccess;
  }, [role, permission, allPermissionRequired, appContext, checkPermission, checkModuleAccess, modules, customer_modules]);

  // If the user doesn't have the required permission(s), return null
  if (!showChildren) return null;

  return <>{children}</>;
};

export default AccessGuard;
