import get from "lodash/get";
import { ModelConfigDropdownLists } from "../interfaces/be.interfaces";
import { CommandTypes, CustomSequenceDetails, SelectOption } from "../interfaces/fe.interfaces";
import { ALPHA_NUM_DASH_UNDERSCORE_REGEX } from "./constants";

interface Path {
  titlePath: string;
  idPath: string;
  moveOnPath?: string;
  passingValuesPath?: string;
  type: CommandTypes;
  minValuePath?: string;
  maxValuePath?: string;
}

/**
 * Recursively finds values according to path provided in parseCustomSequence function
 * Path is the key received from backend
 * @param obj - whole object received from backend
 * @param paths - all the paths using which we will find value
 */
function findValueByPath(obj: Record<string, any>, paths: Path[]): any {
  for (let i = 0; i < paths.length; i++) {
    const currentPath = paths[i];

    const title = get(obj, currentPath.titlePath);
    const id = get(obj, currentPath.idPath);
    const passingValues = currentPath.passingValuesPath && get(obj, currentPath.passingValuesPath);
    const moveOn = currentPath.moveOnPath && get(obj, currentPath.moveOnPath);
    const minValue = currentPath.minValuePath && get(obj, currentPath.minValuePath);
    const maxValue = currentPath.maxValuePath && get(obj, currentPath.maxValuePath);

    if (!title || !id) {
      return findValueByPath(obj, paths.slice(i + 1, paths.length));
    }

    return {
      title,
      id,
      type: currentPath?.type,
      move_on: moveOn,
      passingValues,
      min_value: minValue,
      max_value: maxValue,
    };
  }
}

/**
 * Parses custom sequence backend response to a custom JSON that we use on frontend side
 * @param arr - Array received from backend side
 * @param initialObj - Default values for object (if any)
 * @param rest - rest params contains mapping for object keys received from backend and object keys used on frontend
 */
export function parseCustomSequence(arr: Record<string, any>[], initialObj: Record<string, any>, ...rest: Path[]): any[] {
  const parsedArr: CustomSequenceDetails[] = [];

  arr.forEach((item) => {
    const foundItem = findValueByPath(item, rest);

    const obj = {
      id: foundItem?.id,
      title: foundItem?.title,
      sequences_id: foundItem?.id,
      sequence_type: foundItem?.type,
      result_type: foundItem?.type === CommandTypes.CUSTOM_COMMANDS ? item?.wisa_cust_commands?.result_type : undefined,
      move_on: foundItem?.move_on,
      set_value: item?.set_value,
      allow_update: item?.allow_update,
      passing_values: foundItem?.passingValues,
      min_value: foundItem?.min_value,
      max_value: foundItem?.max_value,
      ...initialObj,
    };

    if (obj) {
      parsedArr.push(obj);
    }
  });

  return parsedArr;
}

/**
 * This function is used to parse string to number
 * It parses wrong number format to correct format like ".5" to "0.5", "5." to "5"
 * @param value - string value that needs to be parsed to number
 * @returns - parsed number
 */
export const parseStringNumber = (value: string) => {
  // If string only has dot, returning 0
  if (value === ".") {
    value = "0";
  }
  // If string has dot at the end, removing it
  else if (value[value.length - 1] === ".") {
    value = value.slice(0, -1);
  }
  // If string has dot at the beginning, adding 0 before it
  else if (value[0] === ".") {
    value = `0${value}`;
  }

  const parsedValue = parseFloat(value);
  return Number.isNaN(parsedValue) ? 0 : parsedValue;
};

/**
 * Takes in array of objects and returns array of objects with all values parsed to number where key is in intKeys array
 * @param arr - array of objects with values that need to be parsed to number
 * @param intKeys - array of keys that need to be parsed to number
 * @returns - array of objects with values parsed to number
 */
export const parseValueToNumber = (arr: any[] | null, intKeys: string[]): any => {
  return arr?.map((item) => {
    intKeys.forEach((key) => {
      item[key] = parseStringNumber(item[key]);
    });

    return item;
  });
};

/**
 * Checks if all values in array of objects are not empty
 * Use this for validating form data before sending it to backend
 * @param arr - array of objects
 * @returns - true if all values are not empty, false otherwise
 */
export const checkIfAllKeysAreNotEmpty = <T extends object>(arr: T[] | null) => {
  return arr ? arr.every((obj) => Object.values(obj).every((val) => val !== null && val !== "")) : false;
};

/**
 * Validates if mac address is valid
 * @param mac - mac address to be validated
 * @returns - true if mac address is valid, false otherwise
 */
export const isMacValid = (mac: string) => {
  const macRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
  return macRegex.test(mac);
};

/**
 * Use this function to handle input change for number input fields
 * It will only allow numbers in decimal format to be entered in input field
 * @param e - event from input field
 * @param setFunc - function that sets the value of the field
 */
export const handleNumberInputChange = (e: React.ChangeEvent<HTMLInputElement>, setFunc: (fieldName: string, value: string) => void) => {
  const value = e.target.value;

  // If value is empty or only contains numbers, setting value
  if (/^[0-9]+$/.test(value) || value === "") {
    setFunc(e.target.name, value);
  }
};

/**
 * Use this function to handle input change for alphanumeric input fields
 * It will only allow alphanumeric to be entered in input field
 * @param e - event from input field
 * @param setFunc - function that sets the value of the field
 */
export const handleAlphaNumInputChange = (e: React.ChangeEvent<HTMLInputElement>, setFunc: (fieldName: string, value: string) => void) => {
  const value = e.target.value;

  // If value is empty or only contains numbers, setting value
  if (ALPHA_NUM_DASH_UNDERSCORE_REGEX.test(value) || value === "") {
    setFunc(e.target.name, value);
  }
};

/**
 * Use this function to format any valid string as mac address
 * @param mac - mac address to be formatted
 * @returns formatted mac address in format 00:00:00:00:00:00
 */
export const formatStrToMac = (mac: string) => {
  return (
    mac
      .toLowerCase()
      .replace(/[^\d|a-f]/g, "")
      .match(/.{1,2}/g) || []
  ).join(":");
};

/**
 * Use this to put blocking delay in async functions
 * @param delay - delay in milliseconds
 * @returns - promise that resolves after delay
 */
export const delay = async (delay: number) => {
  return new Promise((resolve) => setTimeout(resolve, delay));
};

/**
 * Use this function to format response from device
 * @param response - response from device in Uint8Array format
 * @param prependValue - value to be prepended to response
 * @returns - response in string format
 */
export const formatDeviceResponse = (response: Uint8Array, prependValue = "") => {
  return (
    prependValue +
    Array.from(response)
      .map((i) => i.toString(16))
      .join("")
  );
};

/**
 * Use this function for get desire array for select model configuration
 * @param lists Object of amp config, mcu firmware and chime config
 * @param vendorId Vendor id for filter and show valid list
 * @returns object of list amp config, mcu firmware and chime config selection
 */
export const destructModelConfigList = (lists: ModelConfigDropdownLists, vendorId: number) => {
  // This function will design for selection option
  const getDropdown = <T extends { vendor_id: number }>(list: T[], valKey: keyof T, placeHolder: string) => {
    const dropdownList = list.reduce((pre: SelectOption<string | null>[], curr) => {
      // Check the provided key should be different and product vendor_id and amp_config vendor_id should be same
      if (curr.vendor_id === vendorId && !pre.find((item) => item.value?.toLowerCase() === (curr[valKey] as string).toLowerCase())) {
        // Pushing the valid label and value for selection
        pre.push({
          label: curr[valKey] as string,
          value: curr[valKey] as string,
        });
      }

      return pre;
    }, []);

    dropdownList.unshift({ label: placeHolder, value: "" });

    return dropdownList;
  };

  // Preparing amp config list
  const amp_configs = getDropdown(lists?.amp_configurations, "amp_model", "Select Amp Model");

  // Preparing mcu firmware list
  const mcu_firmware_versions = getDropdown(lists?.mcu_firmware_versions, "mcu_model", "Select MCU Firmware");

  const chime_configurations = lists?.chime_configurations.reduce((pre: SelectOption<string | null>[], curr) => {
    // Check the provided key should be different and product vendor_id and amp_config vendor_id should be same
    if (curr.vendor_id === vendorId) {
      // Pushing the valid label and value for selection
      pre.push({
        label: `${curr.chime_audio} - ${curr.chime_version}`,
        value: String(curr.customer_chime_config_id),
      });
    }

    return pre;
  }, []);

  chime_configurations.unshift({ label: "Select Chime Version", value: "" });

  return { amp_configs, mcu_firmware_versions, chime_configurations };
};

export const findSignedHex2sComplement = (value: number) =>
  parseInt(
    value
      .toString(2)
      .split("")
      .map((item) => (item === "1" ? "0" : "1"))
      .join(""),
    2
  ) + 1;
