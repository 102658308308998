import styles from "./styles.module.scss";
import { ChimeConfigInitValue } from "../../interfaces/fe.interfaces";
import { Formik, FormikHelpers } from "formik";
import { AxiosResponse } from "axios";
import { ChimeConfiguration, UpdateItemResponse } from "../../interfaces/be.interfaces";
import { http } from "../../api/utils/http";
import { toast } from "react-toastify";
import { editChimeConfig } from "../../api/config";
import { validateAddOrEditChimeConfig } from "../../utils/validationSchemas";
import Form from "./Form";

interface EditChimeConfigDialogProps {
  callback: () => void | Promise<void>;
  onCancelClick: () => void | Promise<void>;
  initialValues: ChimeConfigInitValue;
  id: number;
}

const EditChimeConfig = (props: EditChimeConfigDialogProps) => {
  const onSubmit = (values: ChimeConfigInitValue, { setSubmitting }: FormikHelpers<ChimeConfigInitValue>): void => {
    const formData = new FormData();

    formData.append("chime_description", values.chime_description);
    formData.append("customer_chime_config_id", String(props.id));
    formData.append("vendor_id", values.vendor_id);
    formData.append("chime_version", values.chime_version);
    formData.append("chime_audio", values.chime_audio);

    values.chime_config_bin && formData.append("chime_config_bin", values.chime_config_bin);

    // Success callback for /edit-chime-config API
    const onSuccess = ({ data: response }: AxiosResponse<UpdateItemResponse<ChimeConfiguration>>) => {
      setSubmitting(false);

      if (response?.status) {
        toast.success(response?.message);

        // Close the dialog and call parent callback to refresh the table
        props.callback();
        props.onCancelClick();
      } else {
        toast.error(response?.message);
      }
    };

    // Calls /edit-chime-config API
    http.makePostRequestWithFormData<UpdateItemResponse<ChimeConfiguration>, { message: string }>(
      editChimeConfig,
      onSuccess,
      (error) => {
        setSubmitting(false);
        toast.error(error?.response?.data?.message);
      },
      formData
    );
  };
  return (
    <div className={styles.dialogContainer}>
      <Formik
        enableReinitialize={true}
        initialValues={props?.initialValues}
        onSubmit={onSubmit}
        onReset={props.onCancelClick}
        validationSchema={validateAddOrEditChimeConfig}>
        {(props) => <Form {...props} enableReinitialize={true} />}
      </Formik>
    </div>
  );
};

export default EditChimeConfig;
