import { Formik, FormikHelpers } from "formik";
import styles from "./styles.module.scss";
import Form from "./Form";
import { validateAddOrEditUser } from "../../utils/validationSchemas";
import { UserFormInitValues, UserPayload } from "../../interfaces/fe.interfaces";
import { http } from "../../api/utils/http";
import { PostOrPutUserResponse } from "../../interfaces/be.interfaces";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { editUser } from "../../api/config";

interface EditUserDialogProps {
  callback: () => void | Promise<void>;
  onCancelClick: () => void | Promise<void>;
  initialValues: UserFormInitValues;
  id: string;
}

const EditUserDialog = (props: EditUserDialogProps) => {
  const handleEditUser = (values: UserFormInitValues, { setSubmitting }: FormikHelpers<UserFormInitValues>): void => {
    const payload: UserPayload = {
      vendor_id: Number(values.vendor_id),
      email: values.email,
      name: values.name,
      role_id: values.role_id,
      user_id: values.user_id,
    };

    // If we are changing password then password will be send to the payload
    if (values?.password) payload.password = values.password;

    // Success callback for /edit-user API
    const onSuccess = ({ data: response }: AxiosResponse<PostOrPutUserResponse>) => {
      setSubmitting(false);
      if (response?.status) {
        toast.success(response?.message);

        // Close the dialog and call parent callback to refresh the table
        props.callback();
        props.onCancelClick();
      } else {
        toast.error(response?.message);
      }
    };

    // Calls /edit-user API
    http.makePutRequest<PostOrPutUserResponse, { message: string }>(
      editUser,
      onSuccess,
      (error) => {
        setSubmitting(false);
        toast.error(error?.response?.data?.message);
      },
      payload
    );
  };

  return (
    <>
      <div className={styles.dialogContainer}>
        <Formik
          enableReinitialize={true}
          initialValues={props?.initialValues}
          onSubmit={handleEditUser}
          onReset={props.onCancelClick}
          validationSchema={validateAddOrEditUser}>
          {(props) => <Form {...props} enableReinitialize={true} isEditForm={true} />}
        </Formik>
      </div>
    </>
  );
};

export default EditUserDialog;
