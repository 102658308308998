import { FormikProps } from "formik";
import Spinner from "../Spinner";
import styles from "./styles.module.scss";
import Select from "../Select";
import { getCustomerInfo } from "../../api/config";
import { ChangeEvent, useState } from "react";
import { CustomerFormInitValues } from "../../interfaces/fe.interfaces";
import { CustomerInfoResponse } from "../../interfaces/be.interfaces";
import { useFetch } from "../../hooks/useFetch";
import { handleAlphaNumInputChange } from "../../utils/helpers";

interface CustomerProps {
  label: string;
  value: string;
}

/**
 * Common form component for AddCustomer and EditCustomer
 * Takes in all the props from Formik component
 */
const Form = ({
  values,
  touched,
  setFieldTouched,
  errors,
  handleSubmit,
  handleReset,
  setFieldValue,
  isSubmitting,
  enableReinitialize,
}: FormikProps<CustomerFormInitValues>) => {
  const [customers, setCustomers] = useState<CustomerProps[]>([]);

  // Get list of customer info options to display in dropdown selection
  const { loading: customerInfoLoading, error: customerError } = useFetch<CustomerInfoResponse>({
    url: getCustomerInfo,
    runOnMount: true,
    onSuccess: (response) => {
      if (response?.status) {
        const dropdownItemArr: CustomerProps[] = [];

        // Converting the response to the format required by the dropdown component
        response?.data?.data?.customer_info_arr.map((value: any) => {
          dropdownItemArr.push({ label: value, value: value });
        });

        setCustomers(dropdownItemArr);
      }
    },
  });

  // Only allow numbers to be entered in the vendor id field
  const handleChangeNumberField = (event: ChangeEvent<HTMLInputElement>) => {
    // matches only numbers and empty strings
    if (/^[0-9]+$/.test(event.target.value) || event.target.value.length === 0) {
      setFieldValue("vendor_id", event.target.value.replace(/[^0-9]/g, ""));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {customerError ? <div className="errorAlert">{customerError}</div> : null}
      <div className={styles.dialog}>
        {isSubmitting || customerInfoLoading ? (
          <Spinner />
        ) : (
          <>
            <div className="w-100 d-flex justify-content-around">
              <div className={`${styles.inputContainer} d-flex justify-content-between align-item-center `}>
                <input
                  name="vendor_id"
                  type="text"
                  placeholder={"Enter Vendor ID (Number)"}
                  value={values?.vendor_id}
                  onChange={handleChangeNumberField}
                  onBlur={() => setFieldTouched("vendor_id", true)}
                />
                {errors?.vendor_id && touched.vendor_id ? <p className={styles.error}>{errors?.vendor_id}</p> : null}
              </div>
              <div className={`${styles.inputContainer} d-flex justify-content-between align-item-center`}>
                <Select
                  selectProps={{
                    name: "customer_info",
                    value: values.customer_info || "",
                    placeholder: "Select Customer Info",
                    onChange: (event) => setFieldValue("customer_info", event.target.value),
                  }}
                  options={customers}
                />
                {errors?.customer_info && touched.customer_info ? <p className={styles.error}>{errors?.customer_info}</p> : null}
              </div>
            </div>

            <div className="w-100 d-flex justify-content-around">
              <div className={`${styles.inputContainer} d-flex justify-content-between align-item-center`}>
                <input
                  name="customer_name"
                  type="text"
                  placeholder={"Enter Vendor Name"}
                  value={values.customer_name}
                  onChange={(e) => handleAlphaNumInputChange(e, setFieldValue)}
                  onBlur={() => setFieldTouched("customer_name", true)}
                />
                {errors?.customer_name && touched.customer_name ? <p className={styles.error}>{errors?.customer_name}</p> : null}
              </div>
              <div className={`${styles.inputContainer} d-flex justify-content-between align-item-center`}>
                <div>
                  <span>Manufacturer</span>
                </div>
                <div className={"d-flex justify-content-between align-item-center"}>
                  <div className="d-flex">
                    <input
                      type="radio"
                      name="manufacturer"
                      id="yes"
                      checked={values.manufacturer === true}
                      onChange={() => setFieldValue("manufacturer", true)}
                    />
                    <label htmlFor="yes">Yes</label>
                  </div>
                  <div className="d-flex" style={{ marginLeft: 10 }}>
                    <input
                      type="radio"
                      name="manufacturer"
                      id="no"
                      checked={values.manufacturer === false}
                      onChange={() => setFieldValue("manufacturer", false)}
                    />
                    <label htmlFor="no">No</label>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-100 d-flex justify-content-around">
              <button className={styles.dialogBtn} type="submit" disabled={isSubmitting}>
                {enableReinitialize ? "Update" : "Add"}
              </button>
              <button type="button" className={styles.dialogBtn} onClick={handleReset}>
                Cancel
              </button>
            </div>
          </>
        )}
      </div>
    </form>
  );
};

export default Form;
