import { FormikProps } from "formik";
import { useCallback, ChangeEvent, useState } from "react";
import { ReactComponent as UploadFileIcon } from "../../../assets/images/uploadFile.svg";
import { AmpConfigInitValue, SelectOption } from "../../interfaces/fe.interfaces";
import Select from "../Select";
import Spinner from "../Spinner";
import styles from "./styles.module.scss";
import { useFetch } from "../../hooks/useFetch";
import { GetCustomersResponse } from "../../interfaces/be.interfaces";
import { getCustomers } from "../../api/config";
import Checkbox from "../Checkbox";

/**
 * Common form component for Add amp config and edit apm config
 * Takes in all the props from Formik component
 */
const Form = ({
  values,
  handleChange,
  touched,
  errors,
  handleSubmit,
  handleReset,
  setFieldValue,
  handleBlur,
  isSubmitting,
  enableReinitialize,
  setFieldTouched,
}: FormikProps<AmpConfigInitValue>) => {
  const [customerList, setCustomerList] = useState<SelectOption<number | null>[]>([]);

  /**
   * Fetch list of customers to show in dropdown
   * only active customers are displayed in dropdown
   */
  const { loading: customerInfoLoading } = useFetch<GetCustomersResponse>({
    runOnMount: true,
    url: getCustomers,
    onSuccess: (response) => {
      // Prepare customer data in option format to show in dropdown and filter inactive customers
      const customerOptions: SelectOption<number>[] = response.data?.data?.customers
        ?.filter((item) => item.is_enable)
        .map((customer) => ({
          label: customer.customer_name,
          value: customer.vendor_id,
        }));

      setCustomerList(customerOptions);
    },
  });

  // Handle customer change
  const onChangeCustomer = useCallback((value: number) => {
    setFieldValue("vendor_id", value);
  }, []);

  const onMcuFirmwareBinChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setFieldTouched("amp_config_bin", true);
    setFieldValue("amp_config_bin", event.target.files?.[0]);
  }, []);

  const onChangeVersion = useCallback((value: string) => {
    const regEx = /^[0-9.]+$/;

    // If value is empty or number or .
    if (regEx.test(value) || value === "") setFieldValue("config_version", value);
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.dialog}>
        {isSubmitting || customerInfoLoading ? (
          <Spinner />
        ) : (
          <>
            <div className="w-100 d-flex justify-content-around">
              <div className={`${styles.inputContainer} d-flex justify-content-between align-item-center`}>
                <input
                  id="config_version"
                  name="config_version"
                  type="text"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeVersion(e?.target?.value)}
                  placeholder="Add a config version"
                  value={values.config_version}
                />
                {errors?.config_version && touched.config_version ? <p className={styles.error}>{errors?.config_version}</p> : null}
              </div>
              <div className={`${styles.inputContainer} d-flex justify-content-between align-item-center`}>
                <input
                  name="amp_model"
                  type="text"
                  placeholder={"Add a amp model"}
                  value={values.amp_model}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                />
                {errors?.amp_model && touched.amp_model ? <p className={styles.error}>{errors?.amp_model}</p> : null}
              </div>
            </div>
            <div className="w-100 d-flex justify-content-around">
              <div className={`${styles.inputContainer} d-flex justify-content-between align-item-center`}>
                <Select
                  selectProps={{
                    value: values.vendor_id,
                    name: "vendor_id",
                    placeholder: "Select Customer",
                    onBlur: handleBlur,
                    onChange: (event) => onChangeCustomer(+event.target.value),
                  }}
                  options={customerList}
                />
                {errors?.vendor_id && touched?.vendor_id ? <p className={styles.error}>{errors.vendor_id}</p> : null}
              </div>
              <div className={`${styles.inputContainer} d-flex justify-content-between align-item-center`}>
                <textarea
                  name="config_description"
                  placeholder={"Add a config description"}
                  value={values.config_description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                />
                {errors?.config_description && touched.config_description ? (
                  <p className={styles.error}>{errors?.config_description}</p>
                ) : null}
              </div>
            </div>
            <div className="w-100 d-flex justify-content-around">
              <div className={`${styles.inputContainer} d-flex justify-content-between align-item-center`}>
                <div className={styles.wisaFirmwareUploadFormGridItem}>
                  <p className={styles.wisaFirmwareUploadFormIconText}>Choose Config File</p>
                  <label className={styles.wisaFirmwareUploadFormIconLabel} htmlFor="amp_config_bin">
                    <input id="amp_config_bin" name="amp_config_bin" type="file" onChange={onMcuFirmwareBinChange} hidden />
                    <UploadFileIcon className={styles.wisaFirmwareUploadFormUploadIcon} />
                    <div className={styles.wisaFirmwareUploadFormUploadMessagesWrapper}>
                      {values.amp_config_bin?.name && (
                        <p className={styles.wisaFirmwareUploadFormFileName}>{values.amp_config_bin?.name}</p>
                      )}
                      {touched.amp_config_bin && errors.amp_config_bin && <span className={styles.error}>{errors.amp_config_bin}</span>}
                    </div>
                  </label>
                </div>
              </div>
            </div>

            <div className="w-100 d-flex justify-content-around">
              <div className={`${styles.inputContainer} d-flex justify-content-between align-item-center`}>
                <Checkbox
                  label="Amp config is current customer version?"
                  name="current_customer_version"
                  checked={values.current_customer_version}
                  disabled={enableReinitialize}
                  onChange={() => !enableReinitialize && setFieldValue("current_customer_version", !values.current_customer_version)}
                />
              </div>
            </div>

            <div className="w-100 d-flex justify-content-around">
              <button className={styles.dialogBtn} type="submit" disabled={isSubmitting}>
                {enableReinitialize ? "Update" : "Add"}
              </button>
              <button type="button" className={styles.dialogBtn} onClick={handleReset}>
                Cancel
              </button>
            </div>
          </>
        )}
      </div>
    </form>
  );
};

export default Form;
