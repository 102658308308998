import Spinner from "../Spinner";
import styles from "./styles.module.scss";
import { getCustomers, getRoleInfo } from "../../api/config";
import { useCallback, useState } from "react";
import { SelectOption, UserFormProps } from "../../interfaces/fe.interfaces";
import { GetCustomersResponse, RoleInfoResponse } from "../../interfaces/be.interfaces";
import { useFetch } from "../../hooks/useFetch";
import Select from "../Select";
import { Icons } from "../../../assets/images";
import Checkbox from "../Checkbox";

/**
 * Common form component for AddUser and EditUser
 * Takes in all the props from Formik component
 */
const Form = ({
  values,
  handleChange,
  touched,
  errors,
  handleSubmit,
  handleReset,
  setFieldValue,
  handleBlur,
  isSubmitting,
  enableReinitialize,
  isEditForm,
}: UserFormProps) => {
  const [customerList, setCustomerList] = useState<SelectOption<number | null>[]>([]);
  const [isPasswordVisible, setIsPasswordVisible] = useState({
    password: false,
    confirmPassword: false,
  });

  // Get list of customer info options to display in dropdown selection
  const {
    loading: roleInfoLoading,
    error: customerError,
    data: roleInfoData,
  } = useFetch<RoleInfoResponse>({
    url: getRoleInfo,
    runOnMount: true,
  });

  /**
   * Fetch list of customers to show in dropdown
   * only active customers are displayed in dropdown
   */
  const { loading: customerInfoLoading, data: customerInfoList } = useFetch<GetCustomersResponse>({
    runOnMount: true,
    url: getCustomers,
    onSuccess: (response) => {
      // Prepare customer data in option format to show in dropdown and filter inactive customers
      const customerOptions: SelectOption<number>[] = response.data?.data?.customers
        ?.filter((item) => item.is_enable)
        .map((customer) => ({
          label: customer.customer_name,
          value: customer.vendor_id,
        }));

      setCustomerList(customerOptions);
    },
  });

  // Handle role change
  const onChangeRole = useCallback(
    (value: string) => {
      const role = roleInfoData?.data.list.find((item) => item.role_id === value);

      setFieldValue("role", role);
      setFieldValue("role_id", value);

      if (role && role.is_wisa_role) {
        // Find customer
        const customer = customerInfoList?.data.customers.find((item) => item.is_wisa === true);

        if (customer) {
          setFieldValue("customer", customer);
          setFieldValue("vendor_id", +customer.vendor_id);
        }
      }
    },
    [roleInfoData, customerInfoList]
  );

  // Handle customer change
  const onChangeCustomer = useCallback(
    (value: number) => {
      const customer = customerInfoList?.data.customers.find((item) => item.vendor_id === value);
      setFieldValue("customer", customer);
      setFieldValue("vendor_id", value);
    },
    [customerInfoList]
  );

  const onChangeIsPasswordVisible = useCallback((value: boolean) => {
    // Reset password and confirm password field when uncheck the password visible field
    if (!value) {
      setFieldValue("password", null);
      setFieldValue("confirmPassword", null);
    }

    setFieldValue("isPasswordFieldVisible", value);
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      {customerError ? <div className="errorAlert">{customerError}</div> : null}
      <div className={styles.dialog}>
        {isSubmitting || roleInfoLoading || customerInfoLoading ? (
          <Spinner />
        ) : (
          <>
            <div className="w-100 d-flex justify-content-around">
              <div className={`${styles.inputContainer} d-flex justify-content-between align-item-center`}>
                <Select
                  selectProps={{
                    name: "role",
                    value: values.role_id,
                    placeholder: "Select User Role",
                    onChange: (event) => onChangeRole(event.target.value),
                  }}
                  optionLabel="role_name"
                  optionValue="role_id"
                  options={roleInfoData?.data.list ?? []}
                />
                {errors?.role_id && touched.role_id ? <p className={styles.error}>{errors?.role_id}</p> : null}
              </div>
              <div className={`${styles.inputContainer} d-flex justify-content-between align-item-center `}>
                <Select
                  selectProps={{
                    value: values.vendor_id,
                    placeholder: "Select Customer",
                    onChange: (event) => onChangeCustomer(+event.target.value),
                    disabled: values.role?.is_wisa_role,
                  }}
                  options={customerList}
                />
                {touched.vendor_id && errors.vendor_id && <p className={styles.error}>{errors.vendor_id}</p>}
              </div>
            </div>

            <div className="w-100 d-flex justify-content-around">
              <div className={`${styles.inputContainer} d-flex justify-content-between align-item-center`}>
                <input
                  name="email"
                  type="text"
                  placeholder={"Enter User Email"}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                />
                {errors?.email && touched.email ? <p className={styles.error}>{errors?.email}</p> : null}
              </div>
              <div className={`${styles.inputContainer} d-flex justify-content-between align-item-center`}>
                <input
                  name="name"
                  type="text"
                  placeholder={"Enter User Name"}
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                />
                {errors?.name && touched.name ? <p className={styles.error}>{errors?.name}</p> : null}
              </div>
            </div>

            {values.isPasswordFieldVisible && (
              <div className="w-100 d-flex justify-content-around">
                <div className={`${styles.inputContainer} d-flex justify-content-between`}>
                  <div className={styles.passwordContainer}>
                    <input
                      name="password"
                      type={isPasswordVisible.password ? "text" : "password"}
                      placeholder={"Enter User Password"}
                      value={values?.password ?? ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                    />
                    <button
                      type="button"
                      onClick={() => setIsPasswordVisible({ ...isPasswordVisible, password: !isPasswordVisible.password })}>
                      {!isPasswordVisible.password ? <Icons.EyeOn /> : <Icons.EyeOff />}
                    </button>
                  </div>
                  {errors?.password && touched.password ? <p className={styles.error}>{errors?.password}</p> : null}
                </div>
                <div className={`${styles.inputContainer} d-flex justify-content-between align-item-center`}>
                  <div className={styles.passwordContainer}>
                    <input
                      name="confirmPassword"
                      type={isPasswordVisible.confirmPassword ? "text" : "password"}
                      placeholder={"Enter Password Again"}
                      value={values.confirmPassword ?? ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                    />
                    <button
                      type="button"
                      onClick={() => setIsPasswordVisible({ ...isPasswordVisible, confirmPassword: !isPasswordVisible.confirmPassword })}>
                      {!isPasswordVisible.confirmPassword ? <Icons.EyeOn /> : <Icons.EyeOff />}
                    </button>
                  </div>
                  {errors?.confirmPassword && touched.confirmPassword ? <p className={styles.error}>{errors?.confirmPassword}</p> : null}
                </div>
              </div>
            )}

            {isEditForm && (
              <div className={`${styles.passwordCheckBox} w-100 d-flex`}>
                <Checkbox
                  label="Change Password?"
                  name="isPasswordFieldVisible"
                  checked={values?.isPasswordFieldVisible ?? false}
                  onChange={() => onChangeIsPasswordVisible(!values.isPasswordFieldVisible)}
                />
              </div>
            )}

            <div className="w-100 d-flex justify-content-around">
              <button className={styles.dialogBtn} type="submit" disabled={isSubmitting}>
                {enableReinitialize ? "Update" : "Add"}
              </button>
              <button type="button" className={styles.dialogBtn} onClick={handleReset}>
                Cancel
              </button>
            </div>
          </>
        )}
      </div>
    </form>
  );
};

export default Form;
