import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import Modal from "../Modal/Modal";
import styles from "../CopyProduct/styles.module.scss";
import { RunOptions } from "../../hooks/useFetch";
import { CopyModelData, CopyModelFormValues } from "../../interfaces/fe.interfaces";
import { Formik, FormikHelpers } from "formik";
import { UpdateItemResponse } from "../../interfaces/be.interfaces";
import { copyModel } from "../../api/config";
import { validateCopyModel } from "../../utils/validationSchemas";
import { http } from "../../api/utils/http";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import CopyModelForm from "./CopyModelForm";

interface CopyModelProps {
  copyModelInfo: CopyModelData;
  setCopyModelInfo: Dispatch<SetStateAction<CopyModelData | null>>;
  refreshList: (options?: RunOptions) => void;
}

const initialValues: CopyModelFormValues = {
  new_vendor_id: null,
  new_product_id: null,
  model_id: null,
};

// Copy model dialogbox
const CopyModel = ({ copyModelInfo, setCopyModelInfo, refreshList }: CopyModelProps) => {
  const handleClose = useCallback(() => {
    setCopyModelInfo(null);
  }, []);

  // Submit handler for copy model form
  const handleSubmit = useCallback((values: CopyModelFormValues, { setSubmitting }: FormikHelpers<CopyModelFormValues>) => {
    const payload = {
      ...values,
      customer_product_model_id: copyModelInfo.customerProductModelId,
      customer_product_id: copyModelInfo.customerProductId,
    };

    // Success handler for copy model api
    const onSuccess = (res: AxiosResponse<UpdateItemResponse>) => {
      if (res.data.status) {
        // Show toast message and close dialogbox
        toast.success("Model copied successfully");
        handleClose();
        refreshList();
      } else {
        toast.error(res.data.message);
      }

      setSubmitting(false);
    };

    http.makePostRequest<UpdateItemResponse, { message: string }>(
      copyModel,
      onSuccess,
      (e) => {
        toast.error(e?.response?.data?.message);
        setSubmitting(false);
      },
      payload
    );
  }, []);

  const validationSchema = useMemo(() => validateCopyModel(copyModelInfo), []);

  return (
    <Modal isModalOpen={true} onModalClose={handleClose}>
      <div className={styles.dialogContainer}>
        <p className={styles.title}>Copy Model</p>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
          {(props) => <CopyModelForm {...props} copyModelInfo={copyModelInfo} handleClose={handleClose} />}
        </Formik>
      </div>
    </Modal>
  );
};

export default CopyModel;
