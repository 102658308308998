import { useMemo, useState } from "react";
import styles from "./styles.module.scss";
import { Icons } from "../../../assets/images";
import { getCustomers, enableDisableCustomer } from "../../api/config";
import { GetCustomersResponse, Customer, UpdateItemResponse } from "../../interfaces/be.interfaces";
import Spinner from "../../components/Spinner";
import Switch from "../../components/Switch";
import { useFetch } from "../../hooks/useFetch";
import { toast } from "react-toastify";
import AddCustomerDialog from "../../components/AddOrEditCustomer/AddCustomer";
import EditCustomerDialog from "../../components/AddOrEditCustomer/EditCustomer";
import { CustomerFormInitValues } from "../../interfaces/fe.interfaces";
import { Tooltip as ReactTooltip, Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { initialContext, useCustomerContext } from "../../hooks/useCustomerContext";
import { PERMISSIONS } from "../../utils/constants";
import AccessGuard from "../../components/Guards/AccessGuard";
import EditCustomerModuleDialog from "../../components/EditCustomerModule";

const ManageCustomer = () => {
  const { context, updateContext } = useCustomerContext();
  const [searchText, setSearchText] = useState<string>("");
  const [addDialogVisible, setAddDialogVisible] = useState(false);
  const [editCustomerDetails, setEditCustomerDetails] = useState<CustomerFormInitValues | null>(null);
  const [editCustomerModulesVendorId, setEditCustomerModulesVendorId] = useState<number | null>(null);

  // Hook to get the list of the customer
  const {
    loading: customersLoading,
    data: customer,
    error: customerError,
    run: runCustomers,
  } = useFetch<GetCustomersResponse>({ url: getCustomers, runOnMount: true });

  // Hook to update the status of the customer
  const { run: runStatusUpdate, error: statusUpdateError } = useFetch<UpdateItemResponse<Customer>>({
    url: getCustomers,
    runOnMount: false,
    onSuccess: ({ data: res }) => {
      toast.success(res?.message);

      // When the customer selected in the context is disabled then reset the context
      if (context.vendorId === res?.data?.vendor_id && !res.data.is_enable) {
        updateContext(initialContext);
      }

      // if successful then re-run the customer query to get the updated list
      runCustomers({
        showLoader: false,
      });
    },
  });

  const handleChangeStatus = (id: number): void => {
    runStatusUpdate({
      url: `${enableDisableCustomer}${id}`,
    });
  };

  const createOrEditCustomerCallback = () => {
    // if successful then re-run the customer query to get the updated list
    runCustomers({
      showLoader: false,
    });
  };

  // queries the customer data and filters it based on the search text on customer_name and vendor_id
  const searchResult = useMemo(() => {
    return customer?.data?.customers?.filter(
      (item: Customer) =>
        item?.customer_name?.toLowerCase()?.includes(searchText.toLowerCase()) ||
        item?.vendor_id?.toString()?.toLowerCase()?.includes(searchText.toLowerCase())
    );
  }, [customer, searchText]);

  return (
    <>
      <div className={styles.pageContainer}>
        <div className={`${styles.pageHeader} d-flex justify-content-between`}>
          <p>Manage Customer</p>
          <div className={`${styles.headerActions} d-flex justify-content-between align-item-center`}>
            <input type="text" value={searchText} placeholder="Search Customer" onChange={(e) => setSearchText(e.target.value)} />
            <AccessGuard permission={PERMISSIONS.CUSTOMER_ADD}>
              <button onClick={() => setAddDialogVisible(true)}>Add</button>
            </AccessGuard>
          </div>
        </div>
        {customerError || statusUpdateError ? <div className="errorAlert">{customerError || statusUpdateError}</div> : null}
        <div className={styles.tableResponsive}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Vendor ID</th>
                <th className={`${styles.vendorName}`}>Vendor Name</th>
                <th>Customer Info</th>
                <th className={styles.center}>Manufacturer</th>
                <AccessGuard permission={[PERMISSIONS.CUSTOMER_UPDATE, PERMISSIONS.CUSTOMER_ENABLE_DISABLE]}>
                  <th className={styles.action}>Action</th>
                </AccessGuard>
              </tr>
            </thead>
            <tbody>
              {customersLoading ? (
                <tr>
                  <td colSpan={4}>
                    <Spinner />
                  </td>
                </tr>
              ) : searchResult?.length ? (
                searchResult?.map((item) => {
                  return (
                    <>
                      <tr key={item.vendor_id}>
                        <td>{item?.vendor_id}</td>
                        <td id={`vendor_${item?.vendor_id}`} className={styles.customerName}>
                          <ReactTooltip anchorId={`vendor_${item?.vendor_id}`} place="top" content={item?.customer_name} />
                          {item?.customer_name}
                        </td>
                        <td>{item?.customer_info}</td>
                        <td>
                          <span className={`${styles.manufacturerDot} ${item.manufacturer ? styles.manufacturer : styles.user}`}></span>
                        </td>
                        <AccessGuard permission={[PERMISSIONS.CUSTOMER_UPDATE, PERMISSIONS.CUSTOMER_ENABLE_DISABLE]}>
                          <td className={styles.switchinfo}>
                            <AccessGuard permission={PERMISSIONS.UPDATE_CUSTOMER_MODULE}>
                              <Tooltip id="edit-module-tooltip" />
                              <Icons.List
                                onClick={() => !item.is_wisa && setEditCustomerModulesVendorId(item.vendor_id)}
                                aria-disabled={item.is_wisa}
                                data-tooltip-id={"edit-module-tooltip"}
                                data-tooltip-content={
                                  !item.is_wisa ? "Edit customer module access" : "Default customer module access can not be edited"
                                }
                                data-tooltip-place="top"
                                className={item.is_wisa ? styles.disabled : ""}
                              />
                            </AccessGuard>
                            <AccessGuard permission={PERMISSIONS.CUSTOMER_UPDATE}>
                              <Icons.EditIcon onClick={() => setEditCustomerDetails({ ...item, vendor_id: item.vendor_id.toString() })} />
                            </AccessGuard>
                            <AccessGuard permission={PERMISSIONS.CUSTOMER_ENABLE_DISABLE}>
                              <Switch checked={item.is_enable} name={"manufacturer"} onChange={() => handleChangeStatus(item?.vendor_id)} />
                            </AccessGuard>
                          </td>
                        </AccessGuard>
                      </tr>
                    </>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={5}>
                    <div className={`${styles.emptyPage} d-flex justify-content-center align-item-center`}>
                      <span className={styles.noDataFound}>No Customer Found</span>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {addDialogVisible ? (
          <AddCustomerDialog callback={createOrEditCustomerCallback} onCancelClick={() => setAddDialogVisible(false)} />
        ) : null}
        {editCustomerModulesVendorId ? (
          <EditCustomerModuleDialog vendor_id={editCustomerModulesVendorId} onCancelClick={() => setEditCustomerModulesVendorId(null)} />
        ) : null}
        {editCustomerDetails ? (
          <EditCustomerDialog
            id={editCustomerDetails?.vendor_id}
            initialValues={editCustomerDetails}
            callback={createOrEditCustomerCallback}
            onCancelClick={() => setEditCustomerDetails(null)}
          />
        ) : null}
      </div>
    </>
  );
};

export default ManageCustomer;
