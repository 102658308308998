import { FormikProps } from "formik";
import { ExpressTxConfigurationsFormModel, ExpressTxConfigurationsModel } from "../../../interfaces/fe.interfaces";
import styles from "./styles.module.scss";
import { ChangeEvent, useCallback } from "react";
import Checkbox from "../../../components/Checkbox";

interface ManageProductExpressTxConfigurationsYourProductProps extends FormikProps<ExpressTxConfigurationsFormModel> {
  haveSelectedCheckboxValue: (
    formValues: ExpressTxConfigurationsFormModel,
    fieldsetName: ExpressTxConfigurationsModel,
    checkboxValue: string
  ) => boolean;
}

const ManageProductExpressTxConfigurationsYourProduct = ({
  values,
  setFieldValue,
  haveSelectedCheckboxValue,
  touched,
  errors,
  handleBlur,
}: ManageProductExpressTxConfigurationsYourProductProps) => {
  const handleRxConfigurationsCheckboxChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const isChecked = e.target.checked;
      if (isChecked) {
        values.rx_configurations = values.rx_configurations ? [...values.rx_configurations, e.target.name] : [e.target.name];
      } else {
        values.rx_configurations = values.rx_configurations ? values.rx_configurations.filter((rx) => rx !== e.target.name) : [];
      }
      setFieldValue(e.target.name, values.rx_configurations);
    },
    [setFieldValue, values]
  );

  const hanldeRadioChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      let val: string | boolean = e.target.value;
      if (val === "true") {
        val = true;
      } else if (val === "false") {
        val = false;
      }
      setFieldValue(e.target.name, val);
    },
    [setFieldValue]
  );

  return (
    <div>
      <div className={styles.header}>Your Product</div>
      <div className={styles.section}>
        <div className={styles.inputItem}>
          <label>RX Configuration Options</label>
          <div className={styles.checkboxContainer}>
            <Checkbox
              label="2.0"
              name="rx_configuration_options_2.0"
              checked={haveSelectedCheckboxValue(values, "rx_configurations", "rx_configuration_options_2.0")}
              onChange={handleRxConfigurationsCheckboxChange}
            />
            <Checkbox
              label="2.1"
              name="rx_configuration_options_2.1"
              checked={haveSelectedCheckboxValue(values, "rx_configurations", "rx_configuration_options_2.1")}
              onChange={handleRxConfigurationsCheckboxChange}
            />
            <Checkbox
              label="2.1.2"
              name="rx_configuration_options_2.1.2"
              checked={haveSelectedCheckboxValue(values, "rx_configurations", "rx_configuration_options_2.1.2")}
              onChange={handleRxConfigurationsCheckboxChange}
            />
            <Checkbox
              label="3.1"
              name="rx_configuration_options_3.1"
              checked={haveSelectedCheckboxValue(values, "rx_configurations", "rx_configuration_options_3.1")}
              onChange={handleRxConfigurationsCheckboxChange}
            />
            <Checkbox
              label="3.1.2"
              name="rx_configuration_options_3.1.2"
              checked={haveSelectedCheckboxValue(values, "rx_configurations", "rx_configuration_options_3.1.2")}
              onChange={handleRxConfigurationsCheckboxChange}
            />
            <Checkbox
              label="4.1"
              name="rx_configuration_options_4.1"
              checked={haveSelectedCheckboxValue(values, "rx_configurations", "rx_configuration_options_4.1")}
              onChange={handleRxConfigurationsCheckboxChange}
            />
            <Checkbox
              label="5.1"
              name="rx_configuration_options_5.1"
              checked={haveSelectedCheckboxValue(values, "rx_configurations", "rx_configuration_options_5.1")}
              onChange={handleRxConfigurationsCheckboxChange}
            />
            <Checkbox
              label="5.1.2"
              name="rx_configuration_options_5.1.2"
              checked={haveSelectedCheckboxValue(values, "rx_configurations", "rx_configuration_options_5.1.2")}
              onChange={handleRxConfigurationsCheckboxChange}
            />
            <Checkbox
              label="5.1.4"
              name="rx_configuration_options_5.1.4"
              checked={haveSelectedCheckboxValue(values, "rx_configurations", "rx_configuration_options_5.1.4")}
              onChange={handleRxConfigurationsCheckboxChange}
            />
            <Checkbox
              label="7.1"
              name="rx_configuration_options_7.1"
              checked={haveSelectedCheckboxValue(values, "rx_configurations", "rx_configuration_options_7.1")}
              onChange={handleRxConfigurationsCheckboxChange}
            />
            <Checkbox
              label="7.1.2"
              name="rx_configuration_options_7.1.2"
              checked={haveSelectedCheckboxValue(values, "rx_configurations", "rx_configuration_options_7.1.2")}
              onChange={handleRxConfigurationsCheckboxChange}
            />
            <Checkbox
              label="7.1.4"
              name="rx_configuration_options_7.1.4"
              checked={haveSelectedCheckboxValue(values, "rx_configurations", "rx_configuration_options_7.1.4")}
              onChange={handleRxConfigurationsCheckboxChange}
            />
          </div>
          {touched?.rx_configurations && errors?.rx_configurations && <span className={styles.error}>{errors?.rx_configurations}</span>}
        </div>
        <div className={styles.inputItem}>
          <label>Sync Requirements</label>
          <div>
            <input
              id="sync_requirements_less_than_300_micro_seconds"
              type="radio"
              name="sync_requirements"
              value="less_than_300_micro_seconds"
              checked={values.sync_requirements === "less_than_300_micro_seconds"}
              onChange={hanldeRadioChange}
              onBlur={handleBlur}
            />
            <label htmlFor="sync_requirements_less_than_300_micro_seconds">{"\u003C"} 150 micro seconds</label>
          </div>
          <div>
            <input
              id="sync_requirements_less_than_150_micro_seconds"
              type="radio"
              name="sync_requirements"
              value="less_than_150_micro_seconds"
              checked={values.sync_requirements === "less_than_150_micro_seconds"}
              onChange={hanldeRadioChange}
              onBlur={handleBlur}
            />
            <label htmlFor="sync_requirements_less_than_150_micro_seconds">{"\u003C"} 150 micro seconds</label>
          </div>
          <div>
            <input
              id="sync_requirements_less_than_+/-_21_micro_seconds"
              type="radio"
              name="sync_requirements"
              value="less_than_+/-_21_micro_seconds"
              checked={values.sync_requirements === "less_than_+/-_21_micro_seconds"}
              onChange={hanldeRadioChange}
              onBlur={handleBlur}
            />
            <label htmlFor="sync_requirements_less_than_+/-_21_micro_seconds">{"\u003C"} +/- 21 micro seconds</label>
          </div>
          {touched?.sync_requirements && errors?.sync_requirements && <span className={styles.error}>{errors?.sync_requirements}</span>}
        </div>
        <div className={styles.inputItem}>
          <label>TSF Available?</label>
          <div>
            <input
              id="tsf_available_true"
              type="radio"
              name="tsf_available"
              value="true"
              checked={values.tsf_available === true}
              onChange={hanldeRadioChange}
              onBlur={handleBlur}
            />
            <label htmlFor="tsf_available_true">Yes</label>
          </div>
          <div>
            <input
              id="tsf_available_false"
              type="radio"
              name="tsf_available"
              value="false"
              checked={values.tsf_available === false}
              onChange={hanldeRadioChange}
              onBlur={handleBlur}
            />
            <label htmlFor="tsf_available_true">No</label>
          </div>
          {touched?.tsf_available && errors?.tsf_available && <span className={styles.error}>{errors?.tsf_available}</span>}
        </div>
      </div>
    </div>
  );
};

export { ManageProductExpressTxConfigurationsYourProduct };
