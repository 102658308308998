import { Formik, FormikHelpers } from "formik";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { updateirmware, uploadFirmware } from "../../../api/config";
import { http } from "../../../api/utils/http";
import { UploadFirmwareResponse, WisaProductModel } from "../../../interfaces/be.interfaces";
import { ManageFirmwareWisaFirmwareFormInitValuesInterface } from "../../../interfaces/fe.interfaces";
import { validateWisaFirmwareUploadForm } from "../../../utils/validationSchemas";
import ManageFirmwareForm from "./Form";
import styles from "./styles.module.scss";

export interface ManageFirmwareWisaFirmwareEditFormProps {
  wisaProducts: WisaProductModel[];
  onUploadFirmware?: () => void;
  onFormSubmitSuccessCallback?: () => void;
  onFormSubmitFailureCallback?: () => void;
  initialValues: ManageFirmwareWisaFirmwareFormInitValuesInterface;
  versionId: number;
}

const ManageFirmwareWisaFirmwareEditForm = ({
  wisaProducts,
  onUploadFirmware,
  onFormSubmitFailureCallback,
  onFormSubmitSuccessCallback,
  initialValues,
  versionId,
}: ManageFirmwareWisaFirmwareEditFormProps) => {
  const [postFirmwareError, setPostFirmwareError] = useState<string | undefined>(undefined);

  const onError = useCallback(
    (setSubmitting: (val: boolean) => void, message?: string) => {
      setPostFirmwareError(message);
      onFormSubmitFailureCallback?.();
      setSubmitting(false);
    },
    [setPostFirmwareError, onFormSubmitFailureCallback]
  );

  const onSuccess = useCallback(
    (data: UploadFirmwareResponse, setSubmitting: (isSubmitting: boolean) => void) => {
      if (data.status) {
        setPostFirmwareError(undefined);
        toast.success(data.message);
        onUploadFirmware?.();
      } else {
        setPostFirmwareError(data.message);
      }

      onFormSubmitSuccessCallback?.();
      setSubmitting(false);
    },
    [setPostFirmwareError, onFormSubmitSuccessCallback]
  );

  const handleOnFirmwareSubmit = useCallback(
    (
      values: ManageFirmwareWisaFirmwareFormInitValuesInterface,
      { setSubmitting }: FormikHelpers<ManageFirmwareWisaFirmwareFormInitValuesInterface>
    ) => {
      const formData = new FormData();
      formData.append("current_customer_version", values.current_customer_version ? "true" : "false");
      values.description && formData.append("description", values.description);
      formData.append("memory_location", values.memory_location);
      formData.append("version", values.version);
      formData.append("wisa_product", values.wisa_product);
      formData.append("version_id", String(versionId));
      values.firmware_bin && formData.append("firmware_bin", values.firmware_bin);

      http.makePostRequestWithFormData<UploadFirmwareResponse, { message: string }>(
        updateirmware,
        (res) => onSuccess(res.data, setSubmitting),
        (e) => onError(setSubmitting, e?.response?.data.message),
        formData
      );
    },
    [onError, onSuccess, uploadFirmware, initialValues]
  );

  return (
    <div className={styles.wisaFirmwareUploadFormContainer}>
      {postFirmwareError && <div className="errorAlert">{postFirmwareError}</div>}
      <Formik initialValues={initialValues} onSubmit={handleOnFirmwareSubmit} validationSchema={validateWisaFirmwareUploadForm}>
        {(props) => <ManageFirmwareForm {...props} wisaProducts={wisaProducts} isEdit />}
      </Formik>
    </div>
  );
};

export default ManageFirmwareWisaFirmwareEditForm;
