import { toast } from "react-toastify";
import { CommunicationType } from "../interfaces/fe.interfaces";
import { SerialPort } from "../interfaces/serial";
import { DEVICE_TIMEOUT } from "./constants";

/**
 * Serial Driver class to handle serial programming operation
 * @class SerialDriver
 * @method
 */
export class SerialDriver {
  communicationType = CommunicationType.SERIAL;
  ACK = 0x06;
  port: SerialPort | null = null;
  deviceResponses = new Uint8Array([]);
  deviceResponseTimeout = DEVICE_TIMEOUT;
  timeoutController: NodeJS.Timeout | null = null;

  /**
   * This function write data to the device
   * @return Array of numbers to be sent to the device
   */
  private async write(data: number[]) {
    if (this.port && this.port.writable) {
      const writer = this.port.writable.getWriter();

      const uintArr = new Uint8Array(data);
      await writer.write(uintArr);
      writer.releaseLock();
    }
  }

  /**
   * This function read data to the device
   * @return Array of numbers to be sent to the device
   */
  private async read() {
    if (this.port && this.port.readable) {
      const reader = this.port.readable.getReader();
      const { value } = await reader.read();

      reader.releaseLock();
      return value;
    }
  }

  /**
   * This function use to run sequence for serial programming on device
   * @param Sequence is an array of arrays, where each array is a chunk of commands, which are sent to the device one by one
   * @returns data - Array of numbers to be sent to the device
   */
  async runSequence(sequence: (string | number)[][]) {
    let readEnabled = true;

    for (let i = 0; i < sequence.length; i++) {
      const data = sequence[i];

      // For serial programming we need the UART to operate on different baud rate for performing different operations.
      // So server will send array with OPEN as first element and second element would be the baud rate on which it needs to be operated.
      if (data[0] === "OPEN") {
        this.port && (await this.port.open({ baudRate: +data[1], baudrate: +data[1] }));
        await new Promise((resolve) => setTimeout(resolve, 100));
        continue;
      }

      // Server will send NO_READ if we need to disable reading for some operations
      if (data[0] === "NO_READ") {
        readEnabled = false;
        continue;
      }

      // Server will send READ if we need to read from device while running upcoming commands.
      if (data[0] === "READ") {
        readEnabled = true;
        continue;
      }

      // CLOSE command will close the port.
      if (data[0] === "CLOSE") {
        this.port && (await this.port.close());
        await new Promise((resolve) => setTimeout(resolve, 100));
        continue;
      }

      // If we receive delay from server then we need to wait for that amount of time before running next commands.
      if (data[0] === "delay") {
        await new Promise((resolve) => setTimeout(resolve, +data[1]));
        continue;
      }

      await this.write(data as any);

      let timedOut = false;
      // read from device only when backend enables it using READ command
      if (readEnabled) {
        // eslint-disable-next-line no-constant-condition
        while (true) {
          const value: Uint8Array = await new Promise((resolve) => {
            if (this.timeoutController) {
              clearTimeout(this.timeoutController);
            }

            // Reject sequence if reading from device takes more than timeout time.
            this.timeoutController = setTimeout(() => {
              this.timeoutController && clearTimeout(this.timeoutController);

              // Resolve with empty array and break out of loop
              resolve(new Uint8Array([]));
              timedOut = true;
              toast.error("Device response timeout, please reconnect serial port.");
            }, this.deviceResponseTimeout);

            // Wait for the read.
            this.read().then((value) => {
              if (value) resolve(value);
            });
          });

          // When timed out break the loop and return empty response so UI can be updated with failed status
          if (timedOut) {
            break;
          }

          if (value?.[0] == this.ACK) {
            this.deviceResponses = value;
            break;
          }
        }
      }

      if (timedOut) {
        return new Uint8Array([]);
      }
    }

    return this.deviceResponses;
  }

  async connect() {
    let devicePort;
    const port = await navigator.serial.getPorts();

    if (!port.length) {
      devicePort = await navigator.serial.requestPort();
    } else {
      devicePort = port[0];
    }

    if (!devicePort) {
      return false;
    }

    this.port = devicePort;

    return true;
  }

  async disconnect() {
    if (this.port) {
      await this.port.close();
      this.port = null;
    }
  }
}
