import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePermission } from "../../hooks/usePermission";
import { CUSTOMER_MODULES, PERMISSIONS } from "../../utils/constants";

interface AuthGuardProps {
  children: React.ReactNode;
  permission?: PERMISSIONS;
  moduleAccess?: CUSTOMER_MODULES;
}

/**
 * This component is used for checking if user is logged in and has permission to access the route
 * Also check the logged in user's customer has module access or not
 */
const AuthGuard = ({ children, permission, moduleAccess }: AuthGuardProps) => {
  const navigate = useNavigate();
  const [token, setToken] = useState<string | null>(null);

  const { checkPermission, checkModuleAccess } = usePermission();

  useEffect(() => {
    const token = localStorage.getItem("token");

    // If no token is found, redirect to login page
    if (!token) {
      navigate("/login");
    } else {
      setToken(token);
    }
  }, []);

  if (!token) {
    return null;
  }

  // If module access passed in the guard then that will be check for user has module access rights or not
  // If permission is passed in the guard then that will check that user has permission for access that component or not
  // If not, redirect to home page
  if ((permission && !checkPermission(permission)) || (moduleAccess && !checkModuleAccess(moduleAccess))) {
    navigate("/");
    return null;
  } else {
    return <>{children}</>;
  }
};

export default AuthGuard;
