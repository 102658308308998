import { useCallback } from "react";
import styles from "./styles.module.scss";
import { Formik } from "formik";
import { AddMacValidationSchema } from "../../utils/validationSchemas";
import { http } from "../../api/utils/http";
import { addMacManually } from "../../api/config";
import { UpdateItemResponse } from "../../interfaces/be.interfaces";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { formatStrToMac } from "../../utils/helpers";

/**
 * @interface AddMacAddressDialogProps
 * @property {() => void} handleClose - function to close the dialog
 * @property {() => void} handleAfterSubmit - function to be called after successful submit
 */
interface AddMacAddressDialogProps {
  handleClose: () => void;
  handleAfterSubmit: () => void;
}

const initialValues = {
  wisa_mac_address: "",
};

/**
 * This component is responsible for adding mac address manually
 */
const AddMacAddressDialog = (props: AddMacAddressDialogProps) => {
  // This function is used to stop propagation of click event
  const stopPropagation = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);

  // Submit handler of the form
  const handleSubmit = useCallback((values: typeof initialValues) => {
    // Success callback for /add-mac-address API
    const onSuccess = (res: AxiosResponse<UpdateItemResponse>) => {
      // If status is true then show success toast and close the dialog
      if (res?.data?.status === true) {
        toast.success(res?.data?.message);
        props.handleClose();
        props.handleAfterSubmit();
      }
    };

    // Make post request to /add-mac-address API
    http.makePostRequest<UpdateItemResponse, { message: string }>(
      addMacManually,
      onSuccess,
      (error) => {
        toast.error(error?.response?.data?.message || "Something went wrong");
      },
      values
    );
  }, []);

  return (
    <div className={styles.dialogContainer} onClick={props.handleClose}>
      <Formik validationSchema={AddMacValidationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
        {({ handleSubmit, values, setFieldValue, handleBlur, touched, errors, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <div className={styles.dialog} onClick={stopPropagation}>
              <div className={styles.inputContainer}>
                <input
                  type="text"
                  name="wisa_mac_address"
                  placeholder="Enter MAC address"
                  value={values.wisa_mac_address}
                  onChange={(e) => {
                    // format the mac address to have : after every 2 characters
                    setFieldValue("wisa_mac_address", formatStrToMac(e.target.value));
                  }}
                  onBlur={handleBlur}
                  maxLength={17}
                />
                {touched?.wisa_mac_address && errors?.wisa_mac_address && <div className={styles.error}>{errors?.wisa_mac_address}</div>}
              </div>
              <div className="w-100 d-flex justify-content-around">
                <button className={styles.dialogBtn} type="submit" disabled={isSubmitting}>
                  Add
                </button>
                <button className={styles.dialogBtn} onClick={props.handleClose}>
                  Cancel
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default AddMacAddressDialog;
