/**
 * This file contains all the interfaces for backend API repsonses
 * Each interface should have exactly same keys as the API response
 */

import { COMMAND_STATUS, CUSTOMER_MODULES, PERMISSIONS, ROLE_ID, EXPRESS_DECODE_OPTIONS_ENUM } from "../utils/constants";
import { CustomCommandResultType, SelectOption, TxI2sFormat } from "./fe.interfaces";

export interface CommandSequenceOrders {
  wisa_command_sequence: string;
  wisa_sub_seq: {
    command_name: string;
    move_on: boolean;
  };
  status: COMMAND_STATUS;
  deviceResponse?: string;
}

export interface Sequence {
  name: string;
  description: string;
  wisa_sequence_command_orders: CommandSequenceOrders[];
}

export interface SequencePermission {
  sequence: Sequence[];
  user: UserPermissionResponse;
}

export interface SequencesData {
  status: boolean;
  message: string;
  data: SequencePermission;
}

export interface InitSeqResponse {
  status: boolean;
  message: string;
  data: (string | number)[][];
}

export interface PostScannedMacResponse {
  status: boolean;
  message: string;
  data: {
    sessionId: string;
    command: (string | number)[][];
    commandName: string;
    sequence?: CommandSequenceOrders[];
  };
}

export enum COMMAND_RESPONSE_STATUS {
  Success = "success",
  Failed = "failed",
  Continue = "continue",
}

export enum COMMAND_TYPE {
  Get,
  Set,
}

export interface GetNextOperationResponse {
  status: boolean;
  message: string;
  data: {
    sessionId?: string;
    command?: (string | number)[][];
    fakeResponse?: string;
    status: COMMAND_RESPONSE_STATUS;
    set: COMMAND_TYPE;
    customCommand?: CustomCommand;
    commandName: string;
    skipNext?: boolean;
    skipSubSeq?: boolean;
  };
}

export interface CustomSequence {
  created_at: string;
  enabled: boolean;
  miss_match_count: number;
  disabled_command_count: number;
  seq_id: string;
  seq_name: string;
  updated_at: string;
  is_default: boolean;
}

export interface CustomSequenceData {
  status: boolean;
  message: string;
  data: CustomSequence[];
}

export interface InputSequenceData {
  status: boolean;
  message: string;
  data: CustomSequence;
}

export interface WisaCommands {
  command_code: string;
  command_name: string;
  description: string;
}
export interface WisaSeqCom {
  command_code: string;
  command_name: string;
  description: string;
}

export interface WisaCommandsData {
  wisa_commands: WisaCommands[];
  wisa_sub_seq: WisaSeqCom[];
  custom_commands: CustomCommand[];
}

export interface GetWisaCommands {
  data: WisaCommandsData;
}

export interface SeqCommand {
  created_at: string;
  execution_order: number;
  seq_command_id: string;
  updated_at: string;
  wisa_commands: WisaCommands | null;
  wisa_commands_id: string | null;
  wisa_sub_seq: WisaSeqCom | null;
  wisa_cust_commands: CustomCommand | null;
  wisa_seq_com_id: string | null;
}

export interface WisaCommandDetails {
  created_at: string;
  enabled: boolean;
  seq_id: number;
  seq_name: string;
  updated_at: string;
  seq_commands: SeqCommand[];
  vendor_id: number;
  customer: Customer;
}

export interface GetWisaCommandDetails {
  status: boolean;
  message: string;
  data: WisaCommandDetails;
}

export interface PassingValue {
  cust_command_id: string;
  value: string;
}
export interface CustomCommand {
  cust_command_id: string;
  cust_command_name: string;
  cust_command_description: string;
  vendor_id: number | null;
  enabled: boolean;
  result_type: CustomCommandResultType;
  move_on: boolean;
  use_count: number;
  miss_match_count: number;
  passing_values: PassingValue[];
  min_value: number | null;
  max_value: number | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

export interface CustomCommandData {
  status: boolean;
  message: string;
  data: CustomCommand[];
}

export interface InputCommandData {
  status: boolean;
  message: string;
  data: CustomCommand;
}

export interface CalibrationChannel {
  channel: string;
  center_freq: string;
}
export interface InputTxPo extends CalibrationChannel {
  tx_po_raw: number;
  de: number;
  tx_po_calc: number;
  thermal: string;
}

export interface InputRxGain extends CalibrationChannel {
  rssi_raw: number;
  esg: number;
  rssi_calc: number;
}

export interface InputPPM extends CalibrationChannel {
  ppm_raw: number;
  xtal_k: number;
  ppm_calc: number;
  ppm_raw_adjusted: number;
  ppm_calc_adjusted: number;
}

export interface InputEVM extends CalibrationChannel {
  evm: number;
}

export interface InputTestResults extends CalibrationChannel {
  evm: string;
  ppm: string;
  rx_gain: string;
  sensitivity: string;
  tx_po: string;
  ppm_adjusted: string;
}

export interface CalibrationChannelData {
  status: boolean;
  message: string;
  data: {
    TX_PO_VALUES: InputTxPo[];
    PPM_VALUES: InputPPM[];
    EVM_VALUES: InputEVM[];
    RX_GAIN_VALUES: InputRxGain[];
    TEST_RESULT_VALUES: InputTestResults[];
  };
}

export interface CalcResults {
  status: boolean;
  message: string;
  data: {
    calc_e_fuse: {
      thermal: string;
      ch_36_38_40: number;
      ch_44_46_48: number;
      ch_149_151_153: number;
      ch_157_159_161: number;
      ch_165_169: number;
      ch_173_177: number;
      hex_0x148: string;
      hex_0x14c: string;
      xtal_k: string;
    };
  };
}

export interface Customer {
  vendor_id: number;
  customer_name: string;
  manufacturer: boolean;
  customer_info: string;
  customer_abbreviation: string;
  is_enable: boolean;
  mfg_site: string;
  is_wisa: boolean;
  created_at: string;
  updated_at: string;
}

export interface GetCustomersResponse {
  status: boolean;
  message: string;
  data: { customers: Customer[] };
}

export interface CustomerInfoResponse {
  status: boolean;
  message: string;
  data: { customer_info_arr: string[] };
}

export interface PostOrPutCustomerResponse {
  status: boolean;
  message: string;
  data: Customer;
}

export enum ProductTypes {
  WiSA_DS = "WiSA-DS",
  WiSA_E = "WiSA-E",
}

export enum ModelTypes {
  TX = "TX",
  RX = "RX",
}

export interface Model {
  customer_product_model_id: number;
  customer_product_id: number;
  model_id: string;
  model_description: string;
  use_case_id: number;
  use_case_info: UseCase;
  crossover: string;
  size: string | null;
  tx_rx: ModelTypes;
  is_enable?: boolean;
  customer_product_model_amp_config: {
    customer_amp_config_id: number;
    amp_config: AmpConfiguration;
  } | null;
  customer_product_model_mcu_firmware: {
    mcu_firmware_id: number;
    mcu_firmware: McuFirmwareVersion;
  } | null;
  customer_product_model_chime_config: {
    right_speaker_chime_config_id: number | null;
    left_speaker_chime_config_id: number;
    chime_config: ChimeConfiguration;
  } | null;
}

export interface UseCase {
  use_case_id: number;
  use_case: string;
  product_type: ProductTypes;
  bitmask_left: string;
  bitmask_right: string | null;
  tx_rx: ModelTypes;
  positions_required: boolean;
}

export interface CustomerProductVersion {
  customer_product_version_id: number;
  customer_product_id: number;
  version_id: number;
  is_current_firmware: boolean;
  version_details: WisaFirmwareVersionModel;
}

export interface Product {
  customer_product_id: number;
  product_id: number;
  vendor_id: number;
  product_name: string;
  product_description: string;
  is_enable: boolean;
  product_type: ProductTypes;
  will_config_amp: boolean;
  will_config_chime: boolean;
  will_update_mcu: boolean;
  map_assc_mode: boolean;
  tx_clock_follower: boolean;
  sub_com_provided: boolean;
  wisa_server: boolean;
  notifications: boolean;
  rx_health: boolean;
  volume_manage_in_apps: boolean;
  software_buttons: boolean;
  small_datagrams: boolean;
  large_datagrams: boolean;
  energy_star: boolean;
  time_out_duration: number | null;
  regions: RegionList[];
  use_case_id: number;
  use_case_info: UseCase;
  other_region: string | null;
  customer_product_versions: CustomerProductVersion[];
  tx_i2s_format: TxI2sFormat;
}
export interface ProductModel extends Product {
  customer_product_models: Required<Model>[];
}
export interface CustomerProduct extends Customer {
  customer_products: ProductModel[];
}
export interface GetProductListResponse {
  status: boolean;
  message: string;
  data: { list: CustomerProduct[]; required_speaker_position: number[] };
}

export interface UpdateItemResponse<T = unknown> {
  status: boolean;
  message: string;
  data: T;
}

export interface AddProductResponse {
  status: boolean;
  message: string;
  data: { product: Product };
}

export interface ProductInfo {
  status: boolean;
  message: string;
  data: ProductModel & {
    customer: Customer;
  };
}

export interface ModelUseCase {
  status: boolean;
  message: string;
  data: UseCase[];
}

export interface Region {
  region_code: string;
  region_name: string;
}

export interface RegionList {
  customer_product_id: number;
  region_code: string;
  region_details: Region;
}

export interface GetRegionsList {
  status: boolean;
  message: string;
  data: {
    regions: Region[];
  };
}

export interface WiSAMac {
  assigned: boolean;
  customer_assigned_to: number | null;
  date_assigned: string | null;
  product_type: string;
  wisa_customer: Customer | null;
  wisa_mac_address: string;
}

export interface GetWiSAMacListResponse {
  status: boolean;
  message: string;
  data: {
    limit: number;
    list: WiSAMac[];
    offset: number;
    total: number;
    importProcesses: string[];
    failedCsvList: string[];
  };
}

export interface ImportMacProcessInfo {
  status: boolean;
  message: string;
  data: {
    estimatedTime: string;
    failCount: number;
    successCount: number;
    total: number;
  };
}

export interface GetWiSAMacResponse {
  status: boolean;
  message: string;
  data: {
    wisa_mac: WiSAMac;
    command: number[][];
  };
}
export interface WisaFirmwareVersionModel {
  version_id: number;
  current_customer_version: boolean;
  description: string;
  file_name: string;
  memory_location: string;
  version: string;
  wisa_product: string;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
}

export interface GetWisaFirmwareVersionsResponse {
  status: boolean;
  message: string;
  data: {
    list: WisaFirmwareVersionModel[];
  };
}

export interface UploadFirmwareResponse {
  status: boolean;
  message: string;
  data: { firmware: WisaFirmwareVersionModel };
}

export interface AppVersionInfo {
  dirty: boolean;
  distance: string | null;
  hash: string | null;
  raw: string | null;
  semver: string | null;
  semverString: string | null;
  suffix: string | null;
  tag: string | null;
}

export interface AppInfoApiResponse {
  status: boolean;
  message: string;
  data: {
    dwh: AppVersionInfo;
    server: AppVersionInfo;
  };
}

export interface WisaProductModel {
  type: string;
  mfg: string;
  order: number;
  created_at: string;
  updated_at: string;
}

export interface GetWisaProductsResponse {
  status: boolean;
  message: string;
  data: {
    list: WisaProductModel[];
  };
}
export interface DynamicInputs {
  [key: string]: {
    length: number;
    prefix: string;
  };
}

export interface GetSetCommandsInputsResponse {
  status: boolean;
  message: string;
  data: {
    fixed_values: {
      [key: string]: SelectOption<string>[];
    };
    dynamic_values: DynamicInputs;
  };
}

export enum WisaSequences {
  DEVICE_CONFIGURATION = "DEVICE_CONFIGURATION",
  MODULE_PROGRAMMING = "MODULE_PROGRAMMING",
  SPEAKER_TESTING = "SPEAKER_TESTING",
  SYSTEM_TESTING = "SYSTEM_TESTING",
  REMOTE_COMMANDS = "REMOTE_COMMANDS",
  DEVICE_CALIBRATION = "DEVICE_CALIBRATION",
  SERIAL_PROGRAMMING = "SERIAL_PROGRAMMING",
}

export interface Role {
  role_id: ROLE_ID;
  role_name: string;
  permission: PERMISSIONS[];
  is_wisa_role: boolean;
}

export interface RoleInfoResponse {
  status: boolean;
  message: string;
  data: { list: Role[] };
}

export interface User {
  user_id: number;
  vendor_id: number;
  name: string;
  email: string;
  role: Role;
  role_id: ROLE_ID;
  is_enable: boolean;
  is_default: boolean;
  customer: Customer;
}

export interface UserPermissionResponse extends User {
  customer: Customer & {
    customer_modules: CustomerModuleResponse;
  };
}

export interface GetUsersResponse {
  status: boolean;
  message: string;
  data: { list: User[]; offset: number; total: number };
}

export interface PostOrPutUserResponse {
  status: boolean;
  message: string;
  data: User;
}

export interface AmpConfiguration {
  customer_amp_config_id: number;
  amp_model: string;
  config_version: string;
  vendor_id: number;
  config_file_name: string;
  config_description: string;
  current_customer_version: boolean;
  customer: Customer;
}

export interface GetAmpConfigurationsResponse {
  status: boolean;
  message: string;
  data: AmpConfiguration[];
}

export interface McuFirmwareVersion {
  mcu_firmware_id: number;
  vendor_id: number;
  firmware_version: string;
  firmware_file_name: string;
  firmware_description: string;
  mcu_model: string;
  current_customer_version: boolean;
  customer: Customer;
}

export interface ModelConfigDropdownLists {
  amp_configurations: AmpConfiguration[];
  mcu_firmware_versions: McuFirmwareVersion[];
  chime_configurations: ChimeConfiguration[];
}

export interface GetModelConfigListResponse {
  status: boolean;
  message: string;
  data: ModelConfigDropdownLists;
}

export interface GetMcuFirmwareVersionsResponse {
  status: boolean;
  message: string;
  data: McuFirmwareVersion[];
}

export interface LoginResponse {
  status: boolean;
  message: string;
  data: {
    token: string;
    user: User;
  };
}

export interface CustomerModuleResponse {
  vendor_id: number;
  modules: CUSTOMER_MODULES[];
}

export interface ChimeConfiguration {
  customer_chime_config_id: number;
  chime_description: string;
  chime_version: string;
  vendor_id: number;
  file_name?: string;
  current_customer_version: boolean;
  chime_config_bin?: File | null;
  chime_audio: string;
  customer: Customer;
}

export interface ExpressDecodeUseCasesModel {
  hex: string;
  label: string;
  discrete: string[] | null;
  soundbar: string[] | null;
  hybrid: string[] | null;
  atmos: string[] | null;
}

export interface ExpressDecodeOptionsModel {
  [EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_2_0]: ExpressDecodeUseCasesModel | null;
  [EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_2_1]: ExpressDecodeUseCasesModel | null;
  [EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_ATMOS_2_1_2]: ExpressDecodeUseCasesModel | null;
  [EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_3_1]: ExpressDecodeUseCasesModel | null;
  [EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_ATMOS_3_1_2]: ExpressDecodeUseCasesModel | null;
  [EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_4_1]: ExpressDecodeUseCasesModel | null;
  [EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_ATMOS_4_1_2]: ExpressDecodeUseCasesModel | null;
  [EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_5_1]: ExpressDecodeUseCasesModel | null;
  [EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_DONGLE_HEIGHT_SURROUNDS_5_1_2]: ExpressDecodeUseCasesModel | null;
  [EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_DONGLE_HEIGHT_FRONTS_5_1_2]: ExpressDecodeUseCasesModel | null;
  [EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_7_1]: ExpressDecodeUseCasesModel | null;
}

export interface ExpressTxConfigurationsResponseModel {
  status: boolean;
  message: string;
  data: { actual_product: ExpressDecodeOptionsModel };
}

export interface CertAppVersion {
  version_no: string;
  file_path: string;
  is_latest_version: boolean;
  description: string;
  added_by: number;
  updated_by: number;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
}
