import { useCallback, useState } from "react";
import { Formik, FormikHelpers } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { ManageProductExpressTxConfigurations } from "../ManageProductExpressTxConfigurations";
import AddOrEditProductForm from "../../../components/AddOrEditProductForm";
import { ProductFormInitValues } from "../../../interfaces/fe.interfaces";
import { validateProduct } from "../../../utils/validationSchemas";
import { useFetch } from "../../../hooks/useFetch";
import { getModelsList, updateProduct } from "../../../api/config";
import { AddProductResponse, ProductInfo } from "../../../interfaces/be.interfaces";
import { http } from "../../../api/utils/http";
import { AxiosResponse } from "axios";
import omit from "lodash/omit";
import Spinner from "../../../components/Spinner";
import { initialContext, useCustomerContext } from "../../../hooks/useCustomerContext";

const UpdateProduct = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { context, updateContext } = useCustomerContext();

  const [formValues, setFormValues] = useState<ProductFormInitValues | null>(null);
  const [error, setError] = useState<string | null>(null);

  // fetch product info to populate form with existing values
  const { data: productInfoResponse } = useFetch<ProductInfo>({
    url: `${getModelsList}/${id}`,
    runOnMount: true,
    onSuccess: (response) => {
      const versionId = response?.data?.data?.customer_product_versions?.[0]?.version_id;
      // prepare form initial values to populate in form
      setFormValues({
        customer_product_id: response.data.data.customer_product_id,
        product_id: String(response.data.data.product_id),
        product_name: response.data.data.product_name,
        product_description: response.data.data.product_description,
        models_arr: response.data.data.customer_product_models.map((item) => ({
          ...item,
          amp_model: item?.customer_product_model_amp_config?.amp_config?.amp_model ?? "",
          mcu_model: item?.customer_product_model_mcu_firmware?.mcu_firmware?.mcu_model ?? "",
          customer_amp_config_id: String(item?.customer_product_model_amp_config?.customer_amp_config_id ?? ""),
          mcu_firmware_id: String(item?.customer_product_model_mcu_firmware?.mcu_firmware_id ?? ""),
          left_speaker_chime_config_id: String(item?.customer_product_model_chime_config?.left_speaker_chime_config_id) ?? "",
          right_speaker_chime_config_id:
            item?.use_case_info?.positions_required && item?.customer_product_model_chime_config?.right_speaker_chime_config_id
              ? String(item.customer_product_model_chime_config.right_speaker_chime_config_id)
              : "",
          positions_required: item?.use_case_info?.positions_required ?? false,
        })),
        vendor_id: String(response.data.data.vendor_id),
        product_type: response.data.data.product_type,
        will_config_amp: response.data.data.will_config_amp,
        will_update_mcu: response.data.data.will_update_mcu,
        map_assc_mode: response.data.data.map_assc_mode,
        tx_clock_follower: response.data.data.tx_clock_follower,
        sub_com_provided: response.data.data.sub_com_provided,
        wisa_server: response.data.data.wisa_server,
        notifications: response.data.data.notifications,
        rx_health: response.data.data.rx_health,
        volume_manage_in_apps: response.data.data.volume_manage_in_apps,
        software_buttons: response.data.data.software_buttons,
        small_datagrams: response.data.data.small_datagrams,
        large_datagrams: response.data.data.large_datagrams,
        energy_star: response.data.data.energy_star,
        will_config_chime: response.data.data.will_config_chime,
        time_out_duration: response.data.data.time_out_duration,
        region_code_arr: response.data.data.regions.map((item) => item.region_code),
        use_case_id: response.data.data.use_case_info?.use_case_id,
        other_region: response.data.data.other_region,
        other: !!response.data.data.other_region,
        version_id: versionId ? String(versionId) : null,
        sample_rate: response.data.data.tx_i2s_format.sample_rate,
        bits_per_frame_sample: response.data.data.tx_i2s_format.bits_per_frame_sample,
        pcm_format: response.data.data.tx_i2s_format.pcm_format,
      });
    },
  });

  /**
   * onSubmit callback for form to update product
   * @param values form values
   * @param resetForm formik resetForm function
   */
  const onSubmit = useCallback(
    (values: ProductFormInitValues, { resetForm }: FormikHelpers<ProductFormInitValues>) => {
      if (!productInfoResponse) return;

      // prepare payload for api call to update product
      const payload = {
        ...omit(values, ["other"]),
        vendor_id: Number(formValues?.vendor_id),
        product_id: Number(values.product_id),
        time_out_duration: values.time_out_duration ? +values.time_out_duration : null,
        version_id: values.version_id ? +values.version_id : null,
        pcm_format: values.pcm_format,
        bits_per_frame_sample: values.bits_per_frame_sample,
        sample_rate: values.sample_rate,

        // omit fields from models_arr before sending to backend
        models_arr: values.models_arr.map((item) => ({
          ...omit(item, [
            "customer_product_id",
            "customer_product_model_amp_config",
            "customer_product_model_chime_config",
            "customer_product_model_mcu_firmware",
            "is_enable",
            "amp_model",
            "mcu_model",
            "created_at",
            "deleted_at",
            "updated_at",
            "use_case_info",
            "positions_required",
          ]),
          customer_amp_config_id: values.will_config_amp && item.customer_amp_config_id ? item.customer_amp_config_id : null,
          left_speaker_chime_config_id:
            values.will_config_chime && item.left_speaker_chime_config_id ? +item.left_speaker_chime_config_id : null,
          right_speaker_chime_config_id:
            item.positions_required && values.will_config_chime && item.right_speaker_chime_config_id
              ? +item.right_speaker_chime_config_id
              : null,
          mcu_firmware_id: values.will_update_mcu && item.mcu_firmware_id ? item.mcu_firmware_id : null,
          size: item.size || null,
          crossover: +item.crossover,
        })),
      };

      // callback for success response
      const onSuccess = ({ data: response }: AxiosResponse<AddProductResponse>) => {
        if (response.status) {
          // reset form and navigate to manage product page

          // update context if product is updated
          if (formValues && Number(formValues?.product_id) === context?.productId) {
            const updatedContext = { ...context, productName: values.product_name, productId: +values.product_id };
            updateContext(updatedContext);

            // Check if model is updated or deleted with product
            if (formValues.models_arr.some((item) => item.model_id === context.modelId)) {
              const model = values.models_arr.find((item) => item.model_id === context.modelId);

              // If updated then update context with new model id
              if (model?.model_id) {
                updateContext({
                  ...updatedContext,
                  customerProductModelId: model?.customer_product_model_id ?? null,
                  modelId: model?.model_id,
                  modelDescription: model?.model_description,
                });
              }
              // If deleted then reset context
              else {
                updateContext(initialContext);
              }
            }
          }

          resetForm();
          navigate("/manage-product");
          setError(null);
        } else {
          setError(response?.message);
        }
      };

      // make api call to update product
      http.makePutRequest<AddProductResponse, { message: string }>(
        updateProduct,
        onSuccess,
        (e) => {
          setError(e?.response?.data?.message ?? null);
        },
        payload
      );
    },
    [formValues, productInfoResponse, context]
  );

  if (!formValues) return <Spinner />;

  return (
    <>
      {error ? <div className="errorAlert">{error}</div> : null}
      <Formik
        initialValues={{ ...formValues, vendor_id: String(productInfoResponse?.data?.customer?.vendor_id) || "" }}
        validationSchema={validateProduct}
        onSubmit={onSubmit}>
        {(props) => (
          <AddOrEditProductForm {...props} pageTitle="Update Product" customerName={productInfoResponse?.data?.customer?.customer_name} />
        )}
      </Formik>
      <ManageProductExpressTxConfigurations />
    </>
  );
};

export default UpdateProduct;
