import { ChangeEvent, Dispatch, ReactNode } from "react";
import styles from "./styles.module.scss";
import { CalibrationChannel } from "../../interfaces/be.interfaces";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { FLOATING_NUM_REGEX } from "../../utils/constants";

/**
 * @param name - Name of the header
 * @param key - Key of the data to be displayed in the table
 * @param backendKey - Key of the data to be updated in the backend
 * @param inputType - Type of input field to be displayed
 */
export interface TableHeader<T> {
  name: string;
  key?: keyof T;
  backendKey: string;
  inputType?: "number" | "hex";
}

/**
 * @param title - Title of the table
 * @param tableHeaders - Array of table headers
 * @param data - Array of data to be displayed in the table
 * @param setValues - Function to update the data where we are displaying input fields
 */
interface Props<T> {
  title: string;
  tableHeaders: TableHeader<T>[];
  data: T[];
  setValues: Dispatch<React.SetStateAction<T[] | null>>;
  isInValid?: boolean;
  handleTableDataChange?: (newData: T[]) => void;
}

/**
 * Generic table component for calibration tables
 * takes in title, table headers, data and a function to update the data
 */
const TableComponent = <T extends CalibrationChannel>({
  title,
  tableHeaders,
  data,
  setValues,
  isInValid,
  handleTableDataChange,
}: Props<T>) => {
  // Updates value in the data array based on the channel and center frequency
  const updateValue = (text: string, channel: string, centerFreq: string, backendKey: string) => {
    // Finds the index of the object in the data array based on the channel and center frequency
    const newData = data?.map((item) => {
      if (backendKey && item?.channel === channel && item?.center_freq === centerFreq) {
        return {
          ...item,
          [backendKey]: text,
        };
      }

      return item;
    });

    setValues(newData);
    if (handleTableDataChange) handleTableDataChange(newData);
  };

  /**
   * On change handler for hex input fields
   * @param e - input text change event
   * @param channel - channel of the row
   * @param center_freq - center frequency of the row
   * @param backendKey - key of the data to be updated
   */
  const handleChange = (e: ChangeEvent<HTMLInputElement>, channel: string, center_freq: string, backendKey?: string) => {
    const regEx = /^[0-9a-fA-F]+$/;
    const isHex = regEx.test(e?.target?.value);

    // If value is empty or is a valid hex value, update the value
    if ((isHex || e?.target?.value.length === 0) && backendKey) {
      updateValue(e?.target?.value, channel, center_freq, backendKey);
    }
  };

  /**
   * On change handler for number input fields
   * @param e - change event of the input field
   * @param channel - channel of the row
   * @param center_freq - center frequency of the row
   * @param oldValue - old value of the input field
   * @param backendKey - key of the data to be updated
   */
  const handleNumberChange = (
    e: ChangeEvent<HTMLInputElement>,
    channel: string,
    center_freq: string,
    oldValue: string,
    backendKey?: string
  ) => {
    const isPass = e.target.value.length ? FLOATING_NUM_REGEX.test(e.target.value) : true;

    // If value is empty or is a valid number, update the value
    if (isPass && backendKey) {
      updateValue(e.target.value, channel, center_freq, backendKey);
    }
  };

  return (
    <>
      <div>
        <div className={styles.tableTitle}>{title}</div>
        <div className={styles.tableResponsive}>
          <ReactTooltip anchorId="tooltipHeader" place="bottom" content="This field takes hex input" />
          <table className={styles.table}>
            <thead>
              <tr>
                {tableHeaders.map((header, index) => (
                  <th
                    key={index}
                    id={header.inputType === "hex" ? "tooltipHeader" : ""}
                    className={header.inputType === "hex" ? styles.tooltipHeader : ""}>
                    {header.name}
                    {header.inputType === "hex" ? <span>&nbsp;&#9432;</span> : null}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <>
                  <tr key={index}>
                    {tableHeaders?.map(({ key, backendKey, inputType }) => {
                      const currentValue = item?.[backendKey as keyof CalibrationChannel]?.toString();

                      return (
                        <>
                          {key ? (
                            <td key={backendKey}>
                              <p>{(item?.[key] as ReactNode) || "-"}</p>
                            </td>
                          ) : (
                            <td key={backendKey}>
                              {inputType === "hex" ? (
                                <input
                                  type="text"
                                  onChange={(e) => handleChange(e, item?.channel, item?.center_freq, backendKey)}
                                  value={item?.[backendKey as keyof CalibrationChannel]}
                                  className={isInValid && !currentValue.length ? styles.invalid : ""}
                                />
                              ) : (
                                <input
                                  type="text"
                                  onChange={(e) => handleNumberChange(e, item?.channel, item?.center_freq, currentValue, backendKey)}
                                  value={currentValue}
                                  className={isInValid && !currentValue.length ? styles.invalid : ""}
                                />
                              )}
                            </td>
                          )}
                        </>
                      );
                    })}
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default TableComponent;
