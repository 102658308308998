import { useCallback, useEffect, useState } from "react";
import { Formik, FormikHelpers } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import AddOrEditProductForm from "../../../components/AddOrEditProductForm";
import { ProductFormInitValues } from "../../../interfaces/fe.interfaces";
import { validateProduct } from "../../../utils/validationSchemas";
import { http } from "../../../api/utils/http";
import { addProduct } from "../../../api/config";
import { AddProductResponse, ModelTypes, ProductTypes } from "../../../interfaces/be.interfaces";
import { AxiosResponse } from "axios";
import omit from "lodash/omit";
import { TX_I2S_FORMAT_DEFAULT } from "../../../utils/constants";

// empty state for model object
export const emptyModelObj = {
  model_id: "",
  model_description: "",
  use_case_id: 0,
  crossover: "0",
  size: "",
  tx_rx: ModelTypes.TX,
};

// empty state for product form
const formValues: ProductFormInitValues = {
  product_id: "",
  vendor_id: "",
  product_name: "",
  product_description: "",
  models_arr: [],
  product_type: ProductTypes.WiSA_DS,
  will_config_amp: false,
  will_config_chime: false,
  will_update_mcu: false,
  map_assc_mode: false,
  tx_clock_follower: false,
  sub_com_provided: false,
  wisa_server: false,
  notifications: false,
  rx_health: false,
  volume_manage_in_apps: false,
  software_buttons: false,
  small_datagrams: false,
  large_datagrams: false,
  energy_star: false,
  time_out_duration: null,
  region_code_arr: [],
  use_case_id: 0,
  version_id: null,
  sample_rate: TX_I2S_FORMAT_DEFAULT.sample_rate,
  bits_per_frame_sample: TX_I2S_FORMAT_DEFAULT.bits_per_frame_sample,
  pcm_format: TX_I2S_FORMAT_DEFAULT.pcm_format,
};

const CreateProduct = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [error, setError] = useState<string | null>(null);

  /**
   * onSubmit callback for form
   * @param values form values
   * @param resetForm formik resetForm function
   */
  const onSubmit = useCallback((values: ProductFormInitValues, { resetForm }: FormikHelpers<ProductFormInitValues>) => {
    // prepare payload for api call
    // omit vendor_id and product_id from models_arr
    const payload = {
      ...omit(values, ["other"]),
      models_arr: values.models_arr.map((item) => ({
        ...omit(item, ["vendor_id", "product_id", "amp_model", "mcu_model", "positions_required"]),
        size: item.size || null,
        crossover: +item.crossover,
        customer_amp_config_id: values.will_config_amp && item.customer_amp_config_id ? item.customer_amp_config_id : null,
        mcu_firmware_id: values.will_update_mcu && item.mcu_firmware_id ? item.mcu_firmware_id : null,
        left_speaker_chime_config_id:
          values.will_config_chime && item.left_speaker_chime_config_id ? +item.left_speaker_chime_config_id : null,
        right_speaker_chime_config_id:
          item.positions_required && values.will_config_chime && item.right_speaker_chime_config_id
            ? +item.right_speaker_chime_config_id
            : null,
      })),
      product_id: +values.product_id,
      time_out_duration: values.time_out_duration ? +values.time_out_duration : null,
      version_id: values.version_id ? values.version_id : null,
      pcm_format: values.pcm_format,
      bits_per_frame_sample: values.bits_per_frame_sample,
      sample_rate: values.sample_rate,
    };

    /**
     * onSuccess callback for api call
     * @param response - response from api
     */
    const onSuccess = ({ data: response }: AxiosResponse<AddProductResponse>) => {
      if (response.status) {
        // reset form and navigate to product list page
        resetForm();
        navigate("/manage-product");
        setError(null);
      } else {
        setError(response?.message);
      }
    };

    // make post request to add product API
    http.makePostRequest<AddProductResponse, { message: string }>(
      addProduct,
      onSuccess,
      (e) => {
        setError(e?.response?.data?.message ?? null);
      },
      payload
    );
  }, []);

  useEffect(() => {
    // if vendor_id is not present in state, navigate to product list page
    if (!state?.vendor_id) {
      navigate("/manage-product");
    }
  }, []);

  return (
    <>
      {error ? <div className="errorAlert">{error}</div> : null}
      <Formik initialValues={{ ...formValues, vendor_id: state?.vendor_id || "" }} validationSchema={validateProduct} onSubmit={onSubmit}>
        {(props) => <AddOrEditProductForm {...props} pageTitle="Create Product" customerName={state?.customer_name} />}
      </Formik>
    </>
  );
};

export default CreateProduct;
