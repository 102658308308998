import { Formik, FormikHelpers } from "formik";
import { AmpConfigInitValue } from "../../interfaces/fe.interfaces";
import styles from "./styles.module.scss";
import Form from "./Form";
import { http } from "../../api/utils/http";
import { toast } from "react-toastify";
import { addAmpConfig } from "../../api/config";
import { AmpConfiguration, UpdateItemResponse } from "../../interfaces/be.interfaces";
import { AxiosResponse } from "axios";
import { validateAddOrEditAmpConfig } from "../../utils/validationSchemas";

interface AddAmpConfigDialogProps {
  callback: () => void | Promise<void>;
  onCancelClick: () => void | Promise<void>;
}

// Initial values for add amplifier configuration form
const initialValues: AmpConfigInitValue = {
  vendor_id: "",
  config_description: "",
  config_version: "",
  amp_model: "",
  amp_config_bin: null,
  current_customer_version: false,
};

/**
 * This component is responsible for adding new amplifier configuration
 */
const AddAmpConfigDialog = (props: AddAmpConfigDialogProps) => {
  // Handler for form submit to save amplifier configuration
  const handleAddAmpConfig = (values: AmpConfigInitValue, { setSubmitting }: FormikHelpers<AmpConfigInitValue>): void => {
    if (!values.amp_config_bin) return;

    const formData = new FormData();

    formData.append("config_description", values.config_description);
    formData.append("vendor_id", values.vendor_id);
    formData.append("config_version", values.config_version);
    formData.append("amp_model", values.amp_model);
    formData.append("amp_config_bin", values.amp_config_bin);
    formData.append("current_customer_version", values.current_customer_version ? "true" : "false");

    // Success callback for /add-amp-config API
    const onSuccess = ({ data: response }: AxiosResponse<UpdateItemResponse<AmpConfiguration>>) => {
      setSubmitting(false);
      if (response?.status) {
        // Display success toast and call parent callback to refresh the table
        toast.success(response?.message);
        props.callback();

        // Close the dialog
        props.onCancelClick();
      } else {
        toast.error(response?.message);
      }
    };

    // Calls /add-amp-config API
    http.makePostRequestWithFormData<UpdateItemResponse<AmpConfiguration>, { message: string }>(
      addAmpConfig,
      onSuccess,
      (error) => {
        setSubmitting(false);
        toast.error(error?.response?.data?.message);
      },
      formData
    );
  };

  return (
    <div className={styles.dialogContainer}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleAddAmpConfig}
        onReset={props.onCancelClick}
        validationSchema={validateAddOrEditAmpConfig}>
        {(props) => <Form {...props} />}
      </Formik>
    </div>
  );
};

export default AddAmpConfigDialog;
