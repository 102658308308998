import { useCallback, useEffect, useState, useMemo } from "react";
import { AxiosResponse } from "axios";
import styles from "./styles.module.scss";
import { Icons } from "../../../assets/images";
import { http } from "../../api/utils/http";
import { editInputSequence, getInputSequenceDetails, getSetCommandsInputs, getWisaCommands } from "../../api/config";
import {
  Customer,
  GetSetCommandsInputsResponse,
  GetWisaCommandDetails,
  GetWisaCommands,
  WisaCommandsData,
} from "../../interfaces/be.interfaces";
import { ReactSortable } from "react-sortablejs";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CommandTypes, CustomSequenceDetails } from "../../interfaces/fe.interfaces";
import pick from "lodash/pick";
import map from "lodash/map";
import { parseCustomSequence } from "../../utils/helpers";
import Checkbox from "../../components/Checkbox";
import { useFetch } from "../../hooks/useFetch";
import Modal from "../../components/Modal/Modal";
import Select from "../../components/Select";
import { Formik } from "formik";
import { setCommandsInputValidation } from "../../utils/validationSchemas";
import EditSeqDialog from "../../components/AddSeqDialog/EditSeq";

const UpdateCustomSequence = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [wisaCommandsList, setWisaCommandsList] = useState<WisaCommandsData | null>(null);
  const [searchText, setSearchText] = useState("");
  const [customSeqList, setCustomSeqList] = useState<CustomSequenceDetails[]>([]);
  const [selectedCommandList, setSelectedCommandList] = useState<CustomSequenceDetails[]>([]);
  const [title, setTitle] = useState("");
  const [vendorId, setVendorId] = useState<number | null>(null);
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showInputSelectModel, setShowInputSelectModel] = useState(false);

  const { data } = useFetch<GetSetCommandsInputsResponse>({
    url: getSetCommandsInputs,
    runOnMount: true,
  });

  /**
   * Preparing list of commands for which user should be asked to enter input
   * This is prepared based on backend's response in which we get list of fixed_inputs and dynamic_input
   */
  const inputAvailableFor = useMemo(() => {
    let keys: string[] = [];
    if (data?.data?.fixed_values) {
      keys = Object.keys(data?.data?.fixed_values);
    }

    if (data?.data?.dynamic_values) {
      keys = [...keys, ...Object.keys(data?.data?.dynamic_values)];
    }

    return keys;
  }, [data]);

  /**
   * Use this function to select checkbox for wisa commands item
   */
  const handleSelectCommand = (id: string, name: string, type: CommandTypes) => {
    if (selectedCommandList?.some((item) => id === item.sequences_id)) {
      setSelectedCommandList((prev) => prev?.filter((item) => item.sequences_id !== id));
    } else {
      setSelectedCommandList((prev) => [
        ...prev,
        {
          id,
          title: name,
          sequences_id: id,
          sequence_type: type,
          set_value: null,
          allow_update: inputAvailableFor.includes(name) ? false : null,
        },
      ]);
    }
  };

  /**
   * Using this function selected commands are transferred from left pane to selected commands list
   * This will only perform transfer locally and will not be saved to database
   * Use handleSaveCommand function to save them in database
   */
  const onCheckIconClick = () => {
    if (wisaCommandsList) {
      const cloneCustomSeqList = [...customSeqList, ...selectedCommandList];
      setSelectedCommandList([]);
      setCustomSeqList(cloneCustomSeqList);
    }
  };

  /**
   * Use this function to deselect commands checkbox
   * @param index at which we need to remove the selected item
   */
  const handleRemoveSelectedSequence = (index: number) => {
    setCustomSeqList(
      customSeqList?.filter((_, itemIndex) => {
        return index !== itemIndex;
      })
    );
  };

  /**
   * API call handler for updating custom sequence
   * @param values updated custom sequence list, defaults to state of customSeqList
   */
  const callUpdateApi = (values = customSeqList) => {
    /**
     * Success handler for update api call
     */
    const onSuccess = ({ data: response }: AxiosResponse<GetWisaCommandDetails>) => {
      // if successful response from then refetch values and show toast
      if (response?.status) {
        fetchCustomSeqList();
        toast.success(response?.message || "Sequences changes successfully.");
        navigate(`/update-custom-seq/${response.data?.seq_id}`);
        setShowInputSelectModel(false);
      } else {
        toast.error(response?.message || "Something went wrong.");
      }
      setLoading(false);
    };

    // Prepare payload with backend accepted fields
    const payload = {
      seq_order_arr: map(values, (item) => pick(item, ["sequences_id", "sequence_type", "set_value", "allow_update"])),
      vendor_id: vendorId,
    };

    // Make API call to update custom sequence
    if (!loading) {
      setLoading(true);
      http.makePutRequest<GetWisaCommandDetails, { message: string }>(
        editInputSequence + id,
        onSuccess,
        (error) => {
          setLoading(false);
          toast.error(error?.response?.data?.message || "Something went wrong.");
        },
        payload
      );
    }
  };

  /**
   * Use this function to save change of order in commands, add new commands or remove commands for custom sequence
   * Calls /edit-input-sequence with seq_order_arr payload
   */
  const handleSaveCommand = () => {
    if (customSeqList?.some((item) => inputAvailableFor.includes(item.title))) {
      setShowInputSelectModel(true);
      return;
    }

    callUpdateApi();
  };

  /**
   * Use this function to fetch list of wisa commands to populate on screen
   * Calls /get-wisa-commands API to fetch the list
   */
  const fetchCommandsList = useCallback((vendorId = customer?.vendor_id) => {
    const onSuccess = ({ data: response }: AxiosResponse<GetWisaCommands>) => {
      setWisaCommandsList(response.data);
    };

    const queryParams: Record<string, string> = {};

    if (vendorId) {
      queryParams.vendor_id = vendorId.toString();
    }

    http.makeGetRequest(
      getWisaCommands,
      onSuccess,
      (err) => {
        console.log("error", err);
      },
      queryParams
    );
  }, []);

  /**
   * Use this function to fetch list of commands that are already saved in database for particular sequence
   * Calls /get-input-sequence API with id to get details of custom sequence
   */
  const fetchCustomSeqList = useCallback(() => {
    setIsModalOpen(false);

    const onSuccess = ({ data: response }: AxiosResponse<GetWisaCommandDetails>) => {
      // parse backend response according to frontend keys
      const data = parseCustomSequence(
        response?.data?.seq_commands,
        {},
        {
          titlePath: "wisa_sub_seq.command_name",
          idPath: "wisa_sub_seq.command_code",
          type: CommandTypes.WISA_SUB_SEQ,
        },
        {
          titlePath: "wisa_commands.command_name",
          idPath: "wisa_commands.command_code",
          type: CommandTypes.WISA_COMMANDS,
        },
        {
          titlePath: "wisa_cust_commands.cust_command_name",
          idPath: "wisa_cust_commands.cust_command_id",
          type: CommandTypes.CUSTOM_COMMANDS,
        }
      );

      setTitle(response?.data?.seq_name);
      setCustomer(response?.data?.customer);
      setVendorId(response?.data?.customer?.vendor_id);
      setCustomSeqList(data);

      // Fetch list of commands and details of selected custom sequence
      fetchCommandsList(response?.data?.customer?.vendor_id);
    };

    http.makeGetRequest(getInputSequenceDetails + id, onSuccess, (err) => {
      console.log("error", err);
    });
  }, []);

  useEffect(() => {
    fetchCustomSeqList();
  }, []);

  // List of wisa commands filtered using search query text
  const searchedWisaCommands = useMemo(
    () =>
      wisaCommandsList
        ? wisaCommandsList?.wisa_commands?.filter((item) => item.command_name.toLowerCase().includes(searchText.toLowerCase()))
        : [],
    [wisaCommandsList?.wisa_commands, searchText]
  );

  // List of wisa sequences filtered using search query text
  const searchedWisaSeqCom = useMemo(
    () =>
      wisaCommandsList
        ? wisaCommandsList?.wisa_sub_seq?.filter((item) => item.command_name.toLowerCase().includes(searchText.toLowerCase()))
        : [],
    [wisaCommandsList?.wisa_sub_seq, searchText]
  );

  // List of wisa sequences filtered using search query text
  const searchedCustomCommand = useMemo(
    () =>
      wisaCommandsList
        ? wisaCommandsList?.custom_commands?.filter((item) => item.cust_command_name.toLowerCase().includes(searchText.toLowerCase()))
        : [],
    [wisaCommandsList?.wisa_sub_seq, searchText]
  );

  const onModalClose = useCallback(() => setIsModalOpen(false), []);

  const validationSchema = useMemo(() => {
    return setCommandsInputValidation(inputAvailableFor, data?.data?.dynamic_values || {});
  }, [inputAvailableFor, data]);

  return (
    <div className={styles.pageContainer}>
      <div className={styles.titleContainer}>
        <div>
          <p>Title: {title}</p>
          <p>Customer: {customer?.customer_name || "---"}</p>
        </div>
        <Icons.EditIcon onClick={() => setIsModalOpen(true)} />
      </div>
      <div className={`${styles.editorContainer} d-flex justify-content-between`}>
        <div className={styles.commandList}>
          <div className={styles.commandHeader}>
            <label>Commands</label>
            <input type="text" placeholder="Search Command" onChange={(e) => setSearchText(e.target.value)} />
            <div className={styles.searchCommand}>
              <button onClick={onCheckIconClick}>Add</button>
            </div>
          </div>
          <div className={styles.commandsContainer}>
            <div className={styles.tableResponsive}>
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th></th>
                    <th>Command Name</th>
                    <th>Command Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={3} className={styles.seperatorTd}>
                      WiSA Commands
                    </td>
                  </tr>
                  {searchedWisaCommands?.map((item) => (
                    <tr key={item.command_code}>
                      <td>
                        <Checkbox
                          label=""
                          name={item.command_code}
                          checked={selectedCommandList.some((sItem) => sItem.sequences_id === item?.command_code)}
                          onChange={() => handleSelectCommand(item?.command_code, item?.command_name, CommandTypes.WISA_COMMANDS)}
                        />
                      </td>
                      <td>{item.command_name}</td>
                      <td>{item.description}</td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={3} className={styles.seperatorTd}>
                      WiSA Sub Sequences
                    </td>
                  </tr>
                  {searchedWisaSeqCom?.map((item) => (
                    <tr key={item.command_code}>
                      <td>
                        <Checkbox
                          label=""
                          name={item.command_code}
                          checked={selectedCommandList.some((sItem) => sItem.sequences_id === item?.command_code)}
                          onChange={() => handleSelectCommand(item?.command_code, item?.command_name, CommandTypes.WISA_SUB_SEQ)}
                        />
                      </td>
                      <td>{item.command_name}</td>
                      <td>{item.description}</td>
                    </tr>
                  ))}
                  {searchedCustomCommand.length > 0 && (
                    <tr>
                      <td colSpan={3} className={styles.seperatorTd}>
                        Custom Commands
                      </td>
                    </tr>
                  )}
                  {searchedCustomCommand?.map((item) => (
                    <tr key={item.cust_command_id}>
                      <td>
                        <Checkbox
                          label=""
                          name={item.cust_command_name}
                          checked={selectedCommandList.some((sItem) => sItem.sequences_id === item?.cust_command_id)}
                          onChange={() => handleSelectCommand(item?.cust_command_id, item?.cust_command_name, CommandTypes.CUSTOM_COMMANDS)}
                        />
                      </td>
                      <td>{item.cust_command_name}</td>
                      <td>{item.cust_command_description}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className={styles.seqContainer}>
          <div className={styles.seqHeader}>
            <label>Sequence</label>
            <div className={styles.searchCommand}>
              <button onClick={handleSaveCommand} disabled={loading}>
                <Icons.SaveIcon />
              </button>
            </div>
          </div>

          <ReactSortable
            draggable=".drag"
            filter={".exclude"}
            handle=".handle"
            list={customSeqList}
            setList={setCustomSeqList}
            key="select"
            group="sortableGroup"
            className={styles?.sortableContainer}
            animation={200}>
            {customSeqList?.map((item, index: number) => (
              <div
                className={`${styles.seqItem} ${index ? "drag" : ""}`}
                key={`item?.command_code-${index}` ?? `item?.wisa_seq_com_id-${index}`}
                id={String(index)}>
                <p>{item?.title}</p>
                {index ? (
                  <div className={styles.seqActions}>
                    <Icons.TrashIcon onClick={() => handleRemoveSelectedSequence(index)} />
                    <Icons.MoveIcon className={index ? "handle" : "exclude"} />
                  </div>
                ) : null}
              </div>
            ))}
          </ReactSortable>
        </div>
      </div>
      {isModalOpen && (
        <EditSeqDialog
          callback={fetchCustomSeqList}
          initialValues={{ seq_name: title, vendor_id: vendorId }}
          onCancelClick={onModalClose}
        />
      )}
      <Modal
        isModalOpen={showInputSelectModel}
        onModalClose={() => setShowInputSelectModel(false)}
        onModalOpen={() => setShowInputSelectModel(true)}>
        <div className={styles.inputValuesModel}>
          <p className={styles.heading}>Add set values</p>
          <Formik
            initialValues={customSeqList}
            onSubmit={(values) => {
              setCustomSeqList(values);
              callUpdateApi(values);
            }}
            validationSchema={validationSchema}>
            {({ setFieldValue, handleSubmit, values, errors, touched, resetForm }) => (
              <form noValidate onSubmit={handleSubmit}>
                <div className={styles.commandListContainer}>
                  {customSeqList?.map((item, index) => {
                    const val = values?.[index];
                    const err = errors?.[index];
                    const touch = touched?.[index];

                    return (
                      <div key={item.id} className={styles.commandItem}>
                        <p>{item.title}</p>
                        {inputAvailableFor?.includes(item.title) ? (
                          <>
                            <Checkbox
                              label="Allow update"
                              name={`[${index}].allow_update`}
                              checked={!!val?.allow_update}
                              onChange={() => {
                                setFieldValue(`[${index}].allow_update`, !val?.allow_update);
                              }}
                            />
                            {data?.data?.fixed_values?.[item.title] ? (
                              <div className={styles.customInput}>
                                <Select
                                  options={data?.data?.fixed_values?.[item.title] || []}
                                  selectProps={{
                                    value: val?.set_value || "",
                                    onChange: (e) => {
                                      setFieldValue(`[${index}].set_value`, e.target.value);
                                    },
                                  }}
                                />
                                {err?.set_value && touch?.set_value ? <div className={styles.error}>{err?.set_value}</div> : null}
                              </div>
                            ) : (
                              <div className={styles.customInput}>
                                <input
                                  type="text"
                                  value={val.set_value ?? ""}
                                  placeholder={data?.data?.dynamic_values?.[item.title].prefix}
                                  onChange={(e) => {
                                    setFieldValue(`[${index}].set_value`, e.target.value);
                                  }}
                                  className={styles.dynamicInput}
                                />
                                {err?.set_value && touch?.set_value ? <div className={styles.error}>{err?.set_value}</div> : null}
                              </div>
                            )}
                          </>
                        ) : (
                          <p>-</p>
                        )}
                      </div>
                    );
                  })}
                </div>
                <div className={styles.actionButtons}>
                  <button type="submit">Submit</button>

                  <button
                    type="button"
                    onClick={() => {
                      resetForm();
                      setShowInputSelectModel(false);
                    }}>
                    Cancel
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal>
    </div>
  );
};

export default UpdateCustomSequence;
