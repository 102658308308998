import * as Yup from "yup";
import { ALPHA_NUM_DASH_UNDERSCORE_REGEX, DB_MAX_SAFE_INTEGER } from "./constants";
import { DynamicInputs, ModelTypes } from "../interfaces/be.interfaces";
import { CopyModelData } from "../interfaces/fe.interfaces";

export const validateCustomCommandForm = Yup.object().shape(
  {
    cust_command_name: Yup.string()
      .trim()
      .required("Please enter command name")
      .max(255, "command name can't be more than 255 characters")
      .nullable()
      .matches(ALPHA_NUM_DASH_UNDERSCORE_REGEX, "Special characters are not allowed"),
    cust_command_description: Yup.string().trim().required("Please enter description").nullable(),
    result_type: Yup.string().required("Please select result type").nullable(),
    passing_values: Yup.array()
      .of(Yup.string())
      .test("is-unique", "Passing values should be unique (case insensitive)", (values) => {
        return !!values && new Set(values?.map((item) => item?.toLowerCase())).size === values.length;
      })
      .test("is-not-between-min-max", "Passing values should not be between min and max values", (values, { parent }) => {
        return !values?.some((item) => Number(item) <= parent?.max_value && Number(item) >= parent?.min_value);
      }),
    min_value: Yup.number()
      .max(DB_MAX_SAFE_INTEGER, `Max value can be ${DB_MAX_SAFE_INTEGER}`)
      .when("max_value", {
        is: (max_value: string) => Boolean(max_value),
        then: Yup.number().required("Please enter min value").nullable(),
        otherwise: Yup.number().nullable(),
      }),
    max_value: Yup.number()
      .max(DB_MAX_SAFE_INTEGER, `Max value can be ${DB_MAX_SAFE_INTEGER}`)
      .when("min_value", {
        is: (min_value: string) => Boolean(min_value),
        then: Yup.number().required("Please enter max value").min(Yup.ref("min_value"), "Enter value greater than min value").nullable(),
        otherwise: Yup.number().nullable(),
      }),
  },
  [["min_value", "max_value"]]
);

export const validateCustomer = Yup.object().shape({
  vendor_id: Yup.number()
    .min(1)
    .max(DB_MAX_SAFE_INTEGER, `Max value can be ${DB_MAX_SAFE_INTEGER}`)
    .integer("Please enter valid number")
    .required("Please enter vendor ID")
    .nullable(),
  customer_name: Yup.string()
    .trim()
    .max(255, "Vendor name can be maximum 255 characters")
    .required("Please enter vendor name")
    .nullable()
    .matches(ALPHA_NUM_DASH_UNDERSCORE_REGEX, "Special characters are not allowed"),
  manufacturer: Yup.boolean().required("Please select manufacturer").nullable(),
  customer_info: Yup.string().required("Please select customer info").nullable(),
});

export const validateAddOrEditUser = Yup.object().shape({
  vendor_id: Yup.number()
    .required("Please select customer")
    .typeError("Please select valid customer")
    .test("role", "Please select valid role", function () {
      if (!this.parent.role?.is_wisa_role) return true;
      return this.parent?.customer?.is_wisa && this.parent?.role?.is_wisa_role;
    }),
  email: Yup.string()
    .email("Please enter valid email")
    .matches(/^(?!.*@[^,]*,)/, "Please enter valid email")
    .trim()
    .required("Please enter user email"),
  name: Yup.string().required("Please enter user name"),
  role_id: Yup.string().required("Please select role"),
  password: Yup.string()
    .nullable()
    .when("isPasswordFieldVisible", {
      is: true,
      then: Yup.string().min(8).required("Please enter password"),
      otherwise: Yup.string().nullable(),
    })
    .label("Password"),
  confirmPassword: Yup.string().when("isPasswordFieldVisible", {
    is: true,
    then: Yup.string()
      .test("passwords-match", "Password and confirm password must match", function (value) {
        return this.parent.password === value;
      })
      .required("Please enter password again"),
    otherwise: Yup.string().nullable(),
  }),
});

export const validateModel = Yup.object().shape(
  {
    model_id: Yup.string().required("Please enter model ID").max(16, "Maximum length is 16"),
    model_description: Yup.string().required("Please enter model description").max(32, "Maximum length is 32"),
    use_case_id: Yup.string().required("Please enter use case"),
    crossover: Yup.number()
      .required("Please enter crossover")
      .typeError("Crossover must be a number")
      .when("tx_rx", {
        is: ModelTypes.TX,
        then: Yup.number()
          .required("Please enter crossover")
          .typeError("Crossover must be a number")
          .min(0, "Please select valid crossover")
          .max(0, "Please select valid crossover"),
        otherwise: Yup.number().min(100, "Minimum value is 100").max(300, "Maximum value is 300").integer("Crossover must be an integer"),
      }),
    size: Yup.string()
      .when("tx_rx", {
        is: ModelTypes.RX,
        then: Yup.string().required("Please enter size").typeError("Please select valid size"),
        otherwise: Yup.string().nullable(),
      })
      .typeError("Please select valid size"),
    tx_rx: Yup.string().required("Please select model type"),
    customer_amp_config_id: Yup.number().when("amp_model", {
      is: (amp_model: string | null) => !amp_model,
      then: Yup.number().nullable(),
      otherwise: Yup.number().required("Please select a config version").typeError("Please select valid config version"),
    }),
    mcu_firmware_id: Yup.number().when("mcu_model", {
      is: (mcu_model: string | null) => !mcu_model,
      then: Yup.number().nullable(),
      otherwise: Yup.number().required("Please select a firmware version").typeError("Please select valid firmware version"),
    }),
    left_speaker_chime_config_id: Yup.string().when("right_speaker_chime_config_id", (rightSpeakerChimeConfigId) => {
      return !rightSpeakerChimeConfigId
        ? Yup.string().nullable()
        : Yup.string().trim().required("Please select a chime config").typeError("Please select valid chime config");
    }),
    right_speaker_chime_config_id: Yup.string().when(
      ["left_speaker_chime_config_id", "positions_required"],
      (leftSpeakerChimeConfigId, positionsRequired) => {
        return !(leftSpeakerChimeConfigId && positionsRequired)
          ? Yup.string().nullable()
          : Yup.string().trim().required("Please select a chime config").typeError("Please select valid chime config");
      }
    ),
  },
  [["left_speaker_chime_config_id", "right_speaker_chime_config_id"]]
);

export const validateProduct = Yup.object().shape({
  product_id: Yup.number()
    .integer()
    .max(DB_MAX_SAFE_INTEGER, `Max value can be ${DB_MAX_SAFE_INTEGER}`)
    .min(1)
    .required("Please enter product ID")
    .typeError("ID must be a number"),
  vendor_id: Yup.number().min(1).required("Please enter vender ID").typeError("ID must be a number"),
  product_name: Yup.string()
    .required("Please enter product name")
    .matches(ALPHA_NUM_DASH_UNDERSCORE_REGEX, "Special characters are not allowed"),
  product_description: Yup.string().required("Please enter product description"),
  models_arr: Yup.array().of(validateModel),
  product_type: Yup.string().required("Please select product type"),
  time_out_duration: Yup.number().when("energy_star", {
    is: true,
    then: Yup.number().integer().required("Please enter time out duration").typeError("Time out duration must be a number"),
    otherwise: Yup.number().typeError("Time out duration must be a number").nullable(),
  }),
  use_case_id: Yup.string().required("Please select use case"),
  other_region: Yup.string().when("other", {
    is: true,
    then: Yup.string().required("Please enter region").typeError("Please enter region"),
    otherwise: Yup.string().nullable(),
  }),
});

export const MAC_ADDRESS_REGEX = /^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$/;

export const validateWisaFirmwareUploadForm = Yup.object().shape({
  version: Yup.string()
    .trim()
    .required("Please enter a version")
    .test("is-correct-version-format", "Enter a valid version (e.g. 1.0.0)", (version) => {
      const versionRegex = /^([0-9]*\.){2}.?([0-9]{1,})$/;
      return !!version?.match(versionRegex)?.length;
    }),
  memory_location: Yup.string()
    .trim()
    .required("Please enter a memeory location (e.g. 0x0)")
    .test("is-hex-format", "Enter a valid memory location (e.g. 0x0)", (memoryLocation) => {
      const memoryLocationRegex = /^0[xX][0-9a-fA-F]+$/;
      return !!memoryLocation?.match(memoryLocationRegex)?.length;
    }),
  firmware_bin: Yup.mixed().when("file_name", {
    is: (file_name: string | undefined) => !file_name,
    then: (schema) =>
      schema.required("Please upload a bin file").test("is-bin-file", "Not a valid bin file", (value) => {
        const extension = value?.name?.split?.(".").pop();
        return extension === "bin";
      }),
    otherwise: Yup.mixed().nullable(),
  }),
  wisa_product: Yup.string().required("Please pick a WiSA product"),
});

export const validateMcuFirmwareUploadForm = Yup.object().shape({
  firmware_version: Yup.string()
    .trim()
    .required("Please enter a version")
    .test("is-correct-version-format", "Enter a valid version (e.g. 1.0.0)", (version) => {
      const versionRegex = /^([0-9]*\.){2}.?([0-9]{1,})$/;
      return !!version?.match(versionRegex)?.length;
    }),
  mcu_firmware_bin: Yup.mixed().when("firmware_file_name", {
    is: (file_name: string | undefined) => !file_name,
    then: (schema) =>
      schema.required("Please upload a bin file").test("is-bin-file", "Not a valid bin file", (value) => {
        const extension = value?.name?.split?.(".").pop();
        return extension === "bin";
      }),
    otherwise: Yup.mixed().nullable(),
  }),
  firmware_description: Yup.string().required("Please enter firmware description"),
  mcu_model: Yup.string().required("Please enter mcu model"),
  vendor_id: Yup.string().required("Please select vendor"),
});

export const LoginValidation = Yup.object().shape({
  email: Yup.string()
    .email("Please enter valid email")
    .matches(/^(?!.*@[^,]*,)/, "Please enter valid email")
    .trim()
    .required("Please enter user email"),
  password: Yup.string().required("Please enter password"),
});

export const AddMacValidationSchema = Yup.object().shape({
  wisa_mac_address: Yup.string()
    .required("Please enter MAC address")
    .length(17, "MAC address must be 17 characters")
    .matches(MAC_ADDRESS_REGEX, "Please enter valid MAC address in format of 00:00:00:00:00:00"),
});

export const CustomSequenceValidationSchema = Yup.object().shape({
  seq_name: Yup.string()
    .required("Please enter sequence name")
    .max(255, "Sequence name can't be more than 255 characters")
    .matches(ALPHA_NUM_DASH_UNDERSCORE_REGEX, "Special characters are not allowed"),
});

export const setCommandsInputValidation = (possibleSetCommands: string[], dynamicInputs: DynamicInputs) =>
  Yup.array().of(
    Yup.object().shape({
      set_value: Yup.string()
        .when("title", {
          is: (title: string) => possibleSetCommands.includes(title),
          then: Yup.string().required("Please enter set value").nullable(),
          otherwise: Yup.string().nullable(),
        })
        .test("max-length", "", (value, context): Yup.ValidationError | boolean => {
          const { title } = context.parent;
          const { length } = dynamicInputs[title] ?? {};

          if (length && value && value.length !== length) {
            return context.createError({ message: `Value must be ${length} characters long` });
          } else {
            return true;
          }
        }),
    })
  );

export const validateCopyProduct = (vendorId: number) =>
  Yup.object().shape({
    new_vendor_id: Yup.number().required("Please select customer").nullable(),
    new_product_id: Yup.number().when("new_vendor_id", {
      is: (new_vendor_id: number) => new_vendor_id === vendorId,
      then: Yup.number()
        .integer()
        .max(DB_MAX_SAFE_INTEGER, `Max value can be ${DB_MAX_SAFE_INTEGER}`)
        .min(1)
        .required("Please enter product ID")
        .nullable(),
      otherwise: Yup.number().nullable().integer().max(DB_MAX_SAFE_INTEGER, `Max value can be ${DB_MAX_SAFE_INTEGER}`),
    }),
  });

export const validateCopyModel = (copyModelInfo: CopyModelData) =>
  Yup.object().shape({
    new_vendor_id: Yup.number().required("Please select customer").nullable(),
    new_product_id: Yup.number().required("Please select product").nullable(),
    model_id: Yup.string()
      .trim()
      .when(["new_vendor_id", "new_product_id"], {
        is: (new_vendor_id: number, new_product_id: number) =>
          new_vendor_id === copyModelInfo.vendorId && new_product_id === copyModelInfo.productId,
        then: Yup.string().trim().required("Please enter model ID").max(16, "Maximum length is 16").nullable(),
        otherwise: Yup.string().trim().nullable(),
      }),
  });

export const validateAddOrEditAmpConfig = Yup.object().shape({
  amp_model: Yup.string().required("Please enter amp model"),
  config_description: Yup.string().required("Please enter config description"),
  config_version: Yup.string()
    .trim()
    .required("Please enter config version")
    .test("is-correct-version-format", "Enter a valid version (e.g. 1.0.0)", (version) => {
      const versionRegex = /^([0-9]*\.){2}.?([0-9]{1,})$/;
      return !!version?.match(versionRegex)?.length;
    }),
  amp_config_bin: Yup.mixed().when("config_file_name", {
    is: (file_name: string | undefined) => !file_name,
    then: (schema) =>
      schema.required("Please upload a bin file").test("is-bin-file", "Not a valid bin file", (value) => {
        const extension = value?.name?.split?.(".").pop();
        return extension === "bin";
      }),
    otherwise: Yup.mixed().nullable(),
  }),
  vendor_id: Yup.number().required("Please select customer").typeError("Please select valid customer"),
});

export const validateAddOrEditChimeConfig = Yup.object().shape({
  chime_audio: Yup.string().required("Please enter chime audio"),
  chime_description: Yup.string().required("Please enter chime description"),
  chime_version: Yup.string()
    .trim()
    .required("Please enter chime version")
    .test("is-correct-version-format", "Enter a valid version (e.g. 1.0.0)", (version) => {
      const versionRegex = /^([0-9]*\.){2}.?([0-9]{1,})$/;
      return !!version?.match(versionRegex)?.length;
    }),
  chime_config_bin: Yup.mixed().when("file_name", {
    is: (file_name: string | undefined) => !file_name,
    then: (schema) =>
      schema.required("Please upload a bin file").test("is-bin-file", "Not a valid bin file", (value) => {
        const extension = value?.name?.split?.(".").pop();
        return extension === "wav";
      }),
    otherwise: Yup.mixed().nullable(),
  }),
  vendor_id: Yup.number().required("Please select customer").typeError("Please select valid customer"),
});

export const validateManageProductExpressTxConfigurations = Yup.object().shape({
  dev_type: Yup.string().required("Please select a device type"),
  local_speakers: Yup.array(Yup.string())
    .required("Please select any local speakers your system supports")
    .test(
      "is-local_speaker-list-valid",
      "Please select supported local speakers",
      (local_speaker_list) => local_speaker_list?.length !== 0 ?? false
    ),
  operating_system: Yup.string().required("Please select an operating system"),
  os_versions: Yup.array(Yup.string())
    .required("Please select OS versions supported")
    .test("is-os-list-valid", "Please select supported OS(s)", (os_list) => os_list?.length !== 0 ?? false),
  os_other_text: Yup.string().when("os_versions", {
    is: (os_versions: string[]) => os_versions?.includes("os_other") ?? false,
    then: Yup.string().required("Please enter a supported OS").max(16, "Maximum length is 16").nullable(),
    otherwise: Yup.string().trim().nullable(),
  }),
  decode_options: Yup.array(Yup.string())
    .required("Please supported decode options")
    .test("is-decode-options-valid", "Please select supported decode option(s)", (decode_options) => decode_options?.length !== 0 ?? false),
  audio_hal: Yup.string().required("Please select an audio HAL"),
  audio_hal_other_text: Yup.string().when("audio_hal", {
    is: (audio_hal: string) => audio_hal === "audio_hal_other",
    then: Yup.string().required("Please enter a supported audio HAL").max(16, "Maximum length is 16").nullable(),
    otherwise: Yup.string().trim().nullable(),
  }),
  binder: Yup.string()
    .required("Please select if a binder is supported")
    .test("is-boolean-string", "Is a binder required: true or false?", (hasBinder) => hasBinder === "true" || hasBinder === "false"),
  rx_configurations: Yup.array(Yup.string())
    .required("Please select supported RX Configurations")
    .test("is-rx-configs-valid", "Please select supported RX configurations", (rx_list) => rx_list?.length !== 0 ?? false),
  sync_requirements: Yup.string().required("Please select a sync requirement"),
  tsf_available: Yup.string()
    .required("Please select if TSF is supported")
    .test("is-boolean-string", "Is TSF supported: true or false?", (hasTSF) => hasTSF === "true" || hasTSF === "false"),
});

export const validateAppUploadForm = Yup.object().shape({
  version_no: Yup.string()
    .required()
    .test("is-correct-version-format", "Enter a valid version (e.g. 1.0.0)", (version) => {
      const versionRegex = /^([0-9]*\.){2}.?([0-9]{1,})$/;
      return !!version?.match(versionRegex)?.length;
    }),
  description: Yup.string().required(),
  file: Yup.mixed()
    .required("Please upload a APK file")
    .test("is-apk-file", "Not a valid APK file", (value) => {
      console.log("avlues", value);
      const extension = value?.name?.split?.(".").pop();
      return extension === "apk";
    }),
});
