import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { Formik, FormikHelpers } from "formik";
import isNil from "lodash/isNil";

import Form from "./Form";
import { editInputCommand } from "../../api/config";
import { http } from "../../api/utils/http";
import { validateCustomCommandForm } from "../../utils/validationSchemas";
import { InputCommandData } from "../../interfaces/be.interfaces";
import { CustomCommandFormValues, CustomCommandResultType } from "../../interfaces/fe.interfaces";

import styles from "./styles.module.scss";

interface EditCommandDialogProps {
  callback: () => void | Promise<void>;
  onCancelClick: () => void | Promise<void>;
  initialValues: CustomCommandFormValues;
  id: string;
}

// DialogBox component for edit command
const EditCommandDialog = (props: EditCommandDialogProps) => {
  /**
   * Submit handler for edit command form
   * Calls /edit-input-command API with cust_command_name, cust_command_description and result_type payload
   */
  const handleEditCommand = (values: CustomCommandFormValues, { setSubmitting }: FormikHelpers<CustomCommandFormValues>): void => {
    const payload: CustomCommandFormValues = {
      cust_command_name: values.cust_command_name,
      cust_command_description: values.cust_command_description,
      result_type: values.result_type,
      move_on: values.move_on,
      vendor_id: values.vendor_id,
      passing_values: values.passing_values,
      min_value: values.result_type === CustomCommandResultType.NUMBER && !isNil(values?.min_value) ? +values.min_value : null,
      max_value: values.result_type === CustomCommandResultType.NUMBER && !isNil(values?.max_value) ? +values.max_value : null,
    };

    // Success callback for /edit-input-command API
    const onSuccess = ({ data: response }: AxiosResponse<InputCommandData>) => {
      setSubmitting(false);
      if (response?.status) {
        toast.success(response?.message || "Custom command updated successfully");
        props.callback();
        props.onCancelClick();
      } else {
        toast.error(response?.message);
      }
    };

    http.makePutRequest<InputCommandData, { message: string }>(
      editInputCommand + props?.id,
      onSuccess,
      (error) => {
        setSubmitting(false);
        toast.error(error?.response?.data?.message);
        console.log("error", error);
      },
      payload
    );
  };

  return (
    <>
      <div className={styles.dialogContainer}>
        <Formik
          enableReinitialize={true}
          initialValues={props?.initialValues}
          onSubmit={handleEditCommand}
          onReset={props.onCancelClick}
          validationSchema={validateCustomCommandForm}>
          {(props) => <Form {...props} enableReinitialize={true} />}
        </Formik>
      </div>
    </>
  );
};

export default EditCommandDialog;
