import { useMemo } from "react";
import { FormikProps } from "formik";

import { CopyModelData, CopyModelFormValues } from "../../interfaces/fe.interfaces";
import { GetProductListResponse } from "../../interfaces/be.interfaces";
import { useFetch } from "../../hooks/useFetch";
import { getProductsList } from "../../api/config";

import styles from "../CopyProduct/styles.module.scss";
import Select from "../Select";
import { handleAlphaNumInputChange } from "../../utils/helpers";

interface CopyModelFormProps extends FormikProps<CopyModelFormValues> {
  copyModelInfo: CopyModelData;
  handleClose: () => void;
}

// Form of copy model
const CopyModelForm = ({
  values,
  setFieldValue,
  handleSubmit,
  errors,
  touched,
  handleClose,
  copyModelInfo,
  isSubmitting,
}: CopyModelFormProps) => {
  const { data } = useFetch<GetProductListResponse>({
    url: getProductsList,
    runOnMount: true,
  });

  // Prepare vendor list for select box
  const vendorList = useMemo(() => {
    if (!data?.data) return [];
    return data?.data?.list?.map((item) => ({
      label: item.customer_name,
      value: item.vendor_id,
    }));
  }, [data]);

  // We only show products that are same type as the product of model we are copying
  const productList = useMemo(() => {
    const selectedVendor = data?.data.list.find((item) => item.vendor_id === values.new_vendor_id);
    if (!selectedVendor) return [];

    // Prepare list of products for select box
    return selectedVendor.customer_products
      ?.filter((item) => item.product_type === copyModelInfo.productType)
      .map((item) => ({
        label: `${item.product_name} (${item.product_id})`,
        value: item.product_id,
      }));
  }, [data, values.new_vendor_id, copyModelInfo]);

  return (
    <form noValidate onSubmit={handleSubmit}>
      <div className={styles.fieldContainer}>
        <p>1. Select customer to copy this product for</p>
        <Select
          selectProps={{
            name: "new_vendor_id",
            value: values.new_vendor_id || "",
            placeholder: "Select Vendor",
            onChange: (event) => {
              setFieldValue("new_vendor_id", +event.target.value);
              setFieldValue("new_product_id", null);
            },
          }}
          options={vendorList}
        />
        {errors?.new_vendor_id && touched.new_vendor_id && <p className={styles.error}>{errors?.new_vendor_id}</p>}
      </div>
      <div className={styles.fieldContainer}>
        <p>2. Select product to copy this model for</p>
        <Select
          selectProps={{
            name: "new_product_id",
            value: values.new_product_id || "",
            placeholder: "Select Product",
            onChange: (event) => setFieldValue("new_product_id", +event.target.value),
            disabled: !values.new_vendor_id,
          }}
          options={productList}
        />
        {errors?.new_product_id && touched.new_product_id && <p className={styles.error}>{errors?.new_product_id}</p>}
      </div>
      <div className={styles.fieldContainer}>
        <p>3. New model id</p>
        <input
          type="text"
          name="model_id"
          value={values.model_id || ""}
          onChange={(e) => handleAlphaNumInputChange(e, setFieldValue)}
          placeholder="Enter new model id"
        />
        {errors?.model_id && touched.model_id && <p className={styles.error}>{errors?.model_id}</p>}
      </div>
      <div className={styles.buttonsContainer}>
        <button type="submit" disabled={isSubmitting}>
          Copy
        </button>
        <button onClick={handleClose}>Close</button>
      </div>
    </form>
  );
};

export default CopyModelForm;
