import React, { useContext, useMemo, useRef } from "react";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { calculateCalibrationValue, getCalibrationValue, getWisaMacForAssignment, saveCalibrationTest } from "../../api/config";
import { http } from "../../api/utils/http";
import TableComponent from "../../components/CalibrationTable";
import {
  CalibrationChannelData,
  InputTxPo,
  InputRxGain,
  InputEVM,
  InputPPM,
  CalcResults,
  InputTestResults,
  GetWiSAMacResponse,
  COMMAND_TYPE,
  UpdateItemResponse,
} from "../../interfaces/be.interfaces";
import { AssignVerifyPayload, CALIBRATION_SUBMIT_TYPES } from "../../interfaces/fe.interfaces";
import {
  TXPO_TABLE_HEADERS,
  RXGAIN_TABLE_HEADERS,
  EVM_TABLE_HEADERS,
  PPM_TABLE_HEADERS,
  TEST_RESULTS_TABLE_HEADERS,
} from "../../utils/constants";
import { checkIfAllKeysAreNotEmpty, delay, formatDeviceResponse, isMacValid, parseValueToNumber } from "../../utils/helpers";
import styles from "./styles.module.scss";
import { useCustomerContext } from "../../hooks/useCustomerContext";
import { useConnection } from "../../hooks/useConnection";
import { postWisaMacAssignVerify } from "../../api/config";
import ConnectDialog from "../../components/ConnectDialog";
import { AppContext } from "../../context/AppContext";

const initialTouchedValues = {
  txPo: false,
  rxGain: false,
  evm: false,
  ppm: false,
  testResults: false,
  mac: false,
};

const Calibration = () => {
  const calDataRef = useRef<HTMLHeadingElement>(null);
  const calcRef = useRef<HTMLHeadingElement>(null);
  const testsRef = useRef<HTMLHeadingElement>(null);
  const { context } = useCustomerContext();
  const appContext = useContext(AppContext);

  const [txPoInput, setTxPoInput] = React.useState<InputTxPo[] | null>(null);
  const [rxGainInput, setRxGainInput] = React.useState<InputRxGain[] | null>(null);
  const [evmInput, setEvmInput] = React.useState<InputEVM[] | null>(null);
  const [ppmInput, setPPMInput] = React.useState<InputPPM[] | null>(null);
  const [testResultsInput, setTestResultsInput] = React.useState<InputTestResults[] | null>(null);
  const [fetchError, setFetchError] = React.useState<string>();
  const [calcResults, setCalcResults] = React.useState<CalcResults | null>(null);
  const [mac, setMac] = React.useState<string>("");
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [touched, setTouched] = React.useState(initialTouchedValues);
  const [isAlreadySubmitted, setIsAlreadySubmitted] = React.useState(false);
  const [isMacFetching, setIsMacFetching] = React.useState(false);

  const { connected, handleConnect, connecting, setConnecting, setConnected } = useConnection();

  /**
   * Check if all the values are valid
   * Uses checkIfAllKeysAreNotEmpty util function to check if all the keys are not empty
   */
  const IS_TX_PO_VALUES_INVALID = useMemo(() => touched.txPo && !checkIfAllKeysAreNotEmpty(txPoInput), [txPoInput, touched.txPo]);
  const IS_PPM_VALUES_INVALID = useMemo(() => touched.ppm && !checkIfAllKeysAreNotEmpty(ppmInput), [ppmInput, touched.ppm]);
  const IS_EVM_VALUES_INVALID = useMemo(() => touched.evm && !checkIfAllKeysAreNotEmpty(evmInput), [evmInput, touched.evm]);
  const IS_RX_GAIN_VALUES_INVALID = useMemo(() => touched.rxGain && !checkIfAllKeysAreNotEmpty(rxGainInput), [rxGainInput, touched.rxGain]);
  const IS_TEST_RESULTS_VALUES_INVALID = useMemo(
    () => touched.testResults && !checkIfAllKeysAreNotEmpty(testResultsInput),
    [testResultsInput, touched.testResults]
  );
  const IS_MAC_INVALID = useMemo(() => touched.mac && !isMacValid(mac), [mac, touched.mac]);

  /**
   * Use this function to call the getCalibrationValue API and get all channel and center_freq list from backend
   * @param testsOnly - if true, only the test results will be updated
   */
  const getCalibration = async (testsOnly = false) => {
    const onSuccess = ({ data: response }: AxiosResponse<CalibrationChannelData>) => {
      if (response?.status) {
        if (!testsOnly) {
          setTxPoInput(response.data.TX_PO_VALUES);
          setRxGainInput(response.data.RX_GAIN_VALUES);
          setEvmInput(response.data.EVM_VALUES);
          setPPMInput(response.data.PPM_VALUES);
        }
        setTestResultsInput(response.data.TEST_RESULT_VALUES);
      } else {
        toast.error(response?.message);
        setFetchError(response?.message);
      }
    };
    http.makeGetRequest<CalibrationChannelData, { message: string }>(getCalibrationValue, onSuccess, (err) => {
      toast.error(err?.response?.data?.message);
      setFetchError(err?.response?.data?.message ?? err?.message);
    });
  };

  /**
   * Use this function to calculate the calibration values,
   * Calls the calculate-calibration-values API with the input values
   * if type is CALCULATE then backend will only calculate the values and return
   * if type is SUBMIT then backend will save the values in the database
   * @param type - type of submit
   */
  const handleCalculate = (type: CALIBRATION_SUBMIT_TYPES) => {
    // Stop execution if mac is invalid
    if (!isMacValid(mac)) {
      setTouched({
        ...touched,
        mac: true,
      });
      calDataRef.current?.scrollIntoView({ behavior: "smooth" });
      return;
    }

    if (txPoInput && rxGainInput && evmInput && ppmInput) {
      setTouched({
        ...touched,
        txPo: true,
        rxGain: true,
        evm: true,
        ppm: true,
      });

      // Checking that all the values are filled in input
      if (
        !checkIfAllKeysAreNotEmpty(txPoInput) ||
        !checkIfAllKeysAreNotEmpty(rxGainInput) ||
        !checkIfAllKeysAreNotEmpty(evmInput) ||
        !checkIfAllKeysAreNotEmpty(ppmInput)
      ) {
        toast.dismiss();
        toast.error("Please enter calibration data");
        return;
      }

      setIsSubmitting(true);

      const contextDetails = {
        vendor_id: context.vendorId,
        customer_product_id: context.customerProductId,
        model_id: context.modelId,
        customer_product_model_id: context.customerProductModelId,
        serial_number: "",
      };

      // Parsing the values to number before sending to backend and preparing payload
      const payload = {
        mac_address: mac,
        input_type: type,
        tx_po_values: parseValueToNumber(txPoInput, ["de", "tx_po_calc", "tx_po_raw"]),
        ppm_values: parseValueToNumber(ppmInput, ["ppm_raw", "xtal_k", "ppm_calc"]),
        evm_values: parseValueToNumber(evmInput, ["evm"]),
        rx_gain_values: parseValueToNumber(rxGainInput, ["rssi_raw", "esg", "rssi_calc"]),
        context_details: type === CALIBRATION_SUBMIT_TYPES.SUBMIT && contextDetails.vendor_id ? contextDetails : undefined,
      };

      const onSuccess = ({ data: response }: AxiosResponse<CalcResults>) => {
        if (response?.status) {
          toast.success(response?.message);
          setCalcResults(response);

          // Scroll to the calculation result section on success
          const currentRef = type === CALIBRATION_SUBMIT_TYPES.CALCULATE ? calcRef : testsRef;
          currentRef.current?.scrollIntoView({ behavior: "smooth" });
        } else {
          toast.error(response?.message || "Something went wrong.");
        }

        setIsSubmitting(false);
      };

      http.makePostRequest<CalcResults, { message: string }>(
        calculateCalibrationValue,
        onSuccess,
        (err) => {
          setIsSubmitting(false);
          toast.error(err?.response?.data?.message);
        },
        payload
      );
    }
  };

  /**
   * Use this function to save the test results
   * Calls the save-calibration-test API with the input values
   */
  const handleSaveTestResults = () => {
    // Stop execution if mac is invalid
    if (!isMacValid(mac)) {
      setTouched({
        ...touched,
        mac: true,
      });
      calDataRef.current?.scrollIntoView({ behavior: "smooth" });
      return;
    }

    if (testResultsInput) {
      setTouched({
        ...touched,
        testResults: true,
      });

      // Checking that all the values are filled in input
      if (!checkIfAllKeysAreNotEmpty(testResultsInput)) {
        toast.dismiss();
        toast.error("Please enter test data");
        return;
      }

      setIsSubmitting(true);

      // Parsing the values to number before sending to backend and preparing payload
      const payload = {
        mac_address: mac,
        test_result_arr: parseValueToNumber(testResultsInput, ["tx_po", "evm", "ppm", "sensitivity", "rx_gain", "ppm_adjusted"]),
      };

      const onSuccess = ({ data: response }: AxiosResponse<CalcResults>) => {
        if (response?.status) {
          // Enable retest button if test is saved successfully
          setIsAlreadySubmitted(true);
          toast.success(response?.message);
        } else {
          toast.error(response?.message);
        }

        setIsSubmitting(false);
      };

      http.makePostRequest<CalcResults, { message: string }>(
        saveCalibrationTest,
        onSuccess,
        (err) => {
          setIsSubmitting(false);
          toast.error(err?.response?.data?.message);
        },
        payload
      );
    }
  };

  // Handler to verify that mac address was written successfully on the device
  const verifyMacAssigned = (payload: AssignVerifyPayload) => {
    const onSuccess = ({ data: response }: AxiosResponse<UpdateItemResponse>) => {
      if (response?.status) {
        // set mac address to show on UI and change status of mac fetching to false
        setMac(payload?.wisa_mac_address);
        setIsMacFetching(false);
        toast.success(response?.message);
      }
    };

    // Call the API to verify the mac address with mac address and response from device.
    // This API will assign customer to the mac address
    http.makePostRequest<UpdateItemResponse, { message: string }>(
      postWisaMacAssignVerify,
      onSuccess,
      (err) => {
        toast.error(err?.response?.data?.message);
        setIsMacFetching(false);
      },
      payload
    );
  };

  // Click handler for getting the mac address from server
  const assignWisaMac = async () => {
    // Establish connection with device if not connected
    let isConnected = connected;
    if (!isConnected) {
      isConnected = await handleConnect();
    }

    // set status of mac fetching to true
    setIsMacFetching(isConnected);
    await delay(2000);
    if (isConnected) {
      // Success handler for getting the mac address from server
      const onSuccess = async ({ data: response }: AxiosResponse<GetWiSAMacResponse>) => {
        if (!appContext?.connectedPort) return;

        const runSequence = appContext?.connectedPort?.runSequence;

        if (response?.status) {
          if (response?.data?.command && connected) {
            // Run command provided by backend on the device and send the response to backend for verification
            const deviceResponse = await runSequence(response?.data?.command, true, COMMAND_TYPE.Set);

            // Prepare context details
            const contextDetails = {
              vendor_id: context.vendorId,
              customer_product_id: context.customerProductId,
              model_id: context.modelId,
              customer_product_model_id: context.customerProductModelId,
              serial_number: "",
            };

            // Call the verify API with the response from device
            verifyMacAssigned({
              context_details: context.vendorId ? contextDetails : null,
              wisa_mac_address: response?.data?.wisa_mac?.wisa_mac_address,
              device_response: `${formatDeviceResponse(deviceResponse)}`,
            });
          }
        } else {
          toast.error(response?.message);
          setIsMacFetching(false);
        }
      };

      // Call the get mac API to fetch unique mac address from backend
      http.makePostRequest<GetWiSAMacResponse, { message: string }>(
        getWisaMacForAssignment,
        onSuccess,
        (err) => {
          toast.error(err?.response?.data?.message);
          setIsMacFetching(false);
        },
        { communicationType: appContext?.connectedPort?.communicationType }
      );
    }
  };

  const deviceStatus = connected ? "Connected" : "Disconnected";

  React.useEffect(() => {
    getCalibration();
  }, []);

  if (fetchError) return <div className="errorAlert">{fetchError}</div>;

  if (txPoInput === null || rxGainInput === null || evmInput === null || ppmInput === null || testResultsInput === null) return null;
  return (
    <div className={styles.pageContainer}>
      <div className={styles.headContainer} ref={calDataRef}>
        <h2>Input Calibration Data</h2>
        <div className={styles.titleRightContainer}>
          <div className={styles.statusContainer}>
            <div className={`${styles.statusDot} ${styles?.[deviceStatus]}`}></div>
            <span>{deviceStatus}</span>
          </div>
          <button
            onClick={() => {
              getCalibration();
              setTouched(initialTouchedValues);
              setIsAlreadySubmitted(false);
              setMac("");
              setCalcResults(null);
            }}>
            New Calibration
          </button>
        </div>
      </div>
      <div className={styles.inputContainer}>
        {mac ? (
          <input
            placeholder="Enter MAC Address"
            value={mac}
            className={IS_MAC_INVALID ? styles.invalid : ""}
            onBlur={() => setTouched({ ...touched, mac: true })}
            disabled
          />
        ) : (
          <button onClick={assignWisaMac} disabled={isMacFetching || connecting}>
            {isMacFetching ? "Assigning..." : "Assign MAC"}
          </button>
        )}
        {IS_MAC_INVALID && touched.mac && <span className={styles.error}>Please enter valid MAC address</span>}
      </div>
      <div className={styles.container1}>
        <TableComponent
          title="TxPo"
          tableHeaders={TXPO_TABLE_HEADERS}
          data={txPoInput}
          setValues={setTxPoInput}
          isInValid={IS_TX_PO_VALUES_INVALID}
        />
        <TableComponent
          title="RxGain"
          tableHeaders={RXGAIN_TABLE_HEADERS}
          data={rxGainInput}
          setValues={setRxGainInput}
          isInValid={IS_RX_GAIN_VALUES_INVALID}
        />
      </div>
      <div className={styles.container2}>
        <TableComponent
          title="PPM"
          tableHeaders={PPM_TABLE_HEADERS}
          data={ppmInput}
          setValues={setPPMInput}
          isInValid={IS_PPM_VALUES_INVALID}
          handleTableDataChange={(newData) => {
            setPPMInput(
              newData?.map((item) => {
                return {
                  ...item,
                  ppm_raw_adjusted: item.ppm_raw / +item.center_freq,
                  ppm_calc_adjusted: item.ppm_calc / +item.center_freq,
                };
              })
            );
          }}
        />
        <TableComponent
          title="EVM"
          tableHeaders={EVM_TABLE_HEADERS}
          data={evmInput}
          setValues={setEvmInput}
          isInValid={IS_EVM_VALUES_INVALID}
        />
      </div>
      <div className={styles.buttonContainer}>
        <button
          onClick={() => {
            handleCalculate(CALIBRATION_SUBMIT_TYPES.CALCULATE);
          }}
          disabled={isSubmitting}>
          Calculate
        </button>
      </div>
      <hr className={styles.hr} />
      <h2 ref={calcRef}>Calc E-Fuse</h2>
      <div className={styles.calcResultsContainer}>
        <div className={styles.list}>
          <div className={styles.row}>
            <div>Xtal_K(Hex)</div>
            <div>{calcResults?.data?.calc_e_fuse?.xtal_k ?? "-"}</div>
          </div>
          <div className={styles.row}>
            <div>ThermalMeter(Hex)</div>
            <div>{calcResults?.data?.calc_e_fuse?.thermal ?? "-"}</div>
          </div>
        </div>
        <div className={styles.list}>
          <div className={styles.row}>
            <div>0x148</div>
            <div>{calcResults?.data?.calc_e_fuse?.hex_0x148 ?? "-"}</div>
          </div>
          <div className={styles.row}>
            <div>0x14c</div>
            <div>{calcResults?.data?.calc_e_fuse?.hex_0x14c ?? "-"}</div>
          </div>
        </div>
        <div className={styles.list}>
          <div className={styles.row}>
            <div>Ch36,38,40</div>
            <div>{calcResults?.data?.calc_e_fuse?.ch_36_38_40 ?? "-"}</div>
          </div>
          <div className={styles.row}>
            <div>Ch44,46,48</div>
            <div>{calcResults?.data?.calc_e_fuse?.ch_44_46_48 ?? "-"}</div>
          </div>
          <div className={styles.row}>
            <div>Ch149/151/153</div>
            <div>{calcResults?.data?.calc_e_fuse?.ch_149_151_153 ?? "-"}</div>
          </div>
          <div className={styles.row}>
            <div>Ch157/159/161</div>
            <div>{calcResults?.data?.calc_e_fuse?.ch_157_159_161 ?? "-"}</div>
          </div>
          <div className={styles.row}>
            <div>Ch165-169</div>
            <div>{calcResults?.data?.calc_e_fuse?.ch_165_169 ?? "-"}</div>
          </div>
          <div className={styles.row}>
            <div>Ch173-177</div>
            <div>{calcResults?.data?.calc_e_fuse?.ch_173_177 ?? "-"}</div>
          </div>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <button
          onClick={() => {
            handleCalculate(CALIBRATION_SUBMIT_TYPES.SUBMIT);
          }}
          disabled={isSubmitting}>
          Submit
        </button>
      </div>
      <hr className={styles.hr} />
      <h2 ref={testsRef}>Test Results</h2>
      <div className={styles.container3}>
        <TableComponent
          title="Test Results"
          tableHeaders={TEST_RESULTS_TABLE_HEADERS}
          data={testResultsInput}
          setValues={setTestResultsInput}
          isInValid={IS_TEST_RESULTS_VALUES_INVALID}
          handleTableDataChange={(newData) => {
            setTestResultsInput(
              newData?.map((item) => {
                return {
                  ...item,
                  ppm_adjusted: item.ppm ? (+item.ppm / +item.center_freq).toString() : "",
                };
              })
            );
          }}
        />
      </div>
      <div className={styles.buttonContainer}>
        <button onClick={handleSaveTestResults} disabled={isSubmitting || isAlreadySubmitted}>
          Submit
        </button>
        <button
          onClick={() => {
            getCalibration(true);
            setTouched({
              ...touched,
              testResults: false,
            });
            setIsAlreadySubmitted(false);
          }}
          disabled={isSubmitting || !isAlreadySubmitted}>
          Re-Test
        </button>
      </div>
      {!connected ? <ConnectDialog connecting={connecting} setConnected={setConnected} setConnecting={setConnecting} /> : null}
    </div>
  );
};

export default Calibration;
