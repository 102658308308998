import { ChangeEvent } from "react";
import Checkbox from "../../../components/Checkbox";
import styles from "../styles.module.scss";

export interface ModuleProgrammingDeviceCalibrationProps {
  onClickAssignMac: () => void;
  onChangeOverwriteCalData: (event: ChangeEvent<HTMLInputElement>) => void;
  onChangeOverwriteMac: (event: ChangeEvent<HTMLInputElement>) => void;
  onChangeSkipDeviceProvisioning: (event: ChangeEvent<HTMLInputElement>) => void;
  isOverwriteMacChecked: boolean;
  isOverwriteCalDataChecked: boolean;
  isSequenceRunning: boolean;
  isSkipDeviceProvisioning: boolean;
  haveSkipDeviceProvisioningPermission: boolean;
}

const ModuleProgrammingDeviceCalibration = ({
  onChangeOverwriteCalData,
  onChangeOverwriteMac,
  onClickAssignMac,
  onChangeSkipDeviceProvisioning,
  isOverwriteCalDataChecked,
  isOverwriteMacChecked,
  isSequenceRunning,
  isSkipDeviceProvisioning,
  haveSkipDeviceProvisioningPermission,
}: ModuleProgrammingDeviceCalibrationProps) => {
  return (
    <div className={styles.assignMacContainer}>
      <button onClick={onClickAssignMac} disabled={isSequenceRunning}>
        Assign mac
      </button>
      <Checkbox
        label="Overwrite MAC address"
        checked={isOverwriteMacChecked}
        onChange={onChangeOverwriteMac}
        disabled={isSequenceRunning}
      />
      <Checkbox
        label="Overwrite Cal Data"
        checked={isOverwriteCalDataChecked}
        onChange={onChangeOverwriteCalData}
        disabled={isSequenceRunning}
      />
      {haveSkipDeviceProvisioningPermission && (
        <Checkbox
          label="Skip Device Provisioning"
          checked={isSkipDeviceProvisioning}
          onChange={onChangeSkipDeviceProvisioning}
          disabled={isSequenceRunning}
        />
      )}
    </div>
  );
};

export default ModuleProgrammingDeviceCalibration;
