import { AxiosResponse } from "axios";
import { Formik, FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { updateMcuFirmwareVersion } from "../../api/config";
import { http } from "../../api/utils/http";
import { McuFirmwareVersion, UpdateItemResponse } from "../../interfaces/be.interfaces";
import { McuFirmwareVersionInitValue } from "../../interfaces/fe.interfaces";
import { validateMcuFirmwareUploadForm } from "../../utils/validationSchemas";
import Form from "./Form";
import styles from "./styles.module.scss";

interface EditMcuFirmwareVersionDialogProps {
  callback: () => void | Promise<void>;
  onCancelClick: () => void | Promise<void>;
  initialValues: McuFirmwareVersionInitValue;
  id: number;
}

/**
 * This component is responsible for edit mcu firmware version
 */
const EditMcuFirmwareVersionDialog = (props: EditMcuFirmwareVersionDialogProps) => {
  const handleEditAmpConfig = (
    values: McuFirmwareVersionInitValue,
    { setSubmitting }: FormikHelpers<McuFirmwareVersionInitValue>
  ): void => {
    const formData = new FormData();

    formData.append("mcu_model", values.mcu_model);
    formData.append("vendor_id", values.vendor_id);
    formData.append("firmware_version", values.firmware_version);
    formData.append("firmware_description", values.firmware_description);
    formData.append("mcu_firmware_id", String(props.id));
    values.mcu_firmware_bin && formData.append("mcu_firmware_bin", values.mcu_firmware_bin);

    // Success callback for /edit-amp-config API
    const onSuccess = ({ data: response }: AxiosResponse<UpdateItemResponse<McuFirmwareVersion>>) => {
      setSubmitting(false);

      if (response?.status) {
        toast.success(response?.message);

        // Close the dialog and call parent callback to refresh the table
        props.callback();
        props.onCancelClick();
      } else {
        toast.error(response?.message);
      }
    };

    // Calls /edit-amp-config API
    http.makePostRequestWithFormData<UpdateItemResponse<McuFirmwareVersion>, { message: string }>(
      updateMcuFirmwareVersion,
      onSuccess,
      (error) => {
        setSubmitting(false);
        toast.error(error?.response?.data?.message);
      },
      formData
    );
  };

  return (
    <div className={styles.dialogContainer}>
      <Formik
        enableReinitialize={true}
        initialValues={props?.initialValues}
        onSubmit={handleEditAmpConfig}
        onReset={props.onCancelClick}
        validationSchema={validateMcuFirmwareUploadForm}>
        {(props) => <Form {...props} enableReinitialize={true} />}
      </Formik>
    </div>
  );
};

export default EditMcuFirmwareVersionDialog;
