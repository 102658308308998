import { ChangeEvent, Dispatch, SetStateAction } from "react";
import ProductConfigDropdowns, { ProductConfigDropdownsProps } from "../../../components/ProductConfigDropdowns";
import styles from "../styles.module.scss";
import { WisaProductModel } from "../../../interfaces/be.interfaces";
import Select from "../../../components/Select";
import Checkbox from "../../../components/Checkbox";

export interface ModuleProgrammingDeviceConfigurationProps extends ProductConfigDropdownsProps {
  isSequenceRunning: boolean;
  scannedMac: string;
  wisaProducts: WisaProductModel[];
  deviceType: string;
  onChangeWisaProduct: (event: ChangeEvent<HTMLSelectElement>) => void;
  isRemoteMacAvailable: boolean;
  setIsRemoteMacAvailable: Dispatch<SetStateAction<boolean>>;
  macAddresses: string[];
}

const ModuleProgrammingDeviceConfiguration = ({
  deviceConfigurationPayload,
  setDeviceConfigurationPayload,
  customersList,
  productList,
  modelList,
  updatePayloadValue,
  regions,
  isRegionEnabled,
  isSpeakerPositionEnabled,
  isSequenceRunning,
  scannedMac,
  showAsscModeCheckbox,
  changeAsscModeInRemote,
  setChangeAsscModeInRemote,
  wisaProducts,
  deviceType,
  onChangeWisaProduct,
  beProductList,
  setDeviceType,
  isRemoteMacAvailable,
  setIsRemoteMacAvailable,
  macAddresses,
}: ModuleProgrammingDeviceConfigurationProps) => {
  return (
    <div style={{ width: "100%", marginBottom: 10 }}>
      <ProductConfigDropdowns
        deviceConfigurationPayload={deviceConfigurationPayload}
        setDeviceConfigurationPayload={setDeviceConfigurationPayload}
        customersList={customersList}
        productList={productList}
        modelList={modelList}
        updatePayloadValue={updatePayloadValue}
        regions={regions ?? []}
        isSequenceRunning={isSequenceRunning}
        isRegionEnabled={isRegionEnabled}
        isSpeakerPositionEnabled={isSpeakerPositionEnabled}
        showAsscModeCheckbox={showAsscModeCheckbox}
        changeAsscModeInRemote={changeAsscModeInRemote}
        setChangeAsscModeInRemote={setChangeAsscModeInRemote}
        beProductList={beProductList}
        setDeviceType={setDeviceType}
      />
      <div>
        <Checkbox
          label="Is remote device available?"
          checked={isRemoteMacAvailable}
          onChange={() => {
            setIsRemoteMacAvailable(!isRemoteMacAvailable);
          }}
          disabled={isSequenceRunning}
        />
      </div>
      <div className={styles.firmwareProgrammingMacScanInputWrapper}>
        <div className={`d-flex ${styles.infoboxContainer}`}>
          <span>WiSA Product Type:</span>
          <Select
            selectProps={{
              name: "wisa_product",
              placeholder: "WiSA Product",
              value: deviceType,
              onChange: onChangeWisaProduct,
              className: styles.customSelectBox,
              disabled: isSequenceRunning,
            }}
            options={wisaProducts}
            optionLabel="type"
            optionValue="type"
          />
          {isRemoteMacAvailable ? (
            <div className={styles.macAddressContainer}>
              {new Array(2).fill(0).map((_, index) => (
                <label key={index}>
                  {macAddresses[index] ?? (index === 0 ? "Connected device MAC address" : "Remote device MAC address")}
                </label>
              ))}
            </div>
          ) : (
            <div className={styles.macAddressContainer}>
              <label>{scannedMac || "Connected device MAC address"}</label>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModuleProgrammingDeviceConfiguration;
