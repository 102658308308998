import { useCallback, useState, MouseEvent, useMemo, ChangeEvent } from "react";
import AddMacAddress from "../../components/AddMacAddressDialog";
import styles from "./styles.module.scss";
import { useFetch } from "../../hooks/useFetch";
import { BASE_URL, deleteFailedCSV, macAddressList } from "../../api/config";
import { GetWiSAMacListResponse, UpdateItemResponse } from "../../interfaces/be.interfaces";
import moment from "moment";
import UploadMacDialog from "../../components/UploadMacDialog";
import Spinner from "../../components/Spinner";
import { Icons } from "../../../assets/images";
import MacImportDetailsDialog from "../../components/MacImportDetailsDialog";
import { http } from "../../api/utils/http";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { PERMISSIONS } from "../../utils/constants";
import AccessGuard from "../../components/Guards/AccessGuard";

const INITIAL_LIMIT = 15;
const INITIAL_OFFSET = 0;
const INITIAL_PAGES = [1, 2, 3];

/**
 * This component is responsible for showing list of mac addresses
 */
const ManageMac = () => {
  const [showAddMacAddress, setShowAddMacAddress] = useState<boolean>(false);
  const [showUploadDialog, setShowUploadDialog] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(INITIAL_OFFSET);
  const [total, setTotal] = useState<number>(0);
  const [pages, setPages] = useState(INITIAL_PAGES);
  const [limit, setLimit] = useState(INITIAL_LIMIT);
  const [macProcessId, setMacProcessId] = useState<string | null>(null);

  // fetch mac addresses list on mount of the page
  const { data, run, loading } = useFetch<GetWiSAMacListResponse>({
    url: `${macAddressList}?limit=${limit}&${offset}`,
    runOnMount: true,
    onSuccess: (res) => {
      setTotal(res.data.data?.total);
    },
  });

  // Closes the add mac address dialog
  const handleCloseAddManualMacDialog = useCallback(() => {
    setShowAddMacAddress(false);
  }, []);

  // Closes the upload mac address dialog
  const handleCloseUploadDialog = useCallback(() => {
    setShowUploadDialog(false);
  }, []);

  /**
   * Handles after submit of add mac address dialog
   * Used for refetching the mac address list after successful insert of new records in the database
   */
  const handleAfterSubmit = useCallback(
    (showLoader = true) => {
      run({
        url: `${macAddressList}?limit=${limit}&offset=${INITIAL_OFFSET}`,
        showLoader,
      });
      setOffset(INITIAL_OFFSET);
      setPages(INITIAL_PAGES);
    },
    [run, limit]
  );

  const currentPage = useMemo(() => Math.ceil(offset / limit) + 1, [offset, limit]);
  const totalPages = useMemo(() => Math.ceil(total / limit), [total, limit]);

  /**
   * This function is responsible for finding the page numbers to be shown in the pagination
   * Maximum 3 page numbers are shown in the pagination
   * @param {string} btnAction - action of the button clicked, can be next or prev
   * @param {number} newOffset - new offset to be set
   */
  const findPageNumbers = useCallback(
    (btnAction: string, newOffset: number) => {
      const newCurrentPage = Math.ceil(newOffset / limit) + 1;

      // if current page or next page is available then change page numbers on UI
      if (newCurrentPage < totalPages && newCurrentPage > 1) {
        setPages([newCurrentPage - 1, newCurrentPage, newCurrentPage + 1]);
      }
    },
    [pages, totalPages, currentPage, limit]
  );

  /**
   * This function is responsible for handling the next and prev button click
   * data-action attribute is used to identify the button clicked
   */
  const onNextOrPrevClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      const btnAction = e.currentTarget.getAttribute("data-action");
      let newOffset = offset;

      // Increase or decrease the offset based on the button clicked
      if (btnAction === "next") {
        newOffset = newOffset + limit;
      } else if (btnAction === "prev") {
        newOffset = newOffset - limit;
      }

      // Re-generate the page numbers to be shown in the pagination
      btnAction && findPageNumbers(btnAction, newOffset);

      // fetch data for the new offset
      run({
        url: `${macAddressList}?limit=${limit}&offset=${newOffset}`,
      });
      setOffset(newOffset);
    },
    [offset, pages, totalPages, currentPage, run, findPageNumbers, limit]
  );

  /**
   * Handles click event for the page numbers in the pagination
   * Jumps to the page number clicked
   */
  const navigateToPage = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      // Find new offset based on the page number clicked using the data-page_number attribute
      const pageNumber = Number(e.currentTarget.getAttribute("data-page_number"));
      const newOffset = (pageNumber - 1) * limit;

      // Re-generate the page numbers to be shown in the pagination
      findPageNumbers(newOffset > offset ? "next" : "prev", newOffset);

      run({
        url: `${macAddressList}?limit=${limit}&offset=${newOffset}`,
      });
      setOffset(newOffset);
    },
    [offset, findPageNumbers, limit]
  );

  /**
   * Handles change event for the limit dropdown
   * reset all pagination related states and fetch data for the new limit
   */
  const handleChangeLimit = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    const newLimit = Number(e.target.value);
    const newOffset = INITIAL_OFFSET;

    run({
      url: `${macAddressList}?limit=${newLimit}&offset=${newOffset}`,
    });

    setOffset(newOffset);
    setLimit(newLimit);
    setPages(INITIAL_PAGES);
  }, []);

  const handleOpenUploadCSVDialog = useCallback(() => {
    setShowUploadDialog(true);
  }, []);

  const handleOpenManualUploadDialog = useCallback(() => {
    setShowAddMacAddress(true);
  }, []);

  /**
   * Handles click event for the delete button of failed mac CSV list
   * @param {string} csvName - name of the csv file to delete
   */
  const handleDeleteFailedMacProcess = useCallback((csvName: string) => {
    // Success handler for the delete API
    const onSuccess = ({ data: response }: AxiosResponse<UpdateItemResponse>) => {
      if (response?.status) {
        // Refetch the mac address list after deleting the failed mac CSV file
        run({ showLoader: false });
        toast.success(response?.message);
      } else {
        toast.error(response?.message);
      }
    };

    // Call delete API to delete the failed mac CSV file
    http.makeDeleteRequest<UpdateItemResponse, { message: string }>(`${deleteFailedCSV}/${csvName}`, onSuccess, (error) => {
      toast.error(error?.response?.data?.message);
    });
  }, []);

  /**
   * Close button handler for the mac process details dialog
   */
  const handleCloseDetailsDialog = useCallback(() => {
    setMacProcessId(null);
    handleAfterSubmit(false);
  }, [handleAfterSubmit]);

  return (
    <>
      <div className={styles.pageContainer}>
        <div className={styles.processListContainer}>
          {data?.data?.importProcesses?.map((process) => (
            <div className={styles.processItem} key={process}>
              <span>MAC Import Process in Progress</span>
              <button onClick={() => setMacProcessId(process)}>View Details</button>
            </div>
          ))}
          {data?.data?.failedCsvList?.map((process) => (
            <div className={`${styles.processItem} ${styles.failedProcesses}`} key={process}>
              <span>Download list of MAC address that failed to upload</span>
              <div>
                <button onClick={() => handleDeleteFailedMacProcess(process)}>Delete</button>
                <a href={`${BASE_URL}/${process}`} target="_blank" rel="noreferrer">
                  Download
                </a>
              </div>
            </div>
          ))}
        </div>
        <div className={`${styles.pageHeader} d-flex justify-content-between`}>
          <p>Manage MAC Addresses</p>
          <AccessGuard permission={PERMISSIONS.MAC_ADD}>
            <div className={`${styles.headerActions} d-flex justify-content-between align-item-center`}>
              <button onClick={handleOpenUploadCSVDialog}>Upload CSV</button>
              <button onClick={handleOpenManualUploadDialog}>Add Manually</button>
            </div>
          </AccessGuard>
        </div>
        <div className={styles.tableResponsive}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>MAC Address</th>
                <th className={styles.center}>Product Type</th>
                <th className={styles.center}>Assigned</th>
                <th className={styles.center}>Date Assigned</th>
                <th className={styles.center}>Customer Assigned</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={5}>
                    <Spinner className={styles.customLoader} />
                  </td>
                </tr>
              ) : (
                <>
                  {data?.data?.list?.length ? (
                    <>
                      {data?.data?.list?.map((item) => (
                        <tr key={item.wisa_mac_address}>
                          <td>{item.wisa_mac_address}</td>
                          <td className={styles.center}>{item.product_type}</td>
                          <td className={styles.center}>{item.assigned ? "Yes" : "No"}</td>
                          <td className={styles.center}>{item.date_assigned ? moment(item.date_assigned).format("MM-DD-YYYY") : "-"}</td>
                          <td className={styles.center}>{item?.wisa_customer?.customer_name ?? "-"}</td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td className={styles.noDataFound} colSpan={5}>
                        No data found
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
          {!loading && (
            <div className={styles.paginationContainer}>
              <select onChange={handleChangeLimit} value={limit}>
                {[INITIAL_LIMIT, 30, 50, 100].map((item) => (
                  <option key={item} value={item} selected={item === limit}>
                    {item}
                  </option>
                ))}
              </select>
              <button onClick={onNextOrPrevClick} data-action="prev" disabled={offset === 0}>
                <Icons.ChevronLeft />
              </button>
              {pages?.map((item) => (
                <button
                  key={item}
                  className={`${item === currentPage ? styles.active : ""}`}
                  data-page_number={item}
                  onClick={navigateToPage}
                  disabled={item > totalPages}>
                  {item}
                </button>
              ))}
              <button onClick={onNextOrPrevClick} data-action="next" disabled={offset + limit >= total}>
                <Icons.ChevronRight />
              </button>
            </div>
          )}
        </div>
      </div>
      {showAddMacAddress && <AddMacAddress handleClose={handleCloseAddManualMacDialog} handleAfterSubmit={handleAfterSubmit} />}
      {showUploadDialog && <UploadMacDialog handleClose={handleCloseUploadDialog} handleAfterSubmit={handleAfterSubmit} />}
      {macProcessId && <MacImportDetailsDialog macProcessId={macProcessId} handleCloseDetailsDialog={handleCloseDetailsDialog} />}
    </>
  );
};

export default ManageMac;
