import { Formik, FormikHelpers } from "formik";
import { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { uploadFirmware } from "../../../api/config";
import { http } from "../../../api/utils/http";
import { UploadFirmwareResponse, WisaProductModel } from "../../../interfaces/be.interfaces";
import { ManageFirmwareWisaFirmwareFormInitValuesInterface } from "../../../interfaces/fe.interfaces";
import { validateWisaFirmwareUploadForm } from "../../../utils/validationSchemas";
import ManageFirmwareForm from "./Form";
import styles from "./styles.module.scss";

export interface ManageFirmwareWisaFirmwareUploadFormProps {
  wisaProducts: WisaProductModel[];
  onUploadFirmware?: () => void;
  onFormSubmitSuccessCallback?: () => void;
  onFormSubmitFailureCallback?: () => void;
}

const ManageFirmwareWisaFirmwareUploadForm = ({
  wisaProducts,
  onUploadFirmware,
  onFormSubmitFailureCallback,
  onFormSubmitSuccessCallback,
}: ManageFirmwareWisaFirmwareUploadFormProps) => {
  const [postFirmwareError, setPostFirmwareError] = useState<string | undefined>(undefined);

  const initialValues: ManageFirmwareWisaFirmwareFormInitValuesInterface = useMemo(
    () => ({
      current_customer_version: 0,
      description: "",
      memory_location: "",
      version: "",
      firmware_bin: null,
      wisa_product: "",
    }),
    []
  );

  const onError = useCallback(
    (setSubmitting: (val: boolean) => void, message?: string) => {
      setPostFirmwareError(message);
      onFormSubmitFailureCallback?.();
      setSubmitting(false);
    },
    [setPostFirmwareError, onFormSubmitFailureCallback]
  );

  const onSuccess = useCallback(
    (data: UploadFirmwareResponse, setSubmitting: (isSubmitting: boolean) => void) => {
      if (data.status) {
        setPostFirmwareError(undefined);
        toast.success(data.message);
        onUploadFirmware?.();
      } else {
        setPostFirmwareError(data.message);
      }

      onFormSubmitSuccessCallback?.();
      setSubmitting(false);
    },
    [setPostFirmwareError, onFormSubmitSuccessCallback]
  );

  const handleOnFirmwareSubmit = useCallback(
    (
      values: ManageFirmwareWisaFirmwareFormInitValuesInterface,
      { setSubmitting }: FormikHelpers<ManageFirmwareWisaFirmwareFormInitValuesInterface>
    ) => {
      if (!values.firmware_bin) return;

      const formData = new FormData();
      formData.append("current_customer_version", values.current_customer_version ? "true" : "false");
      values.description && formData.append("description", values.description);
      formData.append("memory_location", values.memory_location);
      formData.append("version", values.version);
      formData.append("wisa_product", values.wisa_product);
      formData.append("firmware_bin", values.firmware_bin);

      http.makePostRequestWithFormData<UploadFirmwareResponse, { message: string }>(
        uploadFirmware,
        (res) => onSuccess(res.data, setSubmitting),
        (e) => onError(setSubmitting, e?.response?.data.message),
        formData
      );
    },
    [onError, onSuccess, uploadFirmware]
  );

  return (
    <div className={styles.wisaFirmwareUploadFormContainer}>
      {postFirmwareError && <div className="errorAlert">{postFirmwareError}</div>}
      <Formik initialValues={initialValues} onSubmit={handleOnFirmwareSubmit} validationSchema={validateWisaFirmwareUploadForm}>
        {(props) => <ManageFirmwareForm {...props} wisaProducts={wisaProducts} />}
      </Formik>
    </div>
  );
};

export default ManageFirmwareWisaFirmwareUploadForm;
