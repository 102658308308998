import { useCallback, useState } from "react";
import AddCertVersion from "../../components/AddOrEditCertAppVersion/AddCertVersion";
import Modal from "../../components/Modal/Modal";
import styles from "./styles.module.scss";
import { useFetch } from "../../hooks/useFetch";
import { CertAppVersion, UpdateItemResponse } from "../../interfaces/be.interfaces";
import { getCertAppVersions, updateCurrentCustomerVersion } from "../../api/config";
import Switch from "../../components/Switch";
import { http } from "../../api/utils/http";
import { toast } from "react-toastify";
import AccessGuard from "../../components/Guards/AccessGuard";
import { PERMISSIONS } from "../../utils/constants";

const ManageCertAppVersion = () => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  // Hook to get the list of the cert app versions
  const {
    data: certAppVersions,
    error: certAppVersionError,
    run: runList,
  } = useFetch<UpdateItemResponse<CertAppVersion[]>>({
    url: getCertAppVersions,
    runOnMount: true,
  });

  /**
   * on change event for changing latest version of Cert App
   */
  const changeLatestVersion = useCallback((versionNo: string) => {
    const onSuccess = () => {
      runList();
    };

    // Make post request with version no to update the latest version
    http.makePostRequest<UpdateItemResponse, { message: string }>(
      updateCurrentCustomerVersion,
      onSuccess,
      (err) => {
        toast.error(err?.response?.data?.message);
      },
      {
        version_no: versionNo,
      }
    );
  }, []);

  return (
    <>
      {certAppVersionError ? <div className="errorAlert">{certAppVersionError}</div> : null}
      <div className={styles.pageContainer}>
        <div className={`${styles.pageHeader} d-flex justify-content-between`}>
          <p>Manage Cert App Version</p>
          <AccessGuard permission={PERMISSIONS.CERT_APP_UPLOAD}>
            <div className={`${styles.headerActions} d-flex justify-content-between align-item-center`}>
              <button
                onClick={() => {
                  setIsAddModalOpen(true);
                }}>
                Upload New Version
              </button>
            </div>
          </AccessGuard>
        </div>
        <div className={styles.tableResponsive}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>App Version</th>
                <th className={styles.center}>File</th>
                <th className={styles.center}>Latest Version</th>
                <th className={styles.center}>Description</th>
              </tr>
            </thead>
            <tbody>
              {certAppVersions?.data &&
                certAppVersions?.data?.length > 0 &&
                certAppVersions?.data?.map((version) => (
                  <tr key={version.version_no}>
                    <td>{version.version_no}</td>
                    <td className={styles.center}>{version.file_path}</td>
                    <td className={styles.center}>
                      <AccessGuard permission={PERMISSIONS.CERT_APP_UPDATE_VERSION}>
                        <Switch
                          checked={version.is_latest_version}
                          disabled={version.is_latest_version}
                          name={"user"}
                          onChange={() => !version.is_latest_version && changeLatestVersion(version.version_no)}
                        />
                      </AccessGuard>
                    </td>
                    <td className={styles.center}>{version.description}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        isModalOpen={isAddModalOpen}
        onModalClose={() => {
          setIsAddModalOpen(false);
        }}>
        <AddCertVersion
          handleOnClose={() => {
            setIsAddModalOpen(false);
          }}
          callback={() => {
            // After version is added successfully, run list API call again to refresh the page
            runList();
          }}
        />
      </Modal>
    </>
  );
};

export default ManageCertAppVersion;
