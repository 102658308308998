import { AxiosResponse } from "axios";
import { Formik, FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { uploadMcuFirmwareVersion } from "../../api/config";
import { http } from "../../api/utils/http";
import { McuFirmwareVersion, UpdateItemResponse } from "../../interfaces/be.interfaces";
import { McuFirmwareVersionInitValue } from "../../interfaces/fe.interfaces";
import { validateMcuFirmwareUploadForm } from "../../utils/validationSchemas";
import Form from "./Form";
import styles from "./styles.module.scss";

interface AddMcuFirmwareVersionDialogProps {
  callback: () => void | Promise<void>;
  onCancelClick: () => void | Promise<void>;
}

// Initial values for add mcu firmware configuration form
const initialValues: McuFirmwareVersionInitValue = {
  current_customer_version: false,
  mcu_model: "",
  vendor_id: "",
  firmware_version: "",
  firmware_description: "",
  mcu_firmware_bin: null,
};

/**
 * This component is responsible for adding new mcu firmware version
 */
const AddMcuFirmwareVersion = (props: AddMcuFirmwareVersionDialogProps) => {
  // Handler for form submit to save mcu firmware version
  const handleSubmit = (values: McuFirmwareVersionInitValue, { setSubmitting }: FormikHelpers<McuFirmwareVersionInitValue>): void => {
    if (!values.mcu_firmware_bin) return;

    const formData = new FormData();

    formData.append("current_customer_version", values.current_customer_version ? "true" : "false");
    formData.append("mcu_model", values.mcu_model);
    formData.append("vendor_id", values.vendor_id);
    formData.append("firmware_version", values.firmware_version);
    formData.append("firmware_description", values.firmware_description);
    formData.append("mcu_firmware_bin", values.mcu_firmware_bin);

    // Success callback for /upload-mcu-firmware API
    const onSuccess = ({ data: response }: AxiosResponse<UpdateItemResponse<McuFirmwareVersion>>) => {
      setSubmitting(false);
      if (response?.status) {
        // Display success toast and call parent callback to refresh the table
        toast.success(response?.message);
        props.callback();

        // Close the dialog
        props.onCancelClick();
      } else {
        toast.error(response?.message);
      }
    };

    // Calls /upload-mcu-firmware API
    http.makePostRequestWithFormData<UpdateItemResponse<McuFirmwareVersion>, { message: string }>(
      uploadMcuFirmwareVersion,
      onSuccess,
      (error) => {
        setSubmitting(false);
        toast.error(error?.response?.data?.message);
      },
      formData
    );
  };

  return (
    <div className={styles.dialogContainer}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onReset={props.onCancelClick}
        validationSchema={validateMcuFirmwareUploadForm}>
        {(props) => <Form {...props} />}
      </Formik>
    </div>
  );
};

export default AddMcuFirmwareVersion;
