import styles from "./styles.module.scss";

/**
 * title - title string to be displayed as title of the dialog
 * description - description text for dialog box
 * onConfirmClick - callback function to be called on confirm button click
 * onDeclineClick - callback function to be called on decline button click
 */
interface ConfirmationDialogProps {
  title: string;
  description: string;
  onConfirmClick: () => void | Promise<void>;
  onDeclineClick: () => void | Promise<void>;
}

/**
 * Component to use for taking confirmations
 */
const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  return (
    <>
      <div className={styles.dialogContainer}>
        <div className={styles.dialog}>
          <p className={styles.dialogHeader}>{props.title}</p>
          <p className={styles.dialogContent}>{props.description}</p>
          <div className="w-100 d-flex justify-content-around">
            <button className={styles.dialogBtn} onClick={props.onConfirmClick}>
              Yes
            </button>
            <button className={styles.dialogBtn} onClick={props.onDeclineClick}>
              No
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmationDialog;
