import { AxiosResponse } from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";

import Switch from "../../components/Switch";
import Spinner from "../../components/Spinner";
import DeleteConfirmation from "../../components/ConfirmationDialog";
import CreateCommandDialog from "../../components/AddOrEditCommand/AddCommandDialog";

import { http } from "../../api/utils/http";
import { deleteInputCommand, enableDisableInputCommand, getInputCommands } from "../../api/config";
import { CustomCommand, CustomCommandData, InputCommandData } from "../../interfaces/be.interfaces";

import { Icons } from "../../../assets/images";
import styles from "./styles.module.scss";
import EditCommandDialog from "../../components/AddOrEditCommand/EditCommandDialog";
import { Tooltip } from "react-tooltip";
import { Link } from "react-router-dom";
import { CustomCommandFormValues } from "../../interfaces/fe.interfaces";
import { PERMISSIONS } from "../../utils/constants";
import AccessGuard from "../../components/Guards/AccessGuard";

const ManageCustomCommand = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [customCommandList, setCustomCommandList] = useState<CustomCommand[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [deleteCommandId, setDeleteCommandId] = useState<null | string>(null);
  const [addDialogVisible, setAddDialogVisible] = useState(false);
  const [editCommandDetails, setEditCommandDetails] = useState<CustomCommandFormValues | null>(null);

  /**
   * Calls /input-command-enable-disable API to change status in database
   * @param seq_id - command id for which need to change status
   */
  const handleChangeStatus = (seq_id: string): void => {
    const onSuccess = ({ data: response }: AxiosResponse<InputCommandData>) => {
      if (response?.status) {
        toast.success(response?.message || "Command status changed successfully.");
        fetchCustomCommand(false);
      } else {
        toast.error(response?.message);
      }
    };

    http.makeGetRequest<InputCommandData, { message: string }>(enableDisableInputCommand + seq_id, onSuccess, (error) => {
      toast.error(error?.response?.data?.message);
    });
  };

  /**
   * Confirmation callback for delete confirmation
   * Calls /delete-input-command API for removing entity from database
   */
  const handleDeleteCommand = (): void => {
    const onSuccess = ({ data: response }: AxiosResponse<InputCommandData>) => {
      if (response?.status) {
        fetchCustomCommand(false);
        toast.success(response?.message || "Custom command deleted successfully.");
      } else {
        toast.error(response?.message);
      }
    };

    http.makeDeleteRequest<InputCommandData, { message: string }>(deleteInputCommand + deleteCommandId, onSuccess, (error) => {
      toast.error(error?.response?.data?.message);
    });
    setDeleteCommandId(null);
  };

  /**
   * Fetches list of custom commands
   * Calls /get-input-commands API
   * @param loading - depending on this parameter loader is displayed on UI
   */
  const fetchCustomCommand = (loading = true) => {
    setIsLoading(loading);
    const onSuccess = ({ data: response }: AxiosResponse<CustomCommandData>) => {
      setCustomCommandList(response?.data);
      setIsLoading(false);
    };

    http.makeGetRequest(getInputCommands, onSuccess, () => {
      setIsLoading(false);
    });
  };

  /**
   * Callback function for createCommand CreateCommandDialog
   * Refreshes list data to show new created item via the API
   */
  const createOrEditCommandCallback = () => {
    fetchCustomCommand(false);
    setAddDialogVisible(false);
  };

  useEffect(() => {
    fetchCustomCommand();
  }, []);

  // List of custom commands queried by search text
  const searchResult = useMemo(() => {
    return customCommandList.filter(
      (item: CustomCommand) =>
        item?.cust_command_name?.toLowerCase()?.includes(searchText.toLowerCase()) ||
        item?.cust_command_description?.toLowerCase()?.includes(searchText.toLowerCase())
    );
  }, [customCommandList, searchText]);

  /**
   * Click handler for edit button
   * Prepares values for EditCommandDialog
   */
  const handleClickEdit = useCallback((command: CustomCommand) => {
    setEditCommandDetails({ ...command, passing_values: command.passing_values.map((item) => item.value) });
  }, []);

  // Give tooltip id for show in disabled custom command toggle
  const getTooltipId = useCallback((command: CustomCommand) => {
    switch (true) {
      case command.miss_match_count > 0 && !command.enabled:
        return "vendor-miss-match-tooltip";
      case command.use_count > 0 && command.enabled:
        return "switch-tooltip";
      default:
        return "";
    }
  }, []);

  return (
    <div className={styles.pageContainer}>
      <div className={`${styles.pageHeader} d-flex justify-content-between`}>
        <p>Manage Custom Command</p>
        <div className={`${styles.headerActions} d-flex justify-content-between align-item-center`}>
          <input type="text" value={searchText} placeholder="Search Command" onChange={(e) => setSearchText(e.target.value)} />
          <button onClick={() => setAddDialogVisible(true)}>Add</button>
        </div>
      </div>
      <div className={styles.seqListContainer}>
        {isLoading ? (
          <Spinner />
        ) : searchResult?.length ? (
          searchResult.map((item: CustomCommand) => (
            <div className={styles.seqItemContainer} key={item?.cust_command_id}>
              <p>{item?.cust_command_name}</p>
              <AccessGuard
                permission={[
                  PERMISSIONS.CUSTOM_COMMAND_UPDATE,
                  PERMISSIONS.CUSTOM_COMMAND_DELETE,
                  PERMISSIONS.CUSTOM_COMMAND_ENABLE_DISABLE,
                ]}>
                <div className={styles.seqItemActions}>
                  <AccessGuard permission={PERMISSIONS.CUSTOM_COMMAND_UPDATE}>
                    <Icons.EditIcon onClick={() => handleClickEdit(item)} />
                  </AccessGuard>
                  <AccessGuard permission={PERMISSIONS.CUSTOM_COMMAND_DELETE}>
                    <>
                      {/* Not allowed to delete or disable if the command is in use by any custom sequence */}
                      <Tooltip
                        id="delete-tooltip"
                        delayHide={2000}
                        style={{ maxWidth: 300 }}
                        className={styles.tooltip}
                        render={() => {
                          return (
                            <>
                              This custom command can not be deleted as it is already in use. Please go to{" "}
                              <Link to="/manage-custom-seq">Manage Custom Sequences</Link> and review Sequences for this command and remove
                              it from those Sequences
                            </>
                          );
                        }}
                      />
                      <Icons.TrashIcon
                        onClick={() => !(item.use_count > 0) && setDeleteCommandId(item?.cust_command_id)}
                        className={item.use_count > 0 ? styles.disabled : ""}
                        id="delete-tooltip"
                        data-tooltip-id={item.use_count > 0 ? "delete-tooltip" : ""}
                        data-tooltip-place="bottom"
                      />
                    </>
                  </AccessGuard>
                  <AccessGuard permission={PERMISSIONS.CUSTOM_COMMAND_ENABLE_DISABLE}>
                    <>
                      <Tooltip
                        id="switch-tooltip"
                        delayHide={2000}
                        style={{ maxWidth: 300 }}
                        className={styles.tooltip}
                        render={() => {
                          return (
                            <>
                              This custom command can not be disabled as it is already in use. Please go to{" "}
                              <Link to="/manage-custom-seq">Manage Custom Sequences</Link> and review Sequences for this command and remove
                              it from those Sequences
                            </>
                          );
                        }}
                      />

                      <Tooltip
                        id="vendor-miss-match-tooltip"
                        delayHide={2000}
                        style={{ maxWidth: 300 }}
                        className={styles.tooltip}
                        render={() => {
                          return (
                            <>
                              This custom command cannot be enabled due to a mismatch between the vendors of the custom sequence and
                              command. Please visit <Link to="/manage-custom-seq">Manage Custom Sequences</Link> and ensure that both the
                              custom sequence and command have the same vendor to enable this command.
                            </>
                          );
                        }}
                      />

                      <Switch
                        checked={item?.enabled}
                        onChange={() =>
                          !(item.use_count > 0 && item.enabled) &&
                          !(item.miss_match_count > 0 && !item.enabled) &&
                          handleChangeStatus(item?.cust_command_id)
                        }
                        disabled={(item.use_count > 0 && item.enabled) || (item.miss_match_count > 0 && !item.enabled)}
                        id="switch-tooltip"
                        data-tooltip-id={getTooltipId(item)}
                        data-tooltip-place="bottom"
                      />
                    </>
                  </AccessGuard>
                </div>
              </AccessGuard>
            </div>
          ))
        ) : (
          <div className={`${styles.emptyPage} d-flex justify-content-center align-item-center`}>
            <span className={styles.noDataFound}>No Command Found</span>
          </div>
        )}
      </div>
      {deleteCommandId ? (
        <DeleteConfirmation
          title="Delete Command"
          description="Are you sure you want to delete the command?"
          onConfirmClick={handleDeleteCommand}
          onDeclineClick={() => setDeleteCommandId(null)}
        />
      ) : null}
      {addDialogVisible ? (
        <CreateCommandDialog callback={createOrEditCommandCallback} onCancelClick={() => setAddDialogVisible(false)} />
      ) : null}
      {editCommandDetails && editCommandDetails?.cust_command_id ? (
        <EditCommandDialog
          id={editCommandDetails?.cust_command_id}
          initialValues={editCommandDetails}
          callback={createOrEditCommandCallback}
          onCancelClick={() => setEditCommandDetails(null)}
        />
      ) : null}
    </div>
  );
};

export default ManageCustomCommand;
