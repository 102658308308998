import { Tooltip } from "react-tooltip";
import styles from "./styles.module.scss";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
}

/**
 * Checkbox component
 * @param checked Controlled checked state
 * @param onChange onChange callback
 * @param label Label for checkbox
 */
const Checkbox = ({ checked, onChange, label, ...props }: Props) => {
  return (
    <>
      <Tooltip id={props.name} />
      <label className={`${styles.checkbox} ${props.disabled ? styles.disabled : ""}`}>
        <input type="checkbox" checked={checked} onChange={onChange} {...props} />
        <span className={styles.indicator}></span>
        <span
          className={styles.checkboxLabel}
          id={props.name}
          data-tooltip-id={props.name}
          data-tooltip-content={label}
          data-tooltip-place="top">
          {label}
        </span>
      </label>
    </>
  );
};

export default Checkbox;
