import { Formik, FormikHelpers } from "formik";
import { ManageCertAppVersionFormInitValues } from "../../interfaces/fe.interfaces";
import Form from "./Form";
import styles from "./style.module.scss";
import { validateAppUploadForm } from "../../utils/validationSchemas";
import { AxiosResponse } from "axios";
import { CertAppVersion, UpdateItemResponse } from "../../interfaces/be.interfaces";
import { toast } from "react-toastify";
import { http } from "../../api/utils/http";
import { uploadApkVersion } from "../../api/config";

const initialValues: ManageCertAppVersionFormInitValues = {
  version_no: "",
  description: "",
  file: null,
  is_latest_version: false,
};

interface AddCertVersionProps {
  callback: () => void;
  handleOnClose: () => void;
}

/**
 * Component for upload new cert app versions
 */
const AddCertVersion = ({ handleOnClose, callback }: AddCertVersionProps) => {
  const handleUploadApk = (
    values: ManageCertAppVersionFormInitValues,
    { setSubmitting }: FormikHelpers<ManageCertAppVersionFormInitValues>
  ): void => {
    if (!values.file) return;

    const formData = new FormData();

    // Prepare form data for API payload
    formData.append("version_no", values.version_no);
    formData.append("is_latest_version", values.is_latest_version ? "true" : "false");
    formData.append("description", values.description);
    formData.append("cert_app_version", values.file);

    // Success callback for /add-amp-config API
    const onSuccess = ({ data: response }: AxiosResponse<UpdateItemResponse<CertAppVersion>>) => {
      setSubmitting(false);
      if (response?.status) {
        // Display success toast and call parent callback to refresh the table
        toast.success(response?.message);
        callback();

        // Close the dialog
        handleOnClose();
      } else {
        toast.error(response?.message);
      }
    };

    // Calls /add-amp-config API
    http.makePostRequestWithFormData<UpdateItemResponse<CertAppVersion>, { message: string }>(
      uploadApkVersion,
      onSuccess,
      (error) => {
        setSubmitting(false);
        toast.error(error?.response?.data?.message);
      },
      formData
    );
  };

  return (
    <div className={styles.dialogContainer}>
      <Formik initialValues={initialValues} validationSchema={validateAppUploadForm} onSubmit={handleUploadApk}>
        {(props) => <Form {...props} handleOnClose={handleOnClose} />}
      </Formik>
    </div>
  );
};

export default AddCertVersion;
