import ProductConfigDropdowns, { ProductConfigDropdownsProps } from "../../../components/ProductConfigDropdowns";
import styles from "../styles.module.scss";

export interface ModuleProgrammingRemoteCommandsProps extends ProductConfigDropdownsProps {
  isSequenceRunning: boolean;
  macAddresses: string[];
  isStartExecutionButtonDisabled: boolean;
  onClickStartExecution: () => void;
}

const ModuleProgrammingRemoteCommands = ({
  deviceConfigurationPayload,
  setDeviceConfigurationPayload,
  customersList,
  productList,
  modelList,
  updatePayloadValue,
  regions,
  isRegionEnabled,
  isSpeakerPositionEnabled,
  isSequenceRunning,
  showAsscModeCheckbox,
  changeAsscModeInRemote,
  setChangeAsscModeInRemote,
  macAddresses,
  isStartExecutionButtonDisabled,
  onClickStartExecution,
  beProductList,
  setDeviceType,
}: ModuleProgrammingRemoteCommandsProps) => {
  return (
    <div className={`w-100 d-flex justify-content-between ${styles.deviceInfo} ${styles.multiMac}`}>
      <ProductConfigDropdowns
        deviceConfigurationPayload={deviceConfigurationPayload}
        setDeviceConfigurationPayload={setDeviceConfigurationPayload}
        customersList={customersList}
        productList={productList}
        modelList={modelList}
        updatePayloadValue={updatePayloadValue}
        regions={regions ?? []}
        isSequenceRunning={isSequenceRunning}
        isRegionEnabled={isRegionEnabled}
        isSpeakerPositionEnabled={isSpeakerPositionEnabled}
        showAsscModeCheckbox={showAsscModeCheckbox}
        changeAsscModeInRemote={changeAsscModeInRemote}
        setChangeAsscModeInRemote={setChangeAsscModeInRemote}
        beProductList={beProductList}
        setDeviceType={setDeviceType}
      />
      <div className={`d-flex ${styles.infoboxContainer}`}>
        <span>Scanned Mac Addresses</span>
        <div className={styles.macAddressContainer}>
          {new Array(9).fill(0).map((_, index) => (
            <label key={index}>{macAddresses[index] ?? (index === 0 ? "WiSA Now MAC" : "Remote MAC")}</label>
          ))}
          <button onClick={onClickStartExecution} disabled={isStartExecutionButtonDisabled}>
            Start Execution
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModuleProgrammingRemoteCommands;
