import { useCallback, useContext } from "react";
import { AppContext } from "../context/AppContext";
import { CUSTOMER_MODULES, PERMISSIONS } from "../utils/constants";

/**
 * Use this hook to get user roles(Permissions) and update it
 * @returns {PERMISSIONS} Permission
 * @returns {Function} function for check that user has permission
 */
export const usePermission = () => {
  const appContext = useContext(AppContext);

  /**
   * Check that user has permission, if user permissions are not found return false
   * @param {PERMISSIONS} roleForCheck Permission for check
   */
  const checkPermission = useCallback(
    (roleForCheck: PERMISSIONS) => {
      return appContext?.user?.role?.permission?.includes(roleForCheck) ?? false;
    },
    [appContext?.user?.role?.permission]
  );

  /**
   * Check that user's customer has module access or not,
   * if this module access not found for that customer
   * then it returns false
   * @param {CUSTOMER_MODULES} moduleForCheck Module for check
   */
  const checkModuleAccess = useCallback(
    (moduleForCheck: CUSTOMER_MODULES) => {
      const customerModules = appContext?.user?.customer?.customer_modules?.modules;

      // Return true or false
      return customerModules?.includes(moduleForCheck) ?? false;
    },
    [appContext?.user?.customer?.customer_modules]
  );

  return {
    permissions: appContext?.user?.role?.permission ?? [],
    modules: appContext?.user?.customer?.customer_modules?.modules ?? [],
    checkPermission,
    checkModuleAccess,
  };
};
