import { ReactNode, useEffect, useCallback, useState, useContext } from "react";
import styles from "./styles.module.scss";
import { useCustomerContext } from "../../hooks/useCustomerContext";
import { Icons } from "../../../assets/images/index";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../../hooks/useFetch";
import { getModelsList } from "../../api/config";
import { ProductInfo } from "../../interfaces/be.interfaces";
import { initialContext } from "../../hooks/useCustomerContext";
import { http } from "../../api/utils/http";
import ConfirmationDialog from "../ConfirmationDialog";
import { AppContext } from "../../context/AppContext";

interface HeaderProps {
  headerLeft?: ReactNode;
}

const Header = (props: HeaderProps) => {
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const { context, updateContext } = useCustomerContext();
  const [logoutModelVisible, setLogoutModelVisible] = useState(false);

  // Fetch details of currently selected context's product and model
  // Remove context if product or model is not active or not found
  const { data, run, error } = useFetch<ProductInfo>({
    url: `${getModelsList}/${context?.customerProductId}`,
    runOnMount: false,
    onSuccess: ({ data: response }) => {
      // Checking if model is active and is present in the list of models
      const modelFound =
        response?.data?.customer_product_models?.findIndex(
          (model) => model.customer_product_model_id === context?.customerProductModelId && model.is_enable === true
        ) !== -1;

      // Checking if product and customer are active
      const isCustomerActive = response?.data?.customer?.is_enable;
      const isProductActive = response?.data?.is_enable;
      const isVendorIdValid = response?.data?.vendor_id === context?.vendorId;

      // If any of the context's items are not active or not found, remove context
      if (!modelFound || !isCustomerActive || !isProductActive || !isVendorIdValid) {
        updateContext(initialContext);
        navigate("/");
      } else {
        updateContext({
          ...context,
          customerName: response?.data?.customer?.customer_name,
          productName: response?.data?.product_name,
          modelDescription:
            response?.data?.customer_product_models?.find((model) => model.customer_product_model_id === context?.customerProductModelId)
              ?.model_description || "",
        });
      }
    },
    onError: (e) => {
      // Remove the context if there is an error from the API while fetching the info
      if (e?.response?.status === 400) {
        updateContext(initialContext);
        navigate("/");
      }
    },
  });

  useEffect(() => {
    // call API for getting details only one time on load of page, when context is available
    if (!data && context?.customerProductId && !error) {
      run({
        url: `${getModelsList}/${context?.customerProductId}`,
      });
    }
  }, [context, data, run, error]);

  // This will clear the local storage and navigate the user to login screen
  const logout = useCallback(() => {
    // Remove auth token and mac address
    http.setAuthToken("");
    http.setSequenceToken("");

    // Clear local storage
    localStorage.clear();

    // Navigate to login screen
    navigate("/login");
  }, []);

  return (
    <>
      <div className={styles.header}>
        {props?.headerLeft ? <div className={styles.col}>{props?.headerLeft}</div> : null}
        {context?.customerName ? (
          <div className={styles.col}>
            <span className={styles.context}>
              <span>{context.customerName}</span>
              <br />
              <span>{context.productName}</span>
              <br />
              <span>
                {context.modelId} - {context.modelDescription ? context.modelDescription : null}
              </span>
              <Icons.EditIcon className={styles.trash} onClick={() => navigate("/")} />
            </span>
          </div>
        ) : null}
        <div className={styles.col}>
          <div className={styles.profileDropdown}>
            <div className={styles.dropdown}>
              <div>{appContext?.user?.name}</div>
              <Icons.ChevronLeft className={styles.arrow} />
            </div>
            <div className={styles.dropdownContent}>
              <div className={styles.logoutBtn} onClick={() => setLogoutModelVisible(true)}>
                Logout
              </div>
            </div>
          </div>
        </div>
      </div>
      {logoutModelVisible ? (
        <ConfirmationDialog
          title="Logout"
          description="Are you sure you want to logout?"
          onConfirmClick={logout}
          onDeclineClick={() => setLogoutModelVisible(false)}
        />
      ) : null}
    </>
  );
};

export default Header;
