import styles from "./styles.module.scss";
import { FormikProps } from "formik";
import { ChangeEvent, useCallback } from "react";
import { ExpressTxConfigurationsFormModel, ExpressTxConfigurationsModel } from "../../../interfaces/fe.interfaces";
import { EXPRESS_DECODE_OPTIONS_ENUM, LOCAL_SPEAKERS_ENUM } from "../../../utils/constants";
import Select from "../../../components/Select";
import Checkbox from "../../../components/Checkbox";

const haveSelectedCheckboxValue = (
  formValues: ExpressTxConfigurationsFormModel,
  fieldsetName: ExpressTxConfigurationsModel,
  checkboxValue: string
) => {
  return formValues[fieldsetName]?.includes(checkboxValue) ?? false;
};

const ManageProductExpressTxConfigurationsCustomerHardware = ({
  values,
  setFieldValue,
  touched,
  errors,
  handleBlur,
  handleChange,
}: FormikProps<ExpressTxConfigurationsFormModel>) => {
  const handleSelectChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      setFieldValue(e.target.name, e.target.value);
    },
    [setFieldValue]
  );

  const hanldeRadioChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      let val: string | boolean = e.target.value;
      if (val === "true") {
        val = true;
      } else if (val === "false") {
        val = false;
      }
      setFieldValue(e.target.name, val);
    },
    [setFieldValue]
  );

  const handleLocalSpeakersCheckboxChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const isChecked = e.target.checked;
      if (isChecked) {
        values.local_speakers = values.local_speakers ? [...values.local_speakers, e.target.name] : [e.target.name];
      } else {
        values.local_speakers = values.local_speakers ? values.local_speakers.filter((speaker) => speaker !== e.target.name) : [];
      }
      setFieldValue(e.target.name, values.local_speakers);
    },
    [setFieldValue, values]
  );

  const handleOsCheckboxChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const isChecked = e.target.checked;
      if (isChecked) {
        values.os_versions = values.os_versions ? [...values.os_versions, e.target.name] : [e.target.name];
      } else {
        values.os_versions = values.os_versions ? values.os_versions.filter((os) => os !== e.target.name) : [];
      }
      setFieldValue(e.target.name, values.os_versions);
    },
    [setFieldValue, values]
  );

  const handleDecodeOptionsCheckboxChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const isChecked = e.target.checked;
      if (isChecked) {
        values.decode_options = values.decode_options ? [...values.decode_options, e.target.name] : [e.target.name];
      } else {
        values.decode_options = values.decode_options ? values.decode_options.filter((decodeOption) => decodeOption !== e.target.name) : [];
      }
      setFieldValue(e.target.name, values.decode_options);
    },
    [setFieldValue, values]
  );

  return (
    <div>
      <div className={styles.header}>Customer Hardware</div>
      <div className={styles.section}>
        <div>
          <div className={styles.inputItem}>
            <label>Device Type</label>
            <Select
              selectProps={{
                name: "dev_type",
                value: values.dev_type,
                placeholder: "Select device type",
                onChange: handleSelectChange,
                onBlur: handleBlur,
              }}
              options={[
                { label: "TV", value: "tv" },
                { label: "Set top box", value: "set_top_box" },
                { label: "Projector", value: "projector" },
                { label: "Tablet", value: "tablet" },
                { label: "Phone", value: "phone" },
              ]}
            />
            {touched?.dev_type && errors?.dev_type && <span className={styles.error}>{errors?.dev_type}</span>}
          </div>
          <div className={styles.inputItem}>
            <label>Local Speakers</label>
            <div className={styles.checkboxContainer}>
              <Checkbox
                label="None"
                name={LOCAL_SPEAKERS_ENUM.NONE_LOCAL}
                checked={haveSelectedCheckboxValue(values, "local_speakers", LOCAL_SPEAKERS_ENUM.NONE_LOCAL)}
                onChange={handleLocalSpeakersCheckboxChange}
              />
              <Checkbox
                label="Front Left"
                name={LOCAL_SPEAKERS_ENUM.FRONT_LEFT_LOCAL}
                checked={haveSelectedCheckboxValue(values, "local_speakers", LOCAL_SPEAKERS_ENUM.FRONT_LEFT_LOCAL)}
                onChange={handleLocalSpeakersCheckboxChange}
              />
              <Checkbox
                label="Front Right"
                name={LOCAL_SPEAKERS_ENUM.FRONT_RIGHT_LOCAL}
                checked={haveSelectedCheckboxValue(values, "local_speakers", LOCAL_SPEAKERS_ENUM.FRONT_RIGHT_LOCAL)}
                onChange={handleLocalSpeakersCheckboxChange}
              />
              <Checkbox
                label="Center"
                name={LOCAL_SPEAKERS_ENUM.CENTER_LOCAL}
                checked={haveSelectedCheckboxValue(values, "local_speakers", LOCAL_SPEAKERS_ENUM.CENTER_LOCAL)}
                onChange={handleLocalSpeakersCheckboxChange}
              />
              <Checkbox
                label="Subwoofer"
                name="local_speaker_subwoofer"
                checked={haveSelectedCheckboxValue(values, "local_speakers", "local_speaker_subwoofer")}
                onChange={handleLocalSpeakersCheckboxChange}
              />
              <Checkbox
                label="Height Front Right"
                name={LOCAL_SPEAKERS_ENUM.HEIGHT_FRONT_RIGHT_LOCAL}
                checked={haveSelectedCheckboxValue(values, "local_speakers", LOCAL_SPEAKERS_ENUM.HEIGHT_FRONT_RIGHT_LOCAL)}
                onChange={handleLocalSpeakersCheckboxChange}
              />
              <Checkbox
                label="Height Front Left"
                name={LOCAL_SPEAKERS_ENUM.HEIGHT_FRONT_LEFT_LOCAL}
                checked={haveSelectedCheckboxValue(values, "local_speakers", LOCAL_SPEAKERS_ENUM.HEIGHT_FRONT_LEFT_LOCAL)}
                onChange={handleLocalSpeakersCheckboxChange}
              />
            </div>
            {touched?.local_speakers && errors?.local_speakers && <span className={styles.error}>{errors?.local_speakers}</span>}
          </div>
        </div>
        <div>
          <div className={styles.inputItem}>
            <label>Operating System</label>
            <Select
              selectProps={{
                name: "operating_system",
                value: values.operating_system,
                placeholder: "Select operating system",
                onChange: handleSelectChange,
                onBlur: handleBlur,
              }}
              options={[
                { label: "Android", value: "operating_system_android" },
                { label: "Linux", value: "operating_system_linux" },
                { label: "iOS", value: "operating_system_ios" },
                { label: "Fire TV", value: "operating_system_fire_tv" },
                { label: "Web OS", value: "operating_system_web_os" },
                { label: "Tiezen OS", value: "operating_system_tiezen_os" },
              ]}
            />
            {touched?.operating_system && errors?.operating_system && <span className={styles.error}>{errors?.operating_system}</span>}
          </div>
          <div className={styles.inputItem}>
            <label>OS Version</label>
            <div className={styles.checkboxContainer}>
              <Checkbox
                label="OS Android v11"
                name="os_android_11"
                checked={haveSelectedCheckboxValue(values, "os_versions", "os_android_11")}
                onChange={handleOsCheckboxChange}
              />
              <Checkbox
                label="OS Android v12"
                name="os_android_12"
                checked={haveSelectedCheckboxValue(values, "os_versions", "os_android_12")}
                onChange={handleOsCheckboxChange}
              />
              <Checkbox
                label="OS Android v13"
                name="os_android_13"
                checked={haveSelectedCheckboxValue(values, "os_versions", "os_android_13")}
                onChange={handleOsCheckboxChange}
              />
              <Checkbox
                label="OS Android v14"
                name="os_android_14"
                checked={haveSelectedCheckboxValue(values, "os_versions", "os_android_14")}
                onChange={handleOsCheckboxChange}
              />
              <Checkbox
                label="OS Other"
                name="os_other"
                checked={haveSelectedCheckboxValue(values, "os_versions", "os_other")}
                onChange={handleOsCheckboxChange}
              />
              {haveSelectedCheckboxValue(values, "os_versions", "os_other") && (
                <div className={styles.inputItem}>
                  <input
                    type="text"
                    placeholder="OS Other"
                    value={values.os_other_text}
                    name="os_other_text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {touched?.os_other_text && errors?.os_other_text && <span className={styles.error}>{errors?.os_other_text}</span>}
                </div>
              )}
            </div>
            {touched?.os_versions && errors?.os_versions && <span className={styles.error}>{errors?.os_versions}</span>}
          </div>
        </div>
        <div>
          <div className={styles.inputItem}>
            <label>Audio HAL</label>
            <Select
              selectProps={{
                name: "audio_hal",
                value: values.audio_hal,
                placeholder: "Select audio HAL",
                onChange: handleSelectChange,
                onBlur: handleBlur,
              }}
              options={[
                { label: "Android", value: "audio_hal_android" },
                { label: "Mediatek Android HAL", value: "audio_hal_mediatek_android_hal" },
                { label: "Tiny ALSA", value: "audio_hal_mediatek_tiny_alsa" },
                { label: "ALSA", value: "audio_hal_alsa" },
                { label: "Other", value: "audio_hal_other" },
              ]}
            />
            {values.audio_hal === "audio_hal_other" && (
              <div className={styles.inputItem}>
                <input
                  type="text"
                  placeholder="Audio HAL"
                  value={values.audio_hal_other_text}
                  name="audio_hal_other_text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched?.audio_hal_other_text && errors?.audio_hal_other_text && (
                  <span className={styles.error}>{errors?.audio_hal_other_text}</span>
                )}
              </div>
            )}
            {touched?.audio_hal && errors?.audio_hal && <span className={styles.error}>{errors?.audio_hal}</span>}
          </div>
          <div className={styles.inputItem}>
            <label>Binder?</label>
            <div>
              <input
                id="binder_true"
                type="radio"
                name="binder"
                value="true"
                checked={values.binder === true}
                onChange={hanldeRadioChange}
                onBlur={handleBlur}
              />
              <label htmlFor="binder_true">Yes</label>
            </div>
            <div>
              <input
                id="binder_false"
                type="radio"
                name="binder"
                value="false"
                checked={values.binder === false}
                onChange={hanldeRadioChange}
                onBlur={handleBlur}
              />
              <label htmlFor="binder_true">No</label>
            </div>
            {touched?.binder && errors?.binder && <span className={styles.error}>{errors?.binder}</span>}
          </div>
        </div>
        <div className={styles.inputItem}>
          <label>Decode Options</label>
          <div className={styles.checkboxContainer}>
            <Checkbox
              label="2.0"
              name={EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_2_0}
              checked={haveSelectedCheckboxValue(values, "decode_options", EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_2_0)}
              onChange={handleDecodeOptionsCheckboxChange}
            />
            <Checkbox
              label="2.1"
              name={EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_2_1}
              checked={haveSelectedCheckboxValue(values, "decode_options", EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_2_1)}
              onChange={handleDecodeOptionsCheckboxChange}
            />
            <Checkbox
              label="2.1.2"
              name={EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_ATMOS_2_1_2}
              checked={haveSelectedCheckboxValue(values, "decode_options", EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_ATMOS_2_1_2)}
              onChange={handleDecodeOptionsCheckboxChange}
            />
            <Checkbox
              label="3.1"
              name={EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_3_1}
              checked={haveSelectedCheckboxValue(values, "decode_options", EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_3_1)}
              onChange={handleDecodeOptionsCheckboxChange}
            />
            <Checkbox
              label="3.1.2"
              name={EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_ATMOS_3_1_2}
              checked={haveSelectedCheckboxValue(values, "decode_options", EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_ATMOS_3_1_2)}
              onChange={handleDecodeOptionsCheckboxChange}
            />
            <Checkbox
              label="4.1"
              name={EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_4_1}
              checked={haveSelectedCheckboxValue(values, "decode_options", EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_4_1)}
              onChange={handleDecodeOptionsCheckboxChange}
            />
            <Checkbox
              label="5.1"
              name={EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_5_1}
              checked={haveSelectedCheckboxValue(values, "decode_options", EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_5_1)}
              onChange={handleDecodeOptionsCheckboxChange}
            />
            <Checkbox
              label="5.1.2"
              name={EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_DONGLE_HEIGHT_FRONTS_5_1_2}
              checked={haveSelectedCheckboxValue(values, "decode_options", EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_DONGLE_HEIGHT_FRONTS_5_1_2)}
              onChange={handleDecodeOptionsCheckboxChange}
            />
            <Checkbox
              label="5.1.4"
              name={EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_5_1_4}
              checked={haveSelectedCheckboxValue(values, "decode_options", EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_5_1_4)}
              onChange={handleDecodeOptionsCheckboxChange}
            />
            <Checkbox
              label="7.1"
              name={EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_7_1}
              checked={haveSelectedCheckboxValue(values, "decode_options", EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_7_1)}
              onChange={handleDecodeOptionsCheckboxChange}
            />
            <Checkbox
              label="7.1.2"
              name={EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_7_1_2}
              checked={haveSelectedCheckboxValue(values, "decode_options", EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_7_1_2)}
              onChange={handleDecodeOptionsCheckboxChange}
            />
            <Checkbox
              label="7.1.4"
              name={EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_7_1_4}
              checked={haveSelectedCheckboxValue(values, "decode_options", EXPRESS_DECODE_OPTIONS_ENUM.EXPRESS_7_1_4)}
              onChange={handleDecodeOptionsCheckboxChange}
            />
          </div>
          {touched?.decode_options && errors?.decode_options && <span className={styles.error}>{errors?.decode_options}</span>}
        </div>
      </div>
    </div>
  );
};

export { ManageProductExpressTxConfigurationsCustomerHardware };
