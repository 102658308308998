import { ChangeEvent } from "react";
import InfoBox from "../InfoBox";
import styles from "../styles.module.scss";
import { WisaProductModel } from "../../../interfaces/be.interfaces";
import Select from "../../../components/Select";

export interface ModuleProgrammingSpeakerTestingProps {
  scannedMac: string;
  wisaProducts: WisaProductModel[];
  deviceType: string;
  onChangeWisaProduct: (event: ChangeEvent<HTMLSelectElement>) => void;
  isSequenceRunning: boolean;
}

const ModuleProgrammingSpeakerTesting = ({
  scannedMac,
  wisaProducts,
  deviceType,
  onChangeWisaProduct,
  isSequenceRunning,
}: ModuleProgrammingSpeakerTestingProps) => {
  return (
    <div className={`w-100 d-flex justify-content-between ${styles.deviceInfo}`}>
      <div className={`d-flex ${styles.infoboxContainer}`}>
        <span>WiSA Product Type:</span>
        <Select
          selectProps={{
            name: "wisa_product",
            placeholder: "WiSA Product",
            value: deviceType,
            onChange: onChangeWisaProduct,
            className: styles.customSelectBox,
            disabled: isSequenceRunning,
          }}
          options={wisaProducts}
          optionLabel="type"
          optionValue="type"
        />
      </div>
      <InfoBox className={styles.scannedMacInfoBox} title="Scanned MAC:" value={scannedMac || ""} />
    </div>
  );
};

export default ModuleProgrammingSpeakerTesting;
