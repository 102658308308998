import { FormikProps } from "formik";
import { ChangeEvent, useCallback } from "react";
import { ManageCertAppVersionFormInitValues } from "../../interfaces/fe.interfaces";
import { ReactComponent as UploadFileIcon } from "../../../assets/images/uploadFile.svg";
import Spinner from "../Spinner";
import styles from "./style.module.scss";
import Checkbox from "../Checkbox";

interface FormProps extends FormikProps<ManageCertAppVersionFormInitValues> {
  handleOnClose: () => void;
}

const Form = ({
  values,
  errors,
  touched,
  setFieldValue,
  handleSubmit,
  isSubmitting,
  enableReinitialize,
  handleChange,
  handleBlur,
  setFieldTouched,
  handleOnClose,
}: FormProps) => {
  const onChangeVersion = useCallback((value: string) => {
    const regEx = /^[0-9.]+$/;

    // If value is empty or number or .
    if (regEx.test(value) || value === "") setFieldValue("version_no", value);
  }, []);

  const onApkFileChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setFieldTouched("file", true);
    setFieldValue("file", event.target.files?.[0], true);
  }, []);

  return (
    <form className={styles.appUpload} onSubmit={handleSubmit}>
      <div className={styles.dialog}>
        {isSubmitting ? (
          <Spinner />
        ) : (
          <>
            <div className="w-100 d-flex justify-content-around">
              <div className={`${styles.inputContainer} d-flex justify-content-between align-item-center`}>
                <input
                  id="version_no"
                  name="version_no"
                  type="text"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeVersion(e?.target?.value)}
                  placeholder="App version"
                  value={values.version_no}
                />
                {errors?.version_no && touched.version_no ? <p className={styles.error}>{errors?.version_no}</p> : null}
              </div>
              <div className={`${styles.inputContainer} d-flex justify-content-between align-item-center`}>
                <input
                  name="description"
                  type="text"
                  placeholder="Description"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                />
                {errors?.description && touched.description ? <p className={styles.error}>{errors?.description}</p> : null}
              </div>
            </div>
            <div className="w-100 d-flex justify-content-around">
              <div className={`${styles.inputContainer} d-flex justify-content-between align-item-center`}>
                <div className={styles.appUploadFormGridItem}>
                  <p className={styles.appUploadFormIconText}>Choose APK File</p>
                  <label className={styles.appUploadFormIconLabel} htmlFor="file">
                    <input id="file" name="file" type="file" onChange={onApkFileChange} hidden accept=".apk" />
                    <UploadFileIcon className={styles.appUploadFormUploadIcon} />
                    <div className={styles.appUploadFormUploadMessagesWrapper}>
                      {values.file?.name && <p className={styles.appUploadFormFileName}>{values.file?.name}</p>}
                      {touched.file && errors.file && <span className={styles.error}>{errors.file}</span>}
                    </div>
                  </label>
                </div>
              </div>
            </div>

            <div className="w-100 d-flex justify-content-around">
              <div className={`${styles.inputContainer} d-flex justify-content-between align-item-center`}>
                <Checkbox
                  label="Use this version as latest version?"
                  name="is_latest_version"
                  checked={values.is_latest_version}
                  disabled={enableReinitialize}
                  onChange={() => !enableReinitialize && setFieldValue("is_latest_version", !values.is_latest_version)}
                />
              </div>
            </div>

            <div className="w-100 d-flex justify-content-around">
              <button className={styles.dialogBtn} type="submit" disabled={isSubmitting}>
                {enableReinitialize ? "Update" : "Add"}
              </button>
              <button type="button" className={styles.dialogBtn} onClick={handleOnClose}>
                Cancel
              </button>
            </div>
          </>
        )}
      </div>
    </form>
  );
};

export default Form;
