import { ReactNode, useContext } from "react";

import Aside from "../Aside";
import Header from "../Header";
import Footer from "../Footer";

import styles from "./styles.module.scss";
import { AppContext } from "../../context/AppContext";
import Spinner from "../Spinner";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { TOAST_TIMEOUT } from "../../utils/constants";
import { useCustomerContext } from "../../hooks/useCustomerContext";
interface LayoutProps {
  children: ReactNode;
  headerLeft?: ReactNode;
}

const Layout = ({ children, headerLeft }: LayoutProps) => {
  const appContext = useContext(AppContext);
  const { context } = useCustomerContext();

  return (
    <>
      {appContext?.isLoading ? (
        <div className={styles.loaderContainer}>
          <Spinner />
        </div>
      ) : (
        <div className={styles.wrapper}>
          <Aside />
          <div className={`${styles.contentWrapper}`}>
            <Header headerLeft={headerLeft} />
            <section className={`${styles.pageWrapper} ${context?.customerName ? styles.contextSelected : ""}`}>{children}</section>
            <Footer />
          </div>
        </div>
      )}
      <ToastContainer newestOnTop autoClose={TOAST_TIMEOUT} />
    </>
  );
};

export default Layout;
