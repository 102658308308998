import styles from "./styles.module.scss";

/**
 * @interface SpinnerProps
 * @property {string} [className] - Optional class name to be applied to the spinner
 */
interface SpinnerProps {
  className?: string;
}

/**
 * This component is responsible for showing spinner
 */
const Spinner = ({ className }: SpinnerProps) => {
  return <div className={`${styles.loader} ${className ? className : ""}`}>Loading...</div>;
};

export default Spinner;
