/**
 * Returns string formatted as GET query params from object
 * @param {object} obj - key-value pair to generate GET query params
 * @returns {string}
 */
export const serializeQueryParams = (obj: Record<string, any>): string => {
  if (obj) {
    return `?${Object.keys(obj)
      .map((k) => {
        if (typeof obj[k] === "object") {
          return obj[k] && obj[k].map((v: string) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`).join("&");
        }
        return `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`;
      })
      .join("&")}`;
  }
  return "";
};
