import { AxiosResponse } from "axios";
import { Formik, FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { addChimeConfig } from "../../api/config";
import { http } from "../../api/utils/http";
import { ChimeConfiguration, UpdateItemResponse } from "../../interfaces/be.interfaces";
import { ChimeConfigInitValue } from "../../interfaces/fe.interfaces";
import { validateAddOrEditChimeConfig } from "../../utils/validationSchemas";
import styles from "./styles.module.scss";
import Form from "./Form";

interface AddChimeConfigDialogProps {
  callback: () => void | Promise<void>;
  onCancelClick: () => void | Promise<void>;
}

// Initial values for add chime configuration form
const initialValues: ChimeConfigInitValue = {
  vendor_id: "",
  chime_description: "",
  chime_version: "",
  chime_audio: "",
  chime_config_bin: null,
  current_customer_version: false,
};

/**
 * This component is responsible for adding new chime configuration
 */
const AddChimeConfig = (props: AddChimeConfigDialogProps) => {
  // Handler for form submit to save chime configuration
  const onSubmit = (values: ChimeConfigInitValue, { setSubmitting }: FormikHelpers<ChimeConfigInitValue>): void => {
    if (!values.chime_config_bin) return;

    const formData = new FormData();

    formData.append("chime_description", values.chime_description);
    formData.append("vendor_id", values.vendor_id);
    formData.append("chime_version", values.chime_version);
    formData.append("chime_audio", values.chime_audio);
    formData.append("chime_config_bin", values.chime_config_bin);
    formData.append("current_customer_version", values.current_customer_version ? "true" : "false");

    // Success callback for /add-chime-config API
    const onSuccess = ({ data: response }: AxiosResponse<UpdateItemResponse<ChimeConfiguration>>) => {
      setSubmitting(false);
      if (response?.status) {
        // Display success toast and call parent callback to refresh the table
        toast.success(response?.message);
        props.callback();

        // Close the dialog
        props.onCancelClick();
      } else {
        toast.error(response?.message);
      }
    };

    // Calls /add-chime-config API
    http.makePostRequestWithFormData<UpdateItemResponse<ChimeConfiguration>, { message: string }>(
      addChimeConfig,
      onSuccess,
      (error) => {
        setSubmitting(false);
        toast.error(error?.response?.data?.message);
      },
      formData
    );
  };
  return (
    <div className={styles.dialogContainer}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        onReset={props.onCancelClick}
        validationSchema={validateAddOrEditChimeConfig}>
        {(props) => <Form {...props} />}
      </Formik>
    </div>
  );
};

export default AddChimeConfig;
