import { useEffect, useCallback, useState } from "react";
import { AxiosError, AxiosResponse } from "axios";
import { http } from "../api/utils/http";

export interface UseFetchProps<R, E = unknown> {
  url: string;
  runOnMount?: boolean;
  onSuccess?: (data: AxiosResponse<R>) => void;
  onError?: (error?: AxiosError<E>) => void;
}

interface ErrorDefaultState {
  message?: string;
}

export interface RunOptions {
  url?: string;
  showLoader?: boolean;
}

/**
 * Custom hook to fetch data from an API endpoint with easy state management
 * This hook is a wrapper around the get request function
 * For any get request you can use this hook with the url and the type of data you expect
 *
 * @param url - API endpoint
 * @param runOnMount - If true, the request will be made on mount/load of the page
 * @param onSuccess - Callback function to be called on success
 * @param onError - Callback function to be called on error
 * @returns states for data, error and loading
 */
export const useFetch = <R, E extends ErrorDefaultState = Record<string, unknown>>({
  url,
  runOnMount = false,
  onSuccess,
  onError,
}: UseFetchProps<R, E>) => {
  const [data, setData] = useState<R | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  /**
   * Handler to make request to the endpoint
   * To be called on load if runOnMount is true otherwise can be called from the component using this hook
   */
  const run = useCallback(
    (options?: RunOptions) => {
      setLoading(options?.showLoader ?? true);

      // When the request is successful, set the data and call the onSuccess callback
      const handleResponse = (data: AxiosResponse<R>) => {
        setData(data.data);
        if (onSuccess) {
          onSuccess(data);
        }

        setLoading(false);
      };

      // When the request fails, set the error and call the onError callback
      const handleError = (error?: AxiosError<E>) => {
        // If the error is from the server, set the error message from the server
        // Otherwise, set the error message from the error object which has default messages for network errors
        // At the end if all messages are empty, set a default message
        setError((error?.response?.data?.message || error?.message) ?? "Something went wrong");
        if (onError) {
          onError(error);
        }

        setLoading(false);
      };

      http.makeGetRequest(options?.url ?? url, handleResponse, handleError);
    },
    [url, onSuccess, onError]
  );

  useEffect(() => {
    if (runOnMount) {
      run();
    }
  }, []);

  // Data contains the response from the server
  // Error contains the error message
  // Loading is true when the request is in progress
  // Run is the function to make the request
  return {
    data,
    error,
    loading,
    run,
  };
};
