import { DetailedHTMLProps, SelectHTMLAttributes } from "react";

import styles from "./styles.module.scss";
interface SelectProps {
  selectProps: DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> & { placeholder?: string | undefined };
  optionLabel?: string;
  optionValue?: string;
  options: Record<string, any>[];
}

const Select = ({ selectProps, options, optionLabel = "label", optionValue = "value" }: SelectProps) => {
  return (
    <select {...selectProps} className={`${styles.select} ${selectProps?.className || ""}`}>
      {selectProps.placeholder && !selectProps.value ? <option>{selectProps.placeholder}</option> : null}
      {options.map((item: Record<string, any>) => (
        <option key={item?.[optionValue]} value={item?.[optionValue]}>
          {item?.[optionLabel]}
        </option>
      ))}
    </select>
  );
};

export default Select;
