import { useState, useCallback, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { I2CDriver } from "../utils/i2c";
import { FTDIDriver } from "../utils/ftdi";
import { AppContext } from "../context/AppContext";

interface UseConnectionProps {
  onConnect?: () => void;
  onDisconnect?: () => void;
}

/**
 * Use this hook to connect to the device
 * This hooks manages the connection state and also handles the connection and disconnection events
 * @param props props takes onConnect and onDisconnect callbacks to handle the connection and disconnection events
 * @returns states for device connection
 */
export const useConnection = (props?: UseConnectionProps) => {
  const [connected, setConnected] = useState(false);
  const [connecting, setConnecting] = useState(false);
  const appCtx = useContext(AppContext);

  // Connects to the device
  const handleConnect = useCallback(
    async (type?: string) => {
      // When the device is already connected, return true
      if (appCtx?.connectedPort !== null) {
        setConnecting(false);
        setConnected(true);
        return true;
      } else if (typeof type !== "undefined") {
        const portClass = type === "i2c" ? new I2CDriver() : new FTDIDriver();
        const connected = await portClass.connect();

        setConnecting(false);
        setConnected(connected);

        if (connected) {
          appCtx?.setConnectedPort(portClass);
        }

        return connected;
      }

      return false;
    },
    [appCtx]
  );

  useEffect(() => {
    handleConnect();
  }, []);

  useEffect(() => {
    const port = appCtx?.connectedPort;

    // Handler when device is plugged in to port
    const onConnect = () => {
      handleConnect();
      toast.dismiss();
      toast.info("Device is connected");

      props?.onConnect && props.onConnect();
    };

    // Handler when device is plugged out from port
    const onDisconnect = () => {
      port?.disconnect();
      setConnected(false);

      props?.onDisconnect && props.onDisconnect();
    };

    // Add event listeners for device connection and disconnection
    navigator.serial.addEventListener("disconnect", onDisconnect);
    navigator.serial.addEventListener("connect", onConnect);

    navigator.usb.addEventListener("disconnect", onDisconnect);
    navigator.usb.addEventListener("connect", onConnect);

    return () => {
      // Remove event listeners for device connection and disconnection
      navigator.serial.removeEventListener("disconnect", onDisconnect);
      navigator.serial.removeEventListener("connect", onConnect);

      navigator.usb.removeEventListener("disconnect", onDisconnect);
      navigator.usb.removeEventListener("connect", onConnect);
    };
  }, [appCtx]);

  return {
    connected,
    connecting,
    handleConnect,
    setConnected,
    setConnecting,
  };
};
