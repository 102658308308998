import { AxiosResponse } from "axios";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Icons } from "../../../assets/images";
import { deleteInputSequence, enableDisableInputSequence, getCustomers, getInputSequences } from "../../api/config";
import { http } from "../../api/utils/http";
import CreateSequenceDialog from "../../components/AddSeqDialog";
import DeleteConfirmation from "../../components/ConfirmationDialog";
import Select from "../../components/Select";
import Spinner from "../../components/Spinner";
import Switch from "../../components/Switch";
import { useFetch } from "../../hooks/useFetch";
import { CustomSequence, CustomSequenceData, GetCustomersResponse, InputSequenceData } from "../../interfaces/be.interfaces";
import { SelectOption } from "../../interfaces/fe.interfaces";
import styles from "./styles.module.scss";
import { useCustomerContext } from "../../hooks/useCustomerContext";
import { Tooltip } from "react-tooltip";
import { PERMISSIONS } from "../../utils/constants";
import AccessGuard from "../../components/Guards/AccessGuard";

interface ManageCustomSequenceProps {
  execute: boolean;
}

const ManageCustomSequence = (props: ManageCustomSequenceProps) => {
  const navigate = useNavigate();
  const { context, fetching } = useCustomerContext();
  const [isLoading, setIsLoading] = useState(false);
  const [customSequenceList, setCustomSequenceList] = useState<CustomSequence[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [deleteSequenceId, setDeleteSequenceId] = useState<null | string>(null);
  const [addDialogVisible, setAddDialogVisible] = useState(false);
  const [customerList, setCustomerList] = useState<SelectOption<string>[]>([]);
  const [vendorId, setVendorId] = useState<string>("");

  /**
   * Calls /input-sequence-enable-disable API to change status in database
   * @param seq_id - sequence id for which need to change status
   */
  const handleChangeStatus = (seq_id: string): void => {
    const onSuccess = ({ data: response }: AxiosResponse<InputSequenceData>) => {
      if (response?.status) {
        toast.success(response?.message || "Sequence status changed successfully.");
        fetchCustomSequence(false);
      } else {
        toast.error(response?.message || "Something went wrong.");
      }
    };

    http.makeGetRequest<InputSequenceData, { message: string }>(enableDisableInputSequence + seq_id, onSuccess, (error) => {
      toast.error(error?.response?.data?.message || "Something went wrong.");
      console.log("error", error);
    });
  };

  /**
   * Fetch list of customers to show in dropdown
   * only active customers are displayed in dropdown
   */
  const { loading: customersLoading } = useFetch<GetCustomersResponse>({
    runOnMount: true,
    url: getCustomers,
    onSuccess: (response) => {
      // Prepare customer data in option format to show in dropdown and filter inactive customers
      const customerOptions: SelectOption<string>[] = response.data?.data?.customers
        ?.filter((item) => item.is_enable)
        .map((customer) => ({
          label: customer.customer_name,
          value: customer.vendor_id.toString(),
        }));

      customerOptions.unshift({
        label: "All",
        value: "",
      });

      setCustomerList(customerOptions);
    },
  });

  const handleChangeCustomer = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    setVendorId(e.target.value);
  }, []);

  /**
   * Confirmation callback for delete confirmation
   * Calls /delete-input-sequence API for removing entity from database
   */
  const handleDeleteSequence = (): void => {
    const onSuccess = ({ data: response }: AxiosResponse<InputSequenceData>) => {
      if (response?.status) {
        fetchCustomSequence(false);
        toast.success(response?.message || "Custom sequence deleted successfully.");
      } else {
        toast.error(response?.message || "Something went wrong.");
      }
    };

    http.makeDeleteRequest<InputSequenceData, { message: string }>(deleteInputSequence + deleteSequenceId, onSuccess, (error) => {
      toast.error(error?.response?.data?.message || "Something went wrong.");
      console.log("error", error);
    });
    setDeleteSequenceId(null);
  };

  /**
   * Fetches list of custom sequences
   * Calls /get-input-sequences API
   * @param loading - depending on this parameter loader is displayed on UI
   */
  const fetchCustomSequence = (loading = true) => {
    setIsLoading(loading);
    const onSuccess = ({ data: response }: AxiosResponse<CustomSequenceData>) => {
      setCustomSequenceList(response?.data);
      setIsLoading(false);
    };

    const queryParams: Record<string, string | boolean> = {};

    if (vendorId) {
      queryParams.vendor_id = vendorId || (context?.vendorId ? context?.vendorId.toString() : "");
    }

    if (props.execute) {
      queryParams.enabled = true;
    }

    http.makeGetRequest(
      getInputSequences,
      onSuccess,
      (error) => {
        console.log("error", error);
        setIsLoading(false);
      },
      queryParams
    );
  };

  /**
   * Callback function for createSequence CreateSequenceDialog
   * Refreshes list data to show new created item via the API
   */
  const createSequenceCallback = () => {
    fetchCustomSequence(false);
    setAddDialogVisible(false);
  };

  useEffect(() => {
    if (!fetching) {
      fetchCustomSequence();
    }
  }, [props.execute, vendorId, fetching, context?.vendorId]);

  // List of custom sequences queried by search text
  const searchResult = useMemo(() => {
    return customSequenceList.filter((item: CustomSequence) => item?.seq_name?.toLowerCase()?.includes(searchText.toLowerCase()));
  }, [customSequenceList, searchText]);

  // Give tooltip id for show in disabled custom sequence toggle
  const getTooltipId = useCallback((sequence: CustomSequence) => {
    switch (true) {
      case sequence.miss_match_count > 0 && !sequence.enabled:
        return "vendor-miss-match-tooltip";
      case sequence.disabled_command_count > 0 && !sequence.enabled:
        return "disable-count-tooltip";
      default:
        return "";
    }
  }, []);

  return (
    <div className={styles.pageContainer}>
      <Tooltip id="info-tooltip" />
      <div className={`${styles.pageHeader} d-flex justify-content-between`}>
        <p>Custom Sequence List</p>
        <div className={`${styles.headerActions} d-flex justify-content-between align-item-center`}>
          <input type="text" value={searchText} placeholder="Search Sequence" onChange={(e) => setSearchText(e.target.value)} />
          {!context?.vendorId ||
            (!props.execute && (
              <Select
                selectProps={{
                  value: vendorId || "",
                  onChange: handleChangeCustomer,
                }}
                options={customerList}
              />
            ))}
          <AccessGuard permission={PERMISSIONS.CUSTOM_SEQUENCE_ADD}>
            {!props?.execute ? (
              <button type="submit" onClick={() => setAddDialogVisible(true)}>
                Add
              </button>
            ) : null}
          </AccessGuard>
        </div>
      </div>
      <div className={styles.seqListContainer}>
        {isLoading || customersLoading ? (
          <Spinner />
        ) : searchResult?.length ? (
          searchResult.map((item: CustomSequence) => (
            <div className={styles.seqItemContainer} key={item?.seq_id}>
              <p>{item?.seq_name}</p>
              {!props?.execute ? (
                <AccessGuard
                  permission={[
                    PERMISSIONS.CUSTOM_SEQUENCE_UPDATE,
                    PERMISSIONS.CUSTOM_SEQUENCE_DELETE,
                    PERMISSIONS.CUSTOM_SEQUENCE_ENABLE_DISABLE,
                  ]}>
                  <div className={styles.seqItemActions}>
                    <Tooltip
                      id="vendor-miss-match-tooltip"
                      delayHide={2000}
                      style={{ maxWidth: 300 }}
                      className={styles.tooltip}
                      render={() => {
                        return (
                          <>
                            This custom sequence cannot be enabled because there is a mismatch between the vendor of the custom sequence and
                            command. Please visit <Link to="/manage-custom-commands">Manage Custom Command</Link> and ensure that both the
                            custom sequence and command have the same vendor in order to enable this command.
                          </>
                        );
                      }}
                    />
                    <Tooltip
                      id="disable-count-tooltip"
                      delayHide={2000}
                      style={{ maxWidth: 300 }}
                      className={styles.tooltip}
                      render={() => {
                        return (
                          <>
                            This custom sequence cannot be enabled because there are disabled commands within it. Please go to
                            <Link to="/manage-custom-commands">Manage Custom Command</Link> and enable the necessary commands used within
                            this sequence.
                          </>
                        );
                      }}
                    />
                    <AccessGuard permission={PERMISSIONS.CUSTOM_SEQUENCE_UPDATE}>
                      <Icons.EditIcon
                        onClick={() => !item.is_default && navigate(`/update-custom-seq/${item?.seq_id}`)}
                        className={item.is_default ? styles.disabled : undefined}
                        data-tooltip-id="info-tooltip"
                        data-tooltip-content={item.is_default ? "Default seqeuence could not be edited" : ""}
                        data-tooltip-place="left"
                      />
                    </AccessGuard>
                    <AccessGuard permission={PERMISSIONS.CUSTOM_SEQUENCE_DELETE}>
                      <Icons.TrashIcon
                        onClick={() => !item.is_default && setDeleteSequenceId(item?.seq_id)}
                        className={item.is_default ? styles.disabled : undefined}
                        data-tooltip-id="info-tooltip"
                        data-tooltip-content={item.is_default ? "Default seqeuence could not be deleted" : ""}
                        data-tooltip-place="bottom"
                      />
                    </AccessGuard>
                    <AccessGuard permission={PERMISSIONS.CUSTOM_SEQUENCE_ENABLE_DISABLE}>
                      <Switch
                        checked={item?.enabled}
                        onChange={() =>
                          !((item.miss_match_count > 0 || item.disabled_command_count > 0) && !item.enabled) &&
                          handleChangeStatus(item?.seq_id)
                        }
                        disabled={(item.miss_match_count > 0 || item.disabled_command_count > 0) && !item.enabled}
                        id="switch-tooltip"
                        data-tooltip-id={getTooltipId(item)}
                        data-tooltip-place="bottom"
                      />
                    </AccessGuard>
                  </div>
                </AccessGuard>
              ) : (
                <AccessGuard permission={PERMISSIONS.SEQUENCE_EXECUTION}>
                  <button onClick={() => navigate(`/run-custom-seq/${item?.seq_id}`)}>Run</button>
                </AccessGuard>
              )}
            </div>
          ))
        ) : (
          <div className={`${styles.emptyPage} d-flex justify-content-center align-item-center`}>
            <span className={styles.noDataFound}>No Sequence Found</span>
          </div>
        )}
      </div>
      {deleteSequenceId ? (
        <DeleteConfirmation
          title="Delete Sequence"
          description="Are you sure you want to delete the sequence?"
          onConfirmClick={handleDeleteSequence}
          onDeclineClick={() => setDeleteSequenceId(null)}
        />
      ) : null}
      {addDialogVisible ? (
        <CreateSequenceDialog callback={createSequenceCallback} onCancelClick={() => setAddDialogVisible(false)} />
      ) : null}
    </div>
  );
};

export default ManageCustomSequence;
