import { toast } from "react-toastify";
import { COMMAND_TYPE } from "../interfaces/be.interfaces";
import { USBDevice } from "../interfaces/usb";
import { DEVICE_TIMEOUT, STATUS_COMMAND_RESPONSE } from "./constants";
import { CommunicationType } from "../interfaces/fe.interfaces";

export class FTDIDriver {
  device: USBDevice | null = null;
  communicationType = CommunicationType.FTDI;
  timeoutController: NodeJS.Timeout | null = null;
  deviceResponseTimeout = DEVICE_TIMEOUT;

  constructor() {
    this.runSequence = this.runSequence.bind(this);
  }

  private async sendCommand(data: Uint8Array) {
    if (!this?.device?.configuration) return false;
    const { endpointNumber } = this.device.configuration.interfaces[0].alternate.endpoints[1];
    await this.device.transferOut(endpointNumber, data);
  }

  /**
   * This function reads data from the device. It takes the length of the data to be read as an argument
   */
  private async recieve(len: number) {
    if (!this?.device?.configuration) return new Uint8Array([]);

    // Find endpoint of transferIn
    const { endpointNumber } = this.device.configuration.interfaces[0].alternate.endpoints[0];
    let length = 0;
    let data: number[] = [];

    do {
      // Start transferIn until we get all the data
      await this.device.transferIn(endpointNumber, 512).then(
        (result) => {
          // First two bytes are ignored, if result has more than 2 bytes, slice the first two bytes and append to data
          if (result.data && result.data.byteLength > 2) {
            const view = [...new Uint8Array(result.data.buffer)];

            // remove first two bytes and store the rest in data
            const slicesData = view.slice(2);
            data = length === 0 ? [...slicesData] : [...data, ...slicesData];
            length += result.data.byteLength - 2;
          }
        },
        (error) => {
          console.log(error);
          return new Uint8Array([]);
        }
      );

      // If the data is not read completely, read again till we get all the data
    } while (length < len);

    return new Uint8Array(data.slice(0, len));
  }

  /**
   * This function connects to the device.
   * It filters the devices based on the vendorId and productId and requests access to the device.
   * Once configured, it claims the interface and sets the device to I2C Master mode
   */
  async connect() {
    try {
      // filter for the device to see only the device we want
      const filters = [{ vendorId: 0x0403, productId: 0x601c }];
      const device = await navigator.usb.requestDevice({ filters: filters });
      this.device = device;

      // Open the device, configure it and claim the interface
      await this.device
        .open()
        .then(async () => {
          if (this?.device?.configuration === null) {
            await this.device.selectConfiguration(0);
          }
        })
        .then(async () => {
          await this?.device?.claimInterface(0);
        });

      // Set I2C Master mode  (AN_416, Sec 2.6.2)
      await this.device.controlTransferOut(
        {
          requestType: "vendor",
          recipient: "device",
          request: 0x21,
          value: 0x0105,
          index: 0x0000,
        },
        new ArrayBuffer(0)
      );

      // reSet I2C Master Reset  (AN_416, Sec 2.5.1)
      await this.device.controlTransferOut(
        {
          requestType: "vendor",
          recipient: "device",
          request: 0x21,
          value: 0x0051,
          index: 0x0000,
        },
        new ArrayBuffer(0)
      );

      await this.device.controlTransferOut(
        {
          requestType: "vendor",
          recipient: "device",
          request: 0x21,
          value: 0x8452,
          index: 0x0001,
        },
        new ArrayBuffer(0)
      );

      return true;
    } catch {
      // in case of error, close the device and return false
      this.disconnect();
      return false;
    }
  }

  /**
   * This function disconnects the device
   */
  async disconnect() {
    return await this?.device?.close();
  }

  /**
   * This function runs sequence of commands
   * Sequence is an array of arrays, where each array is a chunk of commands, which are sent to the device one by one
   */
  async runSequence(sequence: (string | number)[][], _: boolean, type = COMMAND_TYPE.Get) {
    try {
      for (let i = 0; i < sequence.length; i++) {
        const element = sequence[i];

        // Clear timeout if next command is starting to execute
        if (this.timeoutController) {
          clearTimeout(this.timeoutController);
        }

        // Reject sequence running promise in case reading from device takes more than defined time
        this.timeoutController = setTimeout(() => {
          toast.error("Device response timeout, please reconnect serial port.");
          this.timeoutController && clearTimeout(this.timeoutController);
        }, this.deviceResponseTimeout);

        if (element[0] === "delay") {
          await new Promise((resolve) => {
            setTimeout(resolve, +element[1]);
          });

          continue;
        }

        await this.sendCommand(new Uint8Array(element as any));

        // if the command is status read, data read then we wait for response from device.
        if ([0x51, 0x8d].includes(+element[0])) {
          // Get the first and second length bytes and concatenate them to get the length of data
          const readFirstLengthByte = +element[element.length - 2] << 8;
          const readSecondLengthByte = +element[element.length - 1];
          const readLength = readFirstLengthByte | readSecondLengthByte;

          const response = await this.recieve(readLength);

          // In case of SET commands if the response is not 0x00 and it is not the last command in sequence then return response and stop
          // In case of GET commands wait for response as data read is always the last command in sequence
          if (sequence.length - 1 === i || (type === COMMAND_TYPE.Set && response[0] !== STATUS_COMMAND_RESPONSE)) {
            clearTimeout(this.timeoutController);
            return response;
          }
        }
      }

      this.timeoutController && clearTimeout(this.timeoutController);
      return new Uint8Array([]);
    } catch (e) {
      console.log(e);
      return new Uint8Array([]);
    }
  }
}
