import { AppContextType } from "../interfaces/fe.interfaces";

let context: AppContextType;

/**
 * Formats a string in MAC address format
 * @param {string} str - unformatted string to be converted to formatted MAC address string
 * @returns {string} formatted mac address
 */
export const formatMAC = (str: string): string => {
  return (
    str
      ?.match(/.{2,2}/g)
      ?.join(":")
      ?.toLowerCase() ?? ""
  );
};

let timer: NodeJS.Timeout | null = null;
/**
 * Handler for keypress event
 */
function onKeyPress(e: Event) {
  /**
   * Allocates empty array to context.scan when
   * - it is not defined
   * - delay between keypress is more than 50ms
   */

  // ignore event if typing on a input box and not scanned
  if (e.target !== document.body) {
    return;
  }

  if (context.scan.length > 0 && e.timeStamp - context.scan[context.scan.length - 1]?.timeStamp > 50) {
    context.setScan([]);
  }

  // After every keypress reset the timer
  if (timer) {
    clearTimeout(timer);
    timer = null;
  }

  const data = JSON.parse(JSON.stringify(e, ["key", "timeStamp"]));

  data.timeStampDiff = context.scan.length > 0 ? data.timeStamp - context.scan.slice(-1)[0].timeStamp : 0;
  context.scan.push(data);

  // After scan giving threshold for returning the event
  timer = setTimeout(() => {
    const scannedString = context.scan.reduce((acc, item) => acc + item.key, "");
    context.setScan([]);
    return document.dispatchEvent(
      new CustomEvent("scanned", {
        detail: scannedString,
      })
    );
  }, 100);
}

/**
 * Use this function to listen for scanner event
 * @param {Function} onComplete - Called when scanner has completed scanning
 */
export const watchScanner = (appContext: AppContextType, onComplete: (e: CustomEvent) => void) => {
  context = appContext;
  document.addEventListener("keypress", onKeyPress);
  document.addEventListener("scanned", onComplete as EventListener);
};

/**
 * Use this function to remove event listeners for scanner event
 */
export const removeScanner = (onComplete: (e: CustomEvent) => void) => {
  document.removeEventListener("keypress", onKeyPress);
  document.removeEventListener("scanned", onComplete as any, false);
};
