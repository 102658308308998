import WiSALogo from "./wisa-logo-white.png";
import Speaker from "./speaker.png";

import { ReactComponent as EditIcon } from "./edit.svg";
import { ReactComponent as TrashIcon } from "./trash.svg";
import { ReactComponent as CheckIcon } from "./check.svg";
import { ReactComponent as MoveIcon } from "./move.svg";
import { ReactComponent as SaveIcon } from "./save.svg";
import { ReactComponent as LeftArrow } from "./arrowLeft.svg";
import { ReactComponent as ChevronLeft } from "./chevron-left.svg";
import { ReactComponent as ChevronRight } from "./chevron-right.svg";
import { ReactComponent as MoveOnIcon } from "./move-on.svg";
import { ReactComponent as CopyIcon } from "./copy.svg";
import { ReactComponent as EyeOn } from "./eye-on.svg";
import { ReactComponent as List } from "./list.svg";
import { ReactComponent as EyeOff } from "./eye-off.svg";

const Images = {
  WiSALogo,
  Speaker,
};

export const Icons = {
  EditIcon,
  TrashIcon,
  CheckIcon,
  MoveIcon,
  SaveIcon,
  LeftArrow,
  ChevronLeft,
  ChevronRight,
  MoveOnIcon,
  CopyIcon,
  EyeOn,
  List,
  EyeOff,
};

export default Images;
