import { Dispatch, SetStateAction, useCallback, useContext, useState } from "react";
import styles from "./styles.module.scss";
import { AppContext } from "../../context/AppContext";
import { I2CDriver } from "../../utils/i2c";
import { FTDIDriver } from "../../utils/ftdi";
import Checkbox from "../Checkbox";
import { SerialDriver } from "../../utils/serial";

interface ConnectDialogProps {
  setConnected: Dispatch<SetStateAction<boolean>>;
  setConnecting: Dispatch<SetStateAction<boolean>>;
  connecting: boolean;
  defaultDriver?: "i2c" | "ftdi" | "serial";
}

const ConnectDialog = (props: ConnectDialogProps) => {
  const appCtx = useContext(AppContext);
  const [communicationType, setCommunicationType] = useState(props.defaultDriver ?? "i2c");

  // Connects to the device
  const handleConnect = useCallback(async () => {
    let portClass;

    switch (communicationType) {
      case "i2c":
        portClass = new I2CDriver();
        break;
      case "serial":
        portClass = new SerialDriver();
        break;
      case "ftdi":
        portClass = new FTDIDriver();
        break;
    }

    const connected = await portClass.connect();

    props.setConnecting(false);
    props.setConnected(connected);
    appCtx?.setConnectedPort(portClass);

    return connected;
  }, [communicationType]);

  if (props.connecting) {
    return (
      <div className={styles.dialogContainer}>
        <div className={styles.dialog}>
          <p className={styles.dialogHeader}>Connecting...</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={styles.dialogContainer}>
        <div className={styles.dialog}>
          <p className={styles.dialogHeader}>Connect to USB port</p>
          <p className={styles.dialogContent}>It looks like you are not connected to device</p>
          {!props.defaultDriver && (
            <Checkbox
              label="Use FTDI"
              checked={communicationType === "ftdi"}
              onChange={() => setCommunicationType(communicationType === "ftdi" ? "i2c" : "ftdi")}
            />
          )}
          <button className={styles.connectBtn} onClick={handleConnect} disabled={props.connecting}>
            Connect
          </button>
        </div>
      </div>
    </>
  );
};

export default ConnectDialog;
