import styles from "./styles.module.scss";
import gitInfo from "../../../git-info.json";

const YEAR = new Date().getFullYear();

/**
 * Footer component for the application
 */
const Footer = () => {
  return (
    <footer className={styles.footer}>
      <span>{YEAR} &copy; WiSA All Rights Reserved </span>
      <div className={styles.aboutApp}>
        {/* Currently displaying static client version and dummy Git Hash as placeholder */}
        <span>
          {gitInfo?.tag ? `${gitInfo?.tag} - ` : null} {gitInfo?.hash}
        </span>
      </div>
    </footer>
  );
};

export default Footer;
