import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AxiosError } from "axios";

import InfoBox from "./InfoBox";

import { AxiosResponse } from "axios";
import { getInputSequenceDetails, runInputSequence, getWisaProducts, getSetCommandsInputs } from "../../api/config";
import { http } from "../../api/utils/http";
import CommandResponse from "../../components/CommandResponse";
import ConnectDialog from "../../components/ConnectDialog";
import ProductConfigDropdowns from "../../components/ProductConfigDropdowns";
import Spinner from "../../components/Spinner";
import { Sequence, useSequence } from "../../hooks/useSequence";
import { GetWisaCommandDetails, GetWisaProductsResponse, GetSetCommandsInputsResponse } from "../../interfaces/be.interfaces";
import { CommandTypes, CustomCommandResultStatus, CustomCommandResultType } from "../../interfaces/fe.interfaces";
import {
  COMMAND_STATUS,
  SET_CONFIGURATION_SEQ_NAME,
  SET_OEM_INFO_COMMAND_NAME,
  STATUS_CLASS_MAPPING,
  TEST_RSSI_COMMAND_ID,
} from "../../utils/constants";
import { parseCustomSequence } from "../../utils/helpers";
import styles from "./styles.module.scss";
import { StatusInfo } from "../ModulePage";
import { Icons } from "../../../assets/images";
import Select from "../../components/Select";
import { FLOATING_NUM_REGEX } from "../../utils/constants";
import { useFetch, UseFetchProps } from "../../hooks/useFetch";
import OverwriteModel from "./OverwriteModel";

const INPUT_FIELD_ERROR = "Please enter value in input field";

interface OnErrorResponse {
  message: string;
}

/**
 * Check if sequence has any passing values or min/max value
 * @param seq Sequence object
 * @returns boolean value
 */
const hasPassingValues = (seq: Sequence): boolean => {
  // Check if sequence has any passing values or min/max value
  return (seq.passing_values && seq.passing_values?.length > 0) || (typeof seq.max_value === "number" && typeof seq.min_value === "number");
};

const CustSeqExecution = () => {
  const { id } = useParams();
  const [error, setError] = useState<string | null>(null);
  const [customCommandResultText, setCustomCommandResultText] = useState("");
  const [customCommandError, setCustomCommandError] = useState<string | null>(null);
  const [infoModelOpen, setInfoModelOpen] = useState(false);
  const [isMultiDevice, setIsMultiDevice] = useState(false);

  const { data } = useFetch<GetSetCommandsInputsResponse>({
    url: getSetCommandsInputs,
    runOnMount: true,
  });

  /**
   * Preparing list of commands for which user should be asked to enter input
   * This is prepared based on backend's response in which we get list of fixed_inputs and dynamic_input
   */
  const inputAvailableFor = useMemo(() => {
    let keys: string[] = [];
    if (data?.data?.fixed_values) {
      keys = Object.keys(data?.data?.fixed_values);
    }

    if (data?.data?.dynamic_values) {
      keys = [...keys, ...Object.keys(data?.data?.dynamic_values)];
    }

    return keys;
  }, [data]);

  const {
    sequence,
    setSequence,
    seqTitle,
    setSeqTitle,
    scannedMac,
    deviceType,
    logs,
    deviceConnection: { connected, connecting, setConnected, setConnecting },
    setSeqId,
    configItemsLoading,
    deviceConfigurationPayload,
    setDeviceConfigurationPayload,
    customersList,
    productList,
    modelList,
    updatePayloadValue,
    sendDeviceResponse,
    devConfigEnabled,
    setDevConfigEnabled,
    regionList,
    isSequenceRunning,
    setDeviceType,
    isRegionEnabled,
    setIsRegionEnabled,
    isSpeakerPositionEnabled,
    setIsSpeakerPositionEnabled,
    showOverwriteModel,
    setShowOverwriteModal,
    callScanAPI,
    changeAsscModeInRemote,
    setChangeAsscModeInRemote,
    beProductList,
    macAddresses,
  } = useSequence({
    setError,
    callbackApiUrl: runInputSequence,
    multiDevice: isMultiDevice,
    maxDeviceScan: isMultiDevice ? 2 : undefined,
  });

  const onGetWisaProductsFailure = useCallback((error: AxiosError<OnErrorResponse, any> | undefined) => {
    toast.error("WiSA products failed to load. Try reloading the page.");
    console.log(`[GetWisaProductsFailure]: ${error?.message}`);
  }, []);

  const useFetchGetWisaProductsProps: UseFetchProps<GetWisaProductsResponse, OnErrorResponse> = useMemo(
    () => ({
      url: getWisaProducts,
      runOnMount: true,
      onError: onGetWisaProductsFailure,
    }),
    []
  );
  const { data: wisaProducts } = useFetch(useFetchGetWisaProductsProps);

  /**
   * Use this function to fetch list of commands that are already saved in database for particular sequences
   * Calls /get-input-sequences API with id to get details of custom sequences
   */
  const fetchSelectedCommandsList = useCallback(() => {
    const onSuccess = ({ data: response }: AxiosResponse<GetWisaCommandDetails>) => {
      const data = parseCustomSequence(
        response?.data?.seq_commands,
        { status: COMMAND_STATUS.Pending },
        {
          titlePath: "wisa_sub_seq.command_name",
          idPath: "wisa_sub_seq.command_code",
          moveOnPath: "wisa_sub_seq.move_on",
          type: CommandTypes.WISA_SUB_SEQ,
        },
        {
          titlePath: "wisa_commands.command_name",
          idPath: "wisa_commands.command_code",
          moveOnPath: "wisa_commands.move_on",
          type: CommandTypes.WISA_COMMANDS,
        },
        {
          titlePath: "wisa_cust_commands.cust_command_name",
          idPath: "wisa_cust_commands.cust_command_id",
          moveOnPath: "wisa_cust_commands.move_on",
          passingValuesPath: "wisa_cust_commands.passing_values",
          minValuePath: "wisa_cust_commands.min_value",
          maxValuePath: "wisa_cust_commands.max_value",
          type: CommandTypes.CUSTOM_COMMANDS,
        }
      );

      setSeqTitle(response?.data?.seq_name);
      if (data) {
        const modifiedSeq: Sequence[] = data.map((item) => ({
          id: item.id,
          title: item.title,
          status: COMMAND_STATUS.Pending,
          resultType: item.result_type,
          moveOn: item.move_on,
          set_value: item.set_value,
          allowUpdate: item.allow_update,
          passing_values: item.passing_values,
          max_value: item.max_value,
          min_value: item.min_value,
        }));

        // device configuration is enabled only when sequence contains set configuration command or set oem info command
        setDevConfigEnabled(
          modifiedSeq.some((item) => item.title === SET_CONFIGURATION_SEQ_NAME || item.title === SET_OEM_INFO_COMMAND_NAME)
        );

        setIsMultiDevice(modifiedSeq.some((item) => item.id === TEST_RSSI_COMMAND_ID));

        // When sequence contains set configuration command, enable region and speaker position dropdowns
        // if sequence contains SET_OEM_INFO commands then region and speaker position dropdowns will be removed from UI
        const sequenceContainsSetConfig = modifiedSeq.some((item) => item.title === SET_CONFIGURATION_SEQ_NAME);
        setIsSpeakerPositionEnabled(sequenceContainsSetConfig);
        setIsRegionEnabled(sequenceContainsSetConfig);
        setSequence(modifiedSeq);
      }
    };

    http.makeGetRequest(getInputSequenceDetails + id, onSuccess, (err) => {
      console.log("error", err);
    });
  }, []);

  /**
   * Submit handler for custom command submit button
   * Validates input and sends device response
   */
  const submitCustomCommand = useCallback(
    (status: CustomCommandResultStatus, resultType?: CustomCommandResultType) => {
      if (!customCommandResultText.length && resultType !== CustomCommandResultType.BOOLEAN) {
        setCustomCommandError(INPUT_FIELD_ERROR);
        return;
      }

      if (resultType === CustomCommandResultType.NUMBER && !FLOATING_NUM_REGEX.test(customCommandResultText)) {
        setCustomCommandError("Please enter valid number");
        return;
      }

      if (resultType !== CustomCommandResultType.BOOLEAN && customCommandResultText.length > 255) {
        setCustomCommandError("Maximum 255 characters allowed");
        return;
      }

      setCustomCommandError(null);

      const payload = {
        deviceResponse: customCommandResultText || String(status === CustomCommandResultStatus.SUCCESS),
        status,
        wisaProduct: deviceType,
      };

      sendDeviceResponse(payload);
      setCustomCommandResultText("");
    },
    [customCommandResultText, sendDeviceResponse, deviceType]
  );

  /**
   * Change handler for input fields
   */
  const handleChangeInputValue = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const inputType = e.target.getAttribute("data-type");

    // validate a valid number when input type is number
    if (inputType === "number" && !FLOATING_NUM_REGEX.test(value) && value !== "") {
      setCustomCommandError("Please enter valid number");
      return;
    } else {
      setCustomCommandError(null);
    }

    setCustomCommandResultText(e.target.value);
  }, []);

  // On page load fetch commands list to be executed
  useEffect(() => {
    if (id) {
      setSeqId(Number(id));
      fetchSelectedCommandsList();
    }
  }, [id]);

  const deviceStatus = connected ? "Connected" : "Disconnected";

  if (configItemsLoading) {
    return <Spinner />;
  }

  return (
    <div className={styles.pageContainer}>
      <div className={styles.titleContainer}>
        <p>
          {seqTitle}
          <span onClick={() => setInfoModelOpen(true)}>&nbsp;&#9432;</span>
        </p>
        <div className={styles.statusContainer}>
          <div className={`${styles.statusDot} ${styles?.[deviceStatus]}`}></div>
          <span>{deviceStatus}</span>
        </div>
      </div>
      {error ? <div className="errorAlert">{error}</div> : null}
      <section className={`d-flex justify-content-between ${styles.moduleContainer}`}>
        <div className={`d-flex justify-content-between ${styles.informationContainer}`}>
          {devConfigEnabled && (
            <ProductConfigDropdowns
              deviceConfigurationPayload={deviceConfigurationPayload}
              setDeviceConfigurationPayload={setDeviceConfigurationPayload}
              customersList={customersList}
              productList={productList}
              modelList={modelList}
              updatePayloadValue={updatePayloadValue}
              regions={regionList ?? []}
              isSequenceRunning={isSequenceRunning}
              isRegionEnabled={isRegionEnabled}
              isSpeakerPositionEnabled={isSpeakerPositionEnabled}
              changeAsscModeInRemote={changeAsscModeInRemote}
              setChangeAsscModeInRemote={setChangeAsscModeInRemote}
              showAsscModeCheckbox={false}
              setDeviceType={setDeviceType}
              beProductList={beProductList}
            />
          )}
          <div className={`w-100 d-flex justify-content-between ${styles.deviceInfo} ${isMultiDevice ? styles.multiContainer : ""}`}>
            <div className={`d-flex ${styles.infoboxContainer}`}>
              <span>WiSA Product Type:</span>
              <Select
                selectProps={{
                  name: "wisa_product",
                  placeholder: "WiSA Product",
                  value: deviceType,
                  onChange: (event) => setDeviceType(event.target.value),
                  disabled: isSequenceRunning,
                }}
                options={wisaProducts?.data.list || []}
                optionLabel="type"
                optionValue="type"
              />
              {isMultiDevice && (
                <div className={styles.macAddressContainer}>
                  {new Array(2).fill(0).map((_, index) => (
                    <label key={index}>
                      {macAddresses[index] ?? (index === 0 ? "Connected device MAC address" : "Remote device MAC address")}
                    </label>
                  ))}
                </div>
              )}
            </div>
            {!isMultiDevice && <InfoBox title="Scanned MAC:" value={scannedMac || ""} />}
          </div>
          <div className={`d-flex ${styles.commandsContainer}`}>
            <span>Programming Results</span>
            {sequence?.map((seq) => (
              <div className={styles.commandItemContainer} key={seq.id}>
                <div className={styles.command}>
                  <label>
                    {seq.title} {seq.deviceResponse ? `(${seq.deviceResponse})` : null}
                    {seq.moveOn ? <Icons.MoveOnIcon /> : null}
                  </label>
                  <span className={styles?.[STATUS_CLASS_MAPPING[seq.status ?? COMMAND_STATUS.Pending]?.className]}>
                    {STATUS_CLASS_MAPPING[seq.status ?? COMMAND_STATUS.Pending]?.label}
                  </span>
                </div>
                {seq.resultType !== undefined && seq.status === COMMAND_STATUS.InProgress && (
                  <div className={styles.customCommand}>
                    {seq.resultType !== CustomCommandResultType.BOOLEAN && (
                      <input
                        placeholder={`Enter ${seq.resultType.toLowerCase()}`}
                        value={customCommandResultText}
                        onChange={handleChangeInputValue}
                        type="text"
                        data-type={seq.resultType === CustomCommandResultType.STRING ? "text" : "number"}
                      />
                    )}
                    {/* {customCommandResultText.length > 255 && <p className={styles.error}>Maximum 255 characters allowed</p>} */}
                    {customCommandError && <p className={styles.error}>{customCommandError}</p>}
                    <div className={styles.btnContainer}>
                      {hasPassingValues(seq) ? (
                        <button
                          type="button"
                          className={styles.pass}
                          onClick={() => submitCustomCommand(CustomCommandResultStatus.SUCCESS, seq.resultType)}>
                          Submit
                        </button>
                      ) : (
                        <>
                          <button
                            type="button"
                            className={styles.pass}
                            onClick={() => submitCustomCommand(CustomCommandResultStatus.SUCCESS, seq.resultType)}>
                            Pass
                          </button>
                          <button
                            type="button"
                            className={styles.fail}
                            onClick={() => submitCustomCommand(CustomCommandResultStatus.FAIL, seq.resultType)}>
                            Fail
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            ))}{" "}
          </div>
        </div>
        <CommandResponse logs={logs} />
      </section>
      <StatusInfo infoModelOpen={infoModelOpen} setInfoModelOpen={setInfoModelOpen} />
      {!connected ? <ConnectDialog connecting={connecting} setConnected={setConnected} setConnecting={setConnecting} /> : null}
      {sequence ? (
        <OverwriteModel
          showOverwriteModel={showOverwriteModel}
          setShowOverwriteModal={setShowOverwriteModal}
          sequence={sequence}
          callScanAPI={callScanAPI}
          inputAvailableFor={inputAvailableFor}
          setCommandsInput={data}
        />
      ) : null}
    </div>
  );
};

export default CustSeqExecution;
