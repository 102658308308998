import { ReactNode, useCallback, useEffect, MouseEvent } from "react";
import styles from "./styles.module.scss";

export interface ModalProps {
  children: ReactNode;
  isModalOpen: boolean;
  onModalClose?: () => void;
  onModalOpen?: () => void;
}

const Modal = ({ children, isModalOpen = false, onModalClose, onModalOpen }: ModalProps) => {
  const handleOnClose = useCallback(() => {
    onModalClose?.();
  }, [onModalClose]);

  const MODAL_OUTER = "modalOuter";
  const handleOnModalOuterClick = useCallback(
    (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
      if ((e.target as Element).classList.contains(MODAL_OUTER)) handleOnClose();
    },
    [handleOnClose, MODAL_OUTER]
  );

  useEffect(() => {
    if (isModalOpen) onModalOpen?.();
  }, [isModalOpen]);

  if (!isModalOpen) return null;

  return (
    <div className={`${styles.modal} ${MODAL_OUTER}`} onClick={handleOnModalOuterClick}>
      <div className={styles.modalClose} onClick={handleOnClose}>
        X
      </div>
      <div className={styles.modalMain}>{children}</div>
    </div>
  );
};

export default Modal;
