import { AxiosResponse } from "axios";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addInputSequence } from "../../api/config";
import { http } from "../../api/utils/http";
import { InputSequenceData } from "../../interfaces/be.interfaces";
import { CustomSequencePayload } from "../../interfaces/fe.interfaces";
import AddOrUpdateSeqForm from "./Form";
import styles from "./styles.module.scss";
import Modal from "../Modal/Modal";
import { CustomSequenceValidationSchema } from "../../utils/validationSchemas";

interface CreateSequenceDialogProps {
  callback: () => void | Promise<void>;
  onCancelClick: () => void | Promise<void>;
}

const initialValues: CustomSequencePayload = { seq_name: "", vendor_id: null };

// DialogBox component for create new sequence
const CreateSequenceDialog = (props: CreateSequenceDialogProps) => {
  const navigate = useNavigate();

  /**
   * Submit handler for create sequence form
   * Calls /add-input-sequence API with seq_name payload
   * @param e Browser native submit event
   */
  const handleAddSequence = (values: CustomSequencePayload): void => {
    // Success callback for /add-input-sequence API
    const onSuccess = ({ data: response }: AxiosResponse<InputSequenceData>) => {
      if (response?.status) {
        toast.success(response?.message || "Custom sequence added successfully");
        navigate(`/update-custom-seq/${response?.data?.seq_id}`);
        props.callback();
      } else {
        toast.error(response?.message || "Something went wrong");
      }
    };

    http.makePostRequest<InputSequenceData, { message: string }>(
      addInputSequence,
      onSuccess,
      (error) => {
        toast.error(error?.response?.data?.message || "Something went wrong");
      },
      values
    );
  };

  return (
    <Modal isModalOpen={true} onModalClose={props.onCancelClick}>
      <div className={styles.dialogContainer}>
        <Formik initialValues={initialValues} validationSchema={CustomSequenceValidationSchema} onSubmit={handleAddSequence}>
          {(formikProps) => <AddOrUpdateSeqForm {...formikProps} onCancelClick={props.onCancelClick} />}
        </Formik>
      </div>
    </Modal>
  );
};

export default CreateSequenceDialog;
